import { Outlet, useParams } from "react-router-dom";
import {
  useApproveTeamJoinRequestMutation,
  useRejectTeamJoinRequestMutation,
  useTeamJoinRequestsQuery,
  useTeamMembersQuery,
} from "../../../../__generated__/types-and-hooks";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { Box, Chip, Tooltip, Typography } from "@mui/joy";
import { useTeam } from "../../TeamLayout";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UserTooltip from "../../../../components/tooltips/UserTooltip";
import { useTranslation } from "../../../../i18n";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";

function UserRenderCell(params: GridRenderCellParams) {
  return (
    <>
      <Chip sx={{ borderRadius: 4 }}>{params.row.slug}</Chip>
      <Tooltip
        placement="top"
        variant="outlined"
        arrow
        title={params.row.slug ? <UserTooltip slug={params.row.slug} /> : null}
      >
        <InfoOutlinedIcon color="disabled" />
      </Tooltip>
    </>
  );
}

export default function JoinRequests() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const { teamSlug } = useParams();
  const teamMembersQuery = useTeamMembersQuery({
    variables: { slug: teamSlug as string },
  });
  const teamJoinRequests = useTeamJoinRequestsQuery({
    variables: { teamId: teamCtx.team.id },
  });
  const [approveTeamJoinRequestMutation] = useApproveTeamJoinRequestMutation(
    {}
  );
  const [rejectTeamJoinRequestMutation] = useRejectTeamJoinRequestMutation({});

  const rows = (teamJoinRequests.data?.team_request_list.items ?? []).map(
    (req) => ({
      id: req.id,
      status: req.status,
      slug: req.user.slug,
      firstName: req.user.firstName,
      lastName: req.user.lastName,
      createdAt: req.createdAt,
    })
  );

  const handleApprove = useCallback(
    (id: number) => {
      approveTeamJoinRequestMutation({
        variables: {
          id,
        },
        onCompleted() {
          teamJoinRequests.refetch();
          teamMembersQuery.refetch();
        },
      });
    },
    [teamJoinRequests, approveTeamJoinRequestMutation, teamMembersQuery]
  );

  const handleReject = useCallback(
    (id: number) => {
      rejectTeamJoinRequestMutation({
        variables: {
          id,
        },
        onCompleted() {
          teamJoinRequests.refetch();
        },
      });
    },
    [rejectTeamJoinRequestMutation, teamJoinRequests]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 10,
        editable: false,
      },
      {
        field: "slug",
        headerName: "User Name",
        width: 240,
        editable: false,
        renderCell: UserRenderCell,
      },
      {
        field: "firstName",
        headerName: "First Name",
        width: 120,
        editable: false,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 120,
        editable: false,
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        editable: false,
      },
      {
        field: "createdAt",
        type: "date",
        valueGetter: (value: string | null) => {
          if (!value) return value;
          return new Date(value);
        },
        headerName: "Created At",
        width: 120,
        editable: false,
      },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<DoneOutlineOutlinedIcon color="success" />}
            label={t("Approve")}
            onClick={() => handleApprove(params.id)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<NotInterestedOutlinedIcon color="error" />}
            label={t("Reject")}
            onClick={() => handleReject(params.id)}
            showInMenu
          />,
        ],
      },
    ],
    [handleApprove, handleReject, t]
  );

  return (
    <Box sx={{ m: 2 }}>
      <Box sx={{ display: "flex", mb: 2 }}>
        <Typography sx={{ flex: 1 }} level="h4">
          {t("Join Requests")}
        </Typography>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="cell"
        showColumnVerticalBorder
        showCellVerticalBorder
        rowHeight={36}
        disableVirtualization={false}
        disableColumnMenu
        disableColumnFilter
        hideFooter
      />
      <Outlet />
    </Box>
  );
}
