import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardOverflow,
  Chip,
  Divider,
  Stack,
  Typography,
  useColorScheme,
} from "@mui/joy";
import { InspectionOpeningFragment } from "../../../__generated__/types-and-hooks";
import { DateTime } from "../../../components/DateTime";
import { ServiceTypeIcon } from "../../../components/ServiceTypeIcon";
import { useTranslation } from "../../../i18n";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import CountryFlag from "../../../components/CountryFlag";
import { useCallback } from "react";
import Money from "../../../components/Money";
import InspectionRef from "../../../components/InspectionRef";
import { useTeam } from "../TeamLayout";

export interface OpenInspectionCardProps {
  inspection: InspectionOpeningFragment;
  onClickQuote: (inspection: InspectionOpeningFragment) => void;
}

export default function OpenInspectionCard({
  inspection,
  onClickQuote,
}: OpenInspectionCardProps) {
  const { mode } = useColorScheme();
  const teamCtx = useTeam();
  const t = useTranslation();
  const handleClickQuote = useCallback(
    () => onClickQuote(inspection),
    [onClickQuote, inspection]
  );
  return (
    <Card size="lg" variant="outlined" sx={{ boxShadow: "sm" }}>
      <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
        <CardContent
          orientation="horizontal"
          sx={{ alignItems: "center", gap: 1 }}
        >
          <Box
            sx={{
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                m: "-2px",
                borderRadius: "50%",
                background:
                  "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
              },
            }}
          >
            <Avatar
              size="sm"
              src={
                inspection.brand.picture && inspection.brand.picture.length > 0
                  ? inspection.brand.picture
                  : mode === "light"
                  ? "/images/qcsku_light.jpg"
                  : "/images/qcsku_dark.jpg"
              }
            />
          </Box>
          <Typography sx={{ fontWeight: "lg", flex: 1 }}>
            {inspection.brand.name}
          </Typography>
          <InspectionRef
            inspectionRef={inspection.ref}
            teamSlug={teamCtx.team.slug}
          />
        </CardContent>
        <Divider inset="context" />
      </CardOverflow>
      <Box maxHeight={60} minHeight={60}>
        {/* [PSI] 10 SKUs, Location: Shanghai, Date: 2024-12-25. */}
        {inspection.serviceType && (
          <ServiceTypeIcon value={inspection.serviceType} />
        )}
        {inspection.supplier && (
          <Chip size="sm">{inspection.supplier?.name}</Chip>
        )}
        {inspection.supplier?.countryCode && (
          <CountryFlag code={inspection.supplier?.countryCode} />
        )}
        <Chip size="sm">
          <DateTime value={inspection.serviceDate}></DateTime>
        </Chip>
        {/* {inspection.samples && (
          <ServiceTypeIcon value={inspection.serviceType} />
        )} */}
      </Box>
      {/* Expires in 3 days. "High Priority," "New," or "Urgent." */}
      <CardActions orientation="horizontal" sx={{ m: 0, p: 0 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          m={0}
          p={0}
        >
          <Button variant="soft" color="neutral" size="sm">
            {t("Hide")}
          </Button>

          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            startDecorator={<ChatBubbleOutlineOutlinedIcon />}
          >
            {t("Q&A")}
          </Button>

          {inspection.quotes.length === 0 && (
            <Button
              variant="solid"
              color="primary"
              size="sm"
              startDecorator={"$"}
              onClick={handleClickQuote}
            >
              {t("Quote")}
            </Button>
          )}
          {inspection.quotes.length > 0 && (
            <Button
              variant="outlined"
              color="success"
              size="sm"
              startDecorator={
                <Money
                  value={
                    inspection.quotes[inspection.quotes.length - 1].totalCosts!
                  }
                  currency={
                    inspection.quotes[inspection.quotes.length - 1].currency!
                  }
                />
              }
              onClick={handleClickQuote}
            >
              {t("Quote")}
            </Button>
          )}
        </Stack>
      </CardActions>
      {/* Urgency Indicators */}
      <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
        <Divider inset="context" />
        <Stack direction="row" gap={2}>
          <Typography
            m={1}
            level="body-xs"
            textColor="text.secondary"
            sx={{ fontWeight: "sm" }}
          >
            EXP in <b>3 days</b>
          </Typography>
          <Divider orientation="vertical" />
          <Box>
            <Chip
              size="sm"
              sx={{ borderRadius: 4 }}
              variant="plain"
              color="warning"
            >
              #NEW
            </Chip>{" "}
            <Chip
              size="sm"
              sx={{ borderRadius: 4 }}
              variant="plain"
              color="primary"
            >
              #URGENT
            </Chip>
          </Box>
        </Stack>
      </CardOverflow>
    </Card>
  );
}
