import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardOverflow,
  Chip,
  Divider,
  Link,
  Stack,
  Typography,
  useColorScheme,
} from "@mui/joy";
import { JoinStatus } from "../../../__generated__/types-and-hooks";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "../../../i18n";

export interface TeamMiniCardProps {
  name: string;
  slug: string;
  picture?: string;
  roles?: string[];
  joinStatus?: JoinStatus;
  description?: string | null;
}

export default function TeamMiniCard({
  name,
  slug,
  picture,
  roles,
  joinStatus,
}: TeamMiniCardProps) {
  const { mode } = useColorScheme();
  const t = useTranslation();

  const navigate = useNavigate();
  const handleOpen = useCallback(() => {
    navigate("/" + slug);
  }, [navigate, slug]);

  return (
    <Card
      size="sm"
      variant={joinStatus ? "soft" : "outlined"}
      sx={{ boxShadow: "sm" }}
    >
      <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
        <CardContent
          orientation="horizontal"
          sx={{ alignItems: "center", gap: 1 }}
        >
          <Box
            sx={{
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                m: "-2px",
                borderRadius: "50%",
                background:
                  "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
              },
            }}
          >
            <Avatar
              size="sm"
              src={
                picture
                  ? picture
                  : mode === "light"
                  ? "/images/qcsku_light.jpg"
                  : "/images/qcsku_dark.jpg"
              }
            />
          </Box>

          <Link
            target="_blank"
            href={process.env.REACT_APP_URL + "/team/" + slug}
          >
            <Typography
              noWrap
              sx={{ fontWeight: "lg", flex: 1 }}
              endDecorator={<OpenInNewIcon />}
            >
              {name.slice(0, 16)}
              {name.length > 16 ? "..." : null}
            </Typography>
          </Link>
        </CardContent>
        <Divider inset="context" />
      </CardOverflow>
      <Stack maxHeight={60} minHeight={60} gap={1}>
        <Box>
          {roles?.map((role) => (
            <Chip key={role} size="sm">
              {role}
            </Chip>
          ))}
        </Box>
        {joinStatus && (
          <Box sx={{ textAlign: "center", opacity: 0.6, p: 1 }}>
            {joinStatus === "PENDING" && (
              <Typography level="title-lg" color="neutral">
                {t("Under Review")}
              </Typography>
            )}
            {joinStatus === "REJECTED" && (
              <Typography level="title-lg" color="danger">
                {t("Rejected")}
              </Typography>
            )}
            {joinStatus === "APPROVED" && (
              <Typography level="title-lg" color="success">
                {t("Approved")}
              </Typography>
            )}
          </Box>
        )}
      </Stack>

      <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
        {!joinStatus && <Button onClick={handleOpen}>{t("Open")}</Button>}
      </CardOverflow>
    </Card>
  );
}
