import {
  Alert,
  Button,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { BehaviorSubject, combineLatest, firstValueFrom } from "rxjs";
import { useTranslation } from "../../../../i18n";
import {
  useGenralTeamSettingsQuery,
  useTeamUpdateMutation,
} from "../../../../__generated__/types-and-hooks";
import { XTextInput } from "../../../../x-components/XTextInput";

export interface NameEditorModalProps {
  open: boolean;
}

export default function NameEditorModal({ open }: NameEditorModalProps) {
  const t = useTranslation();
  const name$ = useMemo(() => new BehaviorSubject<string>(""), []);
  const description$ = useMemo(() => new BehaviorSubject<string>(""), []);
  const navigate = useNavigate();

  const [teamUpdateMutation, { error }] = useTeamUpdateMutation({});

  const { teamSlug } = useParams();
  const genralTeamSettingsQuery = useGenralTeamSettingsQuery({
    variables: { slug: teamSlug! },
  });

  const team = genralTeamSettingsQuery.data?.team_by_slug;

  useEffect(() => {
    name$.next(team?.name ?? "");
    description$.next(team?.description ?? "");
  }, [team, name$, description$]);

  const handleClose = useCallback(() => {
    navigate("../");
  }, [navigate]);

  const handleSave = useCallback(
    (e: any) => {
      e.preventDefault();

      if (team) {
        firstValueFrom(
          combineLatest({ name: name$, description: description$ })
        ).then(({ name, description }) => {
          teamUpdateMutation({
            variables: {
              id: team.id,
              name,
              description,
              contacts: [],
            },
            onCompleted() {
              genralTeamSettingsQuery.refetch();
              handleClose();
            },
          });
        });
      }
    },
    [
      teamUpdateMutation,
      handleClose,
      genralTeamSettingsQuery,
      team,
      name$,
      description$,
    ]
  );

  return (
    <Modal open={open} onClose={() => navigate("../")}>
      <ModalDialog size="md" variant="outlined">
        <DialogTitle>
          {t("Change Name & Description")}
          <ModalClose />
        </DialogTitle>
        <form>
          <Stack spacing={2}>
            <XTextInput
              label={t("Name")}
              size="sm"
              placeholder={t("Company Name")}
              val$={name$}
              showError
            />
            <XTextInput
              label={t("Description")}
              size="sm"
              placeholder={t("Short description")}
              val$={description$}
              showError
            />
            <Button onClick={handleSave} type="submit" fullWidth>
              {t("Save")}
            </Button>
            {error && <Alert color="danger">{error.message}</Alert>}
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
