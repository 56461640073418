import Box from "@mui/joy/Box";
import { useParams } from "react-router-dom";
import { useInspectionByRefQuery } from "../../../__generated__/types-and-hooks";
import { useTeam } from "../TeamLayout";
import InspectionRef from "../../../components/InspectionRef";

export default function Inspection() {
  const teamCtx = useTeam();
  const { ref } = useParams();
  const { data } = useInspectionByRefQuery({
    variables: { ref: ref! },
  });

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <InspectionRef inspectionRef={ref!} teamSlug={teamCtx.team.slug} />
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          minHeight: "calc(100vh - 150px)",
        }}
      >
        <pre>{JSON.stringify(data, null, "\t")}</pre>
      </Box>
    </>
  );
}
