import {
  Alert,
  Button,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { useCallback, useEffect, useMemo } from "react";
import {
  useUserMeQuery,
  useUserUpdateMutation,
} from "../../__generated__/types-and-hooks";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { XTextArea } from "../../x-components/XTextArea";

export interface AboutEditorModalProps {
  open: boolean;
}

export default function AboutEditorModal({ open }: AboutEditorModalProps) {
  const t = useTranslation();
  const about$ = useMemo(() => new BehaviorSubject<string | null>(null), []);
  const navigate = useNavigate();
  const showError = true;

  const [userUpdateMutation, { error }] = useUserUpdateMutation({});

  const userMeQuery = useUserMeQuery();
  const user = userMeQuery.data?.user_me;

  useEffect(() => {
    about$.next(user?.bio ?? "");
  }, [user, about$]);

  const handleClose = useCallback(() => {
    navigate("../");
  }, [navigate]);
  const handleSave = useCallback(
    (e: any) => {
      e.preventDefault();

      if (user) {
        firstValueFrom(about$).then((about) => {
          userUpdateMutation({
            variables: {
              id: user.id,
              bio: about,
            },
            onCompleted() {
              userMeQuery.refetch();
              handleClose();
            },
          });
        });
      }
    },
    [userUpdateMutation, handleClose, userMeQuery, user, about$]
  );

  return (
    <Modal open={open} onClose={() => navigate("../")}>
      <ModalDialog size="md" variant="outlined">
        <DialogTitle>
          {t("Change Profile Bio")}
          <ModalClose />
        </DialogTitle>
        <form>
          <Stack spacing={2}>
            <Typography level="body-xs" sx={{ marginTop: 1 }}>
              {t(
                "Highlight your expertise, accomplishments, and professional background."
              )}
            </Typography>
            <XTextArea
              label={t("Bio")}
              size="sm"
              placeholder={t("")}
              val$={about$}
              showError={showError}
              minRows={10}
            />
            <Button onClick={handleSave} type="submit" fullWidth>
              {t("Save")}
            </Button>
            {error && <Alert color="danger">{error.message}</Alert>}
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
