import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  InspectionListQuery,
  useInspectionUpdateMutation,
} from "../../../../__generated__/types-and-hooks";
import { useTeam } from "../../TeamLayout";
import { useCallback, useMemo, useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "../../../../i18n";
import { Box, Chip, IconButton } from "@mui/joy";
import { PSIValue } from "../../../../components/inspections/PSI";
import { InlineValue } from "../../../../components/inspections/Inline";
import { FAValue } from "../../../../components/inspections/FA";
import { ServiceTypeIcon } from "../../../../components/ServiceTypeIcon";
import AssignedIcon from "@mui/icons-material/Group";
import PrivateIcon from "@mui/icons-material/Lock";
import PublicIcon from "@mui/icons-material/Public";
import CategoryIcon from "@mui/icons-material/Category";
import QuotesModal from "./QuotesModal";
import RequestQuoteIcon from "@mui/icons-material/RequestQuoteOutlined";
import InspectionRef from "../../../../components/InspectionRef";

export type TableViewInspections = any;

export interface TableViewProps {
  inspections: TableViewInspections[];
}

type GQLInspectionType = NonNullable<
  InspectionListQuery["inspection_list"]
>["inspections"][number];

export default function TableView({ inspections }: TableViewProps) {
  const t = useTranslation();
  const { team } = useTeam();
  const [inspectionUpdateMutation] = useInspectionUpdateMutation({});
  const [inspectionRef, setInspectionRef] = useState<string | null>(null);
  const [quotesModalOpen, setQuotesModalOpen] = useState(false);
  const handleQuotesModalClose = useCallback(() => {
    setQuotesModalOpen(false);
  }, [setQuotesModalOpen]);

  function RefRenderCell(
    params: GridRenderCellParams<GQLInspectionType, GQLInspectionType["ref"]>
  ) {
    return (
      <InspectionRef inspectionRef={params.row.ref} teamSlug={team.slug} />
    );
  }

  function ServiceTypeRenderCell(
    params: GridRenderCellParams<
      GQLInspectionType,
      GQLInspectionType["serviceType"]
    >
  ) {
    return <ServiceTypeIcon value={params.value ?? "PSI"} />;
  }

  function VisibilityRenderCell(
    params: GridRenderCellParams<
      GQLInspectionType,
      GQLInspectionType["visibility"]
    >
  ) {
    return (
      <Chip
        size={"sm"}
        sx={{ borderRadius: 4 }}
        startDecorator={
          <>
            {params.value === "PUBLIC" && <PublicIcon />}
            {params.value === "ASSIGNED" && <AssignedIcon />}
            {params.value === "PRIVATE" && <PrivateIcon />}
          </>
        }
      >
        {params.value}
      </Chip>
    );
  }

  function StatusRenderCell(
    params: GridRenderCellParams<GQLInspectionType, GQLInspectionType["status"]>
  ) {
    const statusStylesMap = {
      DRAFT: { backgroundColor: "#bdbdbd", color: "#000" }, // Grey
      PENDING: { backgroundColor: "#ffb74d", color: "#000" }, // Light orange
      CONFIRMED: { backgroundColor: "#0d47a1", color: "#fff" }, // Dark blue
      CANCELLED: { backgroundColor: "#d32f2f", color: "#fff" }, // Red
      INPROGRESS: { backgroundColor: "#42a5f5", color: "#000" }, // Light blue
      COMPLETED: { backgroundColor: "#388e3c", color: "#fff" }, // Green
    };

    const status = params.value as keyof typeof statusStylesMap;
    const chipStyles = statusStylesMap[status] || {
      backgroundColor: "#e0e0e0",
      color: "#000",
    };
    return (
      <Chip size={"sm"} sx={{ ...chipStyles, borderRadius: 4 }}>
        {status}
      </Chip>
    );
  }

  function QuoteRenderCell(
    params: GridRenderCellParams<GQLInspectionType, GQLInspectionType["quotes"]>
  ) {
    if (params.row.quotes && params.row.quotes.length > 0) {
      return (
        <IconButton
          size="sm"
          variant={"outlined"}
          sx={{ fontSize: 12, "--IconButton-size": "26px" }}
          onClick={() => {
            setQuotesModalOpen(true);
            setInspectionRef(params.row.ref);
          }}
        >
          {params.row.quotes.length} <RequestQuoteIcon />
        </IconButton>
      );
    }
    return null;
  }

  function SamplesRenderCell(
    params: GridRenderCellParams<
      GQLInspectionType,
      GQLInspectionType["samples"]
    >
  ) {
    if (!params.row.samples || params.row.samples.length === 0) {
      return (
        <>
          42 SKUs{" "}
          <IconButton
            sx={{ fontSize: 12, p: 0, m: 0, "--IconButton-size": "26px" }}
            size="sm"
            variant={"outlined"}
          >
            <CategoryIcon />
          </IconButton>
        </>
      );
    }

    return params.row.samples?.map((sample) => sample.sku).join(", ");
  }

  // function AgencyRenderCell(
  //   params: GridRenderCellParams<GQLInspectionType, GQLInspectionType["agency"]>
  // ) {
  //   return (
  //     <>
  //       <Chip size={"sm"} sx={{ borderRadius: 4 }}>
  //         {params.row.agency?.name}
  //       </Chip>

  //       <Tooltip
  //         placement="top"
  //         variant="outlined"
  //         arrow
  //         title={
  //           params.row.agency ? (
  //             <AgencyTooltip agency={params.row.agency} />
  //           ) : null
  //         }
  //       >
  //         <InfoOutlinedIcon color="disabled" />
  //       </Tooltip>
  //     </>
  //   );
  // }

  // function AgencyEditCell(props: GridRenderEditCellParams) {
  //   const { id, value, field, hasFocus } = props;
  //   const apiRef = useGridApiContext();

  //   return (
  //     <AgencyAutocomplete
  //       hasFocus={hasFocus}
  //       agency={value}
  //       onChange={(agency) => {
  //         apiRef.current.setEditCellValue({
  //           id,
  //           field,
  //           value: {
  //             ...agency,
  //           },
  //         });
  //         apiRef.current.stopCellEditMode({ id, field });
  //       }}
  //     />
  //   );
  // }

  // function SupplierRenderCell(
  //   params: GridRenderCellParams<
  //     GQLInspectionType,
  //     GQLInspectionType["supplier"]
  //   >
  // ) {
  //   return (
  //     <>
  //       <Chip size={"sm"} sx={{ borderRadius: 4 }}>
  //         {params.row.supplier?.name}
  //       </Chip>
  //       <Tooltip
  //         placement="top"
  //         variant="outlined"
  //         arrow
  //         title={
  //           params.row.supplier ? (
  //             <SupplierTooltip supplier={params.row.supplier} />
  //           ) : null
  //         }
  //       >
  //         <InfoOutlinedIcon color="disabled" />
  //       </Tooltip>
  //     </>
  //   );
  // }

  // function SupplierEditComponent(props: GridRenderEditCellParams) {
  //   const { id, value, field, hasFocus } = props;
  //   const apiRef = useGridApiContext();

  //   return (
  //     <SupplierAutocomplete
  //       hasFocus={hasFocus}
  //       teamId={team.id}
  //       supplier={value}
  //       onChange={(supplier) => {
  //         apiRef.current.setEditCellValue({
  //           id,
  //           field,
  //           value: {
  //             ...supplier,
  //           },
  //         });
  //         apiRef.current.stopCellEditMode({ id, field });
  //       }}
  //     />
  //   );
  // }

  const handleDelete = (params: GridRowParams<any>) => {
    // setSamples(samples.filter((sample) => sample.id !== params.id));
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 10,
        editable: false,
      },
      {
        field: "ref",
        headerName: "Ref #",
        width: 180,
        editable: false,
        renderCell: RefRenderCell,
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        editable: true,
        renderCell: StatusRenderCell,
        // renderEditCell: StatusRenderEditCell,
        type: "singleSelect",
        valueOptions: [
          "DRAFT",
          "PENDING",
          "CONFIRMED",
          "CANCELLED",
          "INPROGRESS",
          "COMPLETED",
        ],
      },
      {
        field: "visibility",
        headerName: "Visibility",
        width: 120,
        editable: true,
        renderCell: VisibilityRenderCell,
        // renderEditCell: StatusRenderEditCell,
        type: "singleSelect",
        valueOptions: [
          "PRIVATE", // Only visible to the creating brand.
          "PUBLIC", // Visible to all eligible agencies.
          "ASSIGNED",
        ],
      },
      // {
      //   field: "agency",
      //   headerName: "Agency",
      //   width: 200,
      //   editable: true,
      //   renderCell: AgencyRenderCell,
      //   renderEditCell: AgencyEditCell,
      // },
      {
        field: "quotes",
        headerName: "Quotes",
        width: 80,
        editable: false,
        renderCell: QuoteRenderCell,
      },

      {
        field: "serviceType",
        headerName: "Service Type",
        width: 120,
        editable: true,
        type: "singleSelect",
        renderCell: ServiceTypeRenderCell,
        valueOptions: [PSIValue, InlineValue, FAValue],
      },
      // {
      //   field: "supplier",
      //   headerName: "Supplier",
      //   width: 200,
      //   editable: true,
      //   renderCell: SupplierRenderCell,
      //   renderEditCell: SupplierEditComponent,
      // },
      {
        field: "samples",
        headerName: "Samples",
        width: 120,
        editable: true,
        renderCell: SamplesRenderCell,
      },
      {
        field: "serviceDate",
        type: "date",
        valueGetter: (value: string | null) => {
          if (!value) return value;
          return new Date(value);
        },
        headerName: "Service Date",
        width: 120,
        editable: true,
      },

      {
        field: "manDays",
        headerName: "Man Days",
        width: 120,
        editable: true,
      },
      {
        field: "rate",
        headerName: "Rate",
        width: 80,
        editable: true,
      },
      {
        field: "currency",
        headerName: "Currency",
        width: 80,
        editable: true,
      },
      {
        field: "createdAt",
        type: "date",
        valueGetter: (value: string | null) => {
          if (!value) return value;
          return new Date(value);
        },
        headerName: "Created At",
        width: 120,
        editable: false,
      },
      {
        field: "updatedAt",
        type: "date",
        valueGetter: (value: string | null) => {
          if (!value) return value;
          return new Date(value);
        },
        headerName: "Updated At",
        width: 120,
        editable: false,
      },
      {
        field: "actions",
        type: "actions",
        width: 10,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<DeleteOutlineOutlinedIcon />}
            label={t("Archive")}
            onClick={() => handleDelete(params)}
            showInMenu
          />,
        ],
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleProcessRowUpdate = useCallback(
    async (
      newRow: GridRowModel,
      oldRow: GridRowModel,
      { rowId }: { rowId: GridRowId }
    ) => {
      const delta = (field: string, key?: string) =>
        newRow[field] !== oldRow[field]
          ? typeof key === "string" && typeof newRow[field] === "object"
            ? newRow[field][key]
            : newRow[field]
          : void 0;

      await inspectionUpdateMutation({
        variables: {
          updates: {
            id: rowId as number,
            status: delta("status"),
            visibility: delta("visibility"),
            serviceType: delta("serviceType"),
            serviceDate: delta("serviceDate"),
            supplierId: delta("supplier", "id"),
            agencyId: delta("agency", "id"),
            address: void 0,
            notes: void 0,
          },
        },
      });

      return newRow;
    },
    [inspectionUpdateMutation]
  );

  return (
    <Box>
      <DataGrid
        sx={{ backgroundColor: "var(--joy-palette-background-body)" }}
        rows={inspections}
        columns={columns}
        editMode="cell"
        showColumnVerticalBorder
        showCellVerticalBorder
        rowHeight={36}
        disableVirtualization={false}
        disableColumnMenu
        disableColumnFilter
        //disableRowSelectionOnClick
        processRowUpdate={handleProcessRowUpdate}
      />
      {quotesModalOpen && inspectionRef && (
        <QuotesModal
          inspectionRef={inspectionRef}
          open={quotesModalOpen}
          onClose={handleQuotesModalClose}
        />
      )}
    </Box>
  );
}
