import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { useTranslation } from "../../i18n";
import SignUpForm, { SignUpVM } from "./SignUpForm";
import { useMemo } from "react";

export default function SignUp() {
  const t = useTranslation();
  const vm = useMemo(() => new SignUpVM(t), [t]);

  return (
    <>
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <Typography component="h1" level="h3">
            {t("Sign up")}
          </Typography>
          <Typography level="body-sm">
            {t("Already have an account?")}{" "}
            <Link href="/auth/sign-in" level="title-sm">
              {t("Sign in!")}
            </Link>
          </Typography>
          <Typography level="body-sm">
            <Link level="title-sm" href="/auth/forgot-password">
              {t("Forgot your password?")}
            </Link>
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={4} sx={{ mt: 2 }}>
        <SignUpForm vm={vm} />
      </Stack>
    </>
  );
}
