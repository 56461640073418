import { Box, Button, Typography } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "../../../i18n";
import UserApiKeys from "./UserAPIKeys";
import { Outlet, useNavigate } from "react-router-dom";

export default function ApiKeys() {
  const t = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h4">
          {t("API Keys")}
        </Typography>
        <Button
          startDecorator={<AddIcon />}
          variant="solid"
          onClick={() => navigate("./create")}
        >
          {t("Add")}
        </Button>
      </Box>
      <Box sx={{ m: 2 }}>
        <UserApiKeys />
      </Box>
      <Outlet />
    </>
  );
}
