import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useSupplierUpsertMutation } from "../../../__generated__/types-and-hooks";
import { useTeam } from "../TeamLayout";
import { useTranslation } from "../../../i18n";
import { useNotify } from "../../../hooks/useNotify";
import EditArrayCell from "../../../components/datagrid/EditArrayCell";
import ArrayCell from "../../../components/datagrid/ArrayCell";

export type TableViewSupplier = any;

export interface TableViewProps {
  suppliers: TableViewSupplier[];
}

export default function TableView({ suppliers }: TableViewProps) {
  const t = useTranslation();
  const teamCtx = useTeam();
  const notify = useNotify();
  const [supplierUpsertMutation] = useSupplierUpsertMutation({});
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "#",
        width: 10,
        editable: false,
      },
      {
        field: "name",
        headerName: t("Name"),
        width: 200,
        editable: true,
      },
      {
        field: "capabilities",
        headerName: t("Capabilities"),
        width: 200,
        editable: true,
        renderCell: (params) => <ArrayCell {...params} />,
        renderEditCell: (params) => <EditArrayCell {...params} />,
      },
      {
        field: "addressLine1",
        headerName: t("Address Line 1"),
        width: 200,
        editable: true,
      },
      {
        field: "addressLine2",
        headerName: t("Address Line 2"),
        width: 100,
        editable: true,
      },
      {
        field: "city",
        headerName: t("City"),
        width: 100,
        editable: true,
      },
      {
        field: "state",
        headerName: t("State"),
        width: 100,
        editable: true,
      },
      {
        field: "postalCode",
        headerName: t("Postal Code"),
        width: 100,
        editable: true,
      },
      {
        field: "countryCode",
        headerName: t("Country"),
        width: 100,
        editable: true,
      },
    ],
    [t]
  );

  const handleProcessRowUpdate = useCallback(
    async (
      newRow: GridRowModel,
      oldRow: GridRowModel,
      { rowId }: { rowId: GridRowId }
    ) => {
      const delta = (field: string, key?: string) =>
        newRow[field] !== oldRow[field]
          ? typeof key === "string" && typeof newRow[field] === "object"
            ? newRow[field][key]
            : newRow[field]
          : void 0;

      const res = await supplierUpsertMutation({
        variables: {
          supplier: {
            teamId: teamCtx.team.id,
            id: rowId as number,
            name: delta("name"),
            addressLine1: delta("addressLine1"),
            addressLine2: delta("addressLine2"),
            city: delta("city"),
            state: delta("state"),
            postalCode: delta("postalCode"),
            countryCode: delta("countryCode"),
            capabilities: newRow.capabilities ?? oldRow.capabilities,
          },
        },
      });
      if (res.errors) {
        throw new Error(res.errors.map((e) => e.message).join("\n"));
      }
      return newRow;
    },
    [supplierUpsertMutation, teamCtx]
  );

  return (
    <DataGrid
      rows={suppliers}
      columns={columns}
      editMode="cell"
      showColumnVerticalBorder
      showCellVerticalBorder
      rowHeight={36}
      disableVirtualization={false}
      disableColumnMenu
      disableColumnFilter
      processRowUpdate={handleProcessRowUpdate}
      onProcessRowUpdateError={(err) => {
        notify({
          type: "danger",
          msg: err.message,
        });
      }}
    />
  );
}
