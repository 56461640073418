import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import { AspectRatio, Option, Select } from "@mui/joy";
import {
  InspectionOpeningFragment,
  useTopOpeningsQuery,
} from "../../../__generated__/types-and-hooks";
import OpenInspectionCard from "./OpenInspectionCard";
import QuoteModal from "./QuoteModal";
import { useCallback, useState } from "react";
import SortIcon from "@mui/icons-material/Sort";

export default function Openings() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const inspectionsListQuery = useTopOpeningsQuery({
    variables: { teamId: teamCtx.team.id },
  });

  const inspections =
    inspectionsListQuery.data &&
    inspectionsListQuery.data.inspection_list?.inspections;

  const [inspectionQuote, setInspectionQuote] =
    useState<InspectionOpeningFragment | null>(null);
  const handleClickQuote = useCallback(
    (inspection: InspectionOpeningFragment) => {
      setInspectionQuote(inspection);
    },
    []
  );

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Inquiries")}
        </Typography>

        {/* Filter by: Dropdown with options: Inspection Type, Brand, Location, Inspection Date,
        Status (New/Urgent). */}
        {/* <Select defaultValue="most_recent" startDecorator={<FilterListIcon />}>
          <Option value="most_recent">Most Recent</Option>
          <Option value="service_date">Closest Service Date</Option>
          <Option value="brand_name">Brand Name</Option>
        </Select> */}

        <Select defaultValue="most_recent" startDecorator={<SortIcon />}>
          <Option value="most_recent">Most Recent</Option>
          <Option value="service_date">Closest Service Date</Option>
          <Option value="brand_name">Brand Name</Option>
        </Select>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          minHeight: "calc(100vh - 150px)",
        }}
      >
        {inspections && inspections.length === 0 && (
          <>
            <Typography color="neutral" level="body-md">
              {t(
                "No Open Inspections Yet! Try to refresh this page or come back later."
              )}
            </Typography>

            <AspectRatio
              variant="plain"
              ratio={1}
              objectFit="contain"
              maxHeight={"50vh"}
              sx={{ bgcolor: "transparent" }}
            >
              <img
                alt={t("No Open Inspections Yet")}
                src="/images/inspections_empty_state.png"
              />
            </AspectRatio>
          </>
        )}
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns:
              "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
            gap: 2,
          }}
        >
          {inspections &&
            inspections.length > 0 &&
            inspections.map((inspection) => (
              <OpenInspectionCard
                inspection={inspection}
                onClickQuote={handleClickQuote}
              />
            ))}
        </Box>
      </Box>
      <QuoteModal
        inspection={inspectionQuote}
        onClose={() => setInspectionQuote(null)}
      />
    </>
  );
}
