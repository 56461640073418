import * as React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Popper } from "@mui/base/Popper";
import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteListbox from "@mui/joy/AutocompleteListbox";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import ListSubheader from "@mui/joy/ListSubheader";
import {
  Avatar,
  Box,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import { Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";
import {
  TeamSearchFragment,
  useXTeamsAutocompleteQuery,
} from "../__generated__/types-and-hooks";

const LISTBOX_PADDING = 6; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <AutocompleteOption {...dataSet[0]} style={inlineStyle}>
      <ListItemDecorator>
        <Box
          sx={{
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              m: "-2px",
              borderRadius: "50%",
              background:
                "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
            },
          }}
        >
          <Avatar
            size="sm"
            src={
              dataSet[0].picture ? dataSet[0].picture : "/images/qcsku_dark.jpg"
            }
          />
        </Box>
      </ListItemDecorator>
      <ListItemContent
        sx={{
          fontSize: "sm",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Typography level="title-sm">{dataSet[1]?.name}</Typography>
        <Typography
          level="body-xs"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          https://{dataSet[1].slug}.qcsku.com
        </Typography>
      </ListItemContent>
    </AutocompleteOption>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return (
    <AutocompleteListbox
      {...props}
      {...outerProps}
      component="div"
      ref={ref}
      sx={{
        "& ul": {
          padding: 0,
          margin: 0,
          flexShrink: 0,
        },
      }}
    />
  );
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  {
    anchorEl: any;
    open: boolean;
    modifiers: any[];
  } & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, anchorEl, open, modifiers, ...other } = props;
  const itemData: Array<any> = [];
  (
    children as [
      Array<{ children: Array<React.ReactElement<any>> | undefined }>
    ]
  )[0].forEach((item) => {
    if (item) {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  });

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper
      style={{ zIndex: 999999 }}
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      modifiers={modifiers}
    >
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={itemSize * 8}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
});

export interface XTeamsAutocompleteProps {
  val$: Subject<TeamSearchFragment | null>;
  placeholder?: string;
}

export function XTeamsAutocomplete({
  val$,
  placeholder,
}: XTeamsAutocompleteProps) {
  const val = useRxVal(val$);

  const teamListQuery = useXTeamsAutocompleteQuery();

  const handleChanges = React.useCallback(
    (e: any, value: TeamSearchFragment | null) => {
      val$.next(value);
    },
    [val$]
  );

  const isLoading = teamListQuery.loading;
  const teams = teamListQuery.data?.team_list?.teams;
  if (!teams) return null;

  return (
    <Autocomplete
      size="sm"
      sx={{ width: "100%" }}
      value={val ? teams.find((s) => s.id === val.id) : null}
      loading={isLoading}
      disableListWrap
      placeholder={placeholder}
      onChange={handleChanges as any}
      slots={{
        listbox: ListboxComponent,
      }}
      options={teams}
      // groupBy={(option) => option.name.split(/[^0-9a-zA-Z]/)[0].toUpperCase()}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => [props, option] as React.ReactNode}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params as unknown as React.ReactNode}
    />
  );
}
