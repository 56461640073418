import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import { ReactNode, useCallback, useMemo, useState } from "react";
import {
  SupplierContact,
  useContactUpsertMutation,
} from "../../../__generated__/types-and-hooks";
import { useTeam } from "../TeamLayout";
import { useTranslation } from "../../../i18n";
import { useNotify } from "../../../hooks/useNotify";
import ContactRef from "../../../components/ContactRef";
import { Box, Button, Chip } from "@mui/joy";
import Email from "@mui/icons-material/EmailOutlined";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Phone from "@mui/icons-material/LocalPhone";
import Message from "@mui/icons-material/Message";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ContactMethodsModal from "./EditContactMethodsModal";

export type TableViewContact = SupplierContact;

export interface TableViewProps {
  contacts: TableViewContact[];
}

// Map of contact method keys to corresponding icons
const iconMap: Record<string, ReactNode> = {
  phone: <Phone style={{ fontSize: 14 }} />,
  email: <Email style={{ fontSize: 14 }} />,
  whatsapp: <WhatsApp style={{ fontSize: 14 }} />,
};

export interface ContactMethodsProps {
  contact: SupplierContact;
  onEditClick: (contact: SupplierContact) => void;
}

function ContactMethods({ contact, onEditClick }: ContactMethodsProps) {
  const t = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        alignContent: "space-between",
        gap: 0.5,
      }}
    >
      <Box sx={{ overflow: "hidden", flex: 1 }}>
        {contact.contactMethods.map((method, index) => (
          <Chip
            key={index}
            variant="soft"
            size={"sm"}
            sx={{ borderRadius: 4 }}
            startDecorator={
              iconMap[method.key] ?? <Message style={{ fontSize: 14 }} />
            }
          >
            {method.value}
          </Chip>
        ))}
      </Box>
      <Button
        sx={{ fontSize: 12 }}
        size="sm"
        variant="plain"
        startDecorator={<ModeEditIcon style={{ fontSize: 14 }} />}
        onClick={() => onEditClick(contact)}
      >
        {t("Edit")}
      </Button>
    </Box>
  );
}

export default function TableView({ contacts }: TableViewProps) {
  const t = useTranslation();
  const teamCtx = useTeam();
  const team = teamCtx.team;
  const notify = useNotify();
  const [contactUpsertMutation] = useContactUpsertMutation({});

  const [contactMethodsOpen, setContactMethodsOpen] =
    useState<SupplierContact | null>(null);
  const handleContactMethodsEditClick = useCallback(
    (contact: SupplierContact) => {
      setContactMethodsOpen(contact);
    },
    []
  );

  function RefRenderCell(params: GridRenderCellParams<any, string>) {
    return <ContactRef contactRef={params.row.uniqueId} teamSlug={team.slug} />;
  }

  function ContactMethodsRenderCell(params: GridRenderCellParams<any, string>) {
    const contact = contacts.find((contact) => contact.id === params.row.id)!;
    return (
      <ContactMethods
        onEditClick={handleContactMethodsEditClick}
        contact={contact}
      />
    );
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "#",
        width: 10,
        editable: false,
      },
      {
        field: "uniqueId",
        headerName: t("Ref"),
        width: 180,
        editable: false,
        renderCell: RefRenderCell,
      },
      {
        field: "name",
        headerName: t("Full Name"),
        width: 200,
        editable: true,
      },
      {
        field: "contactMethods",
        headerName: t("Contact Methods"),
        width: 360,
        editable: false,
        renderCell: ContactMethodsRenderCell,
      },
      {
        field: "addressLine1",
        headerName: t("Address Line 1"),
        width: 200,
        editable: true,
      },
      {
        field: "addressLine2",
        headerName: t("Address Line 2"),
        width: 120,
        editable: true,
      },
      {
        field: "city",
        headerName: t("City"),
        width: 100,
        editable: true,
      },
      {
        field: "state",
        headerName: t("State"),
        width: 100,
        editable: true,
      },
      {
        field: "postalCode",
        headerName: t("Zip Code"),
        width: 100,
        editable: true,
      },
      {
        field: "countryCode",
        headerName: t("Country"),
        width: 100,
        editable: true,
      },
      {
        field: "suppliers",
        headerName: t("Suppliers"),
        width: 300,
        editable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const handleProcessRowUpdate = useCallback(
    async (
      newRow: GridRowModel,
      oldRow: GridRowModel,
      { rowId }: { rowId: GridRowId }
    ) => {
      const delta = (field: string, key?: string) =>
        newRow[field] !== oldRow[field]
          ? typeof key === "string" && typeof newRow[field] === "object"
            ? newRow[field][key]
            : newRow[field]
          : void 0;

      const res = await contactUpsertMutation({
        variables: {
          contact: {
            teamId: teamCtx.team.id,
            id: rowId as number,
            name: delta("name"),
            addressLine1: delta("addressLine1"),
            addressLine2: delta("addressLine2"),
            city: delta("city"),
            state: delta("state"),
            postalCode: delta("postalCode"),
            countryCode: delta("countryCode"),
          },
        },
      });
      if (res.errors) {
        throw new Error(res.errors.map((e) => e.message).join("\n"));
      }
      return newRow;
    },
    [contactUpsertMutation, teamCtx]
  );

  return (
    <>
      <DataGrid
        rows={contacts}
        columns={columns}
        editMode="cell"
        showColumnVerticalBorder
        showCellVerticalBorder
        rowHeight={36}
        disableVirtualization={false}
        disableColumnMenu
        disableColumnFilter
        disableRowSelectionOnClick
        processRowUpdate={handleProcessRowUpdate}
        onProcessRowUpdateError={(err) => {
          notify({
            type: "danger",
            msg: err.message,
          });
        }}
      />
      {contactMethodsOpen && (
        <ContactMethodsModal
          contact={contactMethodsOpen}
          onClose={() => setContactMethodsOpen(null)}
        />
      )}
    </>
  );
}
