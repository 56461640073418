import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTimeISO: { input: any; output: any; }
};

export type Aql = {
  __typename?: 'AQL';
  acceptPoint: Scalars['Float']['output'];
  /** AQL */
  aql: Scalars['Float']['output'];
  rejectPoint: Scalars['Float']['output'];
  sampleSize: Scalars['Float']['output'];
};

export type Anvyl = {
  __typename?: 'Anvyl';
  /** Anvyl API Key */
  anvylApiKey: Scalars['String']['output'];
  /** Anvyl Team Id */
  anvylTeamId: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  enabled: Scalars['Boolean']['output'];
  /** Integration Id */
  id: Scalars['Float']['output'];
  /** Team Id */
  teamId: Scalars['Float']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
};

export type AnvylInput = {
  /** Anvyl API Key. */
  anvylApiKey: Scalars['String']['input'];
  /** Anvyl Team ID. */
  anvylTeamId: Scalars['Float']['input'];
  /** Enabled */
  enabled: Scalars['Boolean']['input'];
  /** Team ID */
  teamId: Scalars['Float']['input'];
};

export type AuthorizationKey = {
  __typename?: 'AuthorizationKey';
  /** Expiration timestamp */
  exp: Scalars['DateTimeISO']['output'];
  /** Unique ID */
  id: Scalars['Float']['output'];
  /** Human readable authorization key */
  key: Scalars['String']['output'];
  /** Descriptive name of the key */
  name?: Maybe<Scalars['String']['output']>;
};

export type AuthorizationKeyInput = {
  /** Expiration timestamp */
  exp?: InputMaybe<Scalars['DateTimeISO']['input']>;
  /** Descriptive name of the key */
  name: Scalars['String']['input'];
};

export type Checklist = {
  __typename?: 'Checklist';
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Sku Id */
  id: Scalars['Float']['output'];
  items: Array<ChecklistItem>;
  /** Name */
  name: Scalars['String']['output'];
  /** Team Id */
  teamId: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type ChecklistItem = {
  __typename?: 'ChecklistItem';
  /** AQL Code */
  aqlCode: Scalars['String']['output'];
  /** Description */
  description: Scalars['String']['output'];
  /** Name */
  name: Scalars['String']['output'];
  /** Pass Criteria */
  passCriteria: Scalars['String']['output'];
};

export type ChecklistList = {
  __typename?: 'ChecklistList';
  checklists: Array<Checklist>;
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
};

export type ContactList = {
  __typename?: 'ContactList';
  contacts: Array<SupplierContact>;
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
};

export type ContactMethod = {
  __typename?: 'ContactMethod';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContactMethodInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Country = {
  __typename?: 'Country';
  /** Country code, e.g. US, RU, CN, etc. */
  code: Scalars['String']['output'];
  /** Name of the country, e.g. United States of America */
  label: Scalars['String']['output'];
  /** Phone extension, e.g. +1 */
  phone: Scalars['String']['output'];
};

export type CreateInspectionDraftInput = {
  /** Inspection Address */
  address?: InputMaybe<InspectionAddressInput>;
  /** Brand Team Id */
  brandId: Scalars['Float']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  serviceDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  /** Service Type */
  serviceType?: InputMaybe<Scalars['String']['input']>;
  /** Supplier Id */
  supplierId?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateInspectionQuoteInput = {
  /** Agency Id */
  agencyId: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  /** Inspection Id */
  inspectionId: Scalars['Float']['input'];
  manDays: Scalars['Float']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  serviceDateEnd: Scalars['DateTimeISO']['input'];
  serviceDateStart: Scalars['DateTimeISO']['input'];
  totalCosts: Scalars['Float']['input'];
};

export type CreateTeamInput = {
  /** Tell more about your company. */
  about?: InputMaybe<Scalars['String']['input']>;
  /** Team background picture. */
  background?: InputMaybe<Scalars['String']['input']>;
  contacts?: InputMaybe<Array<ContactMethodInput>>;
  /** Team Description. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Brand or Agency. */
  kind: TeamKind;
  /** Team Name. */
  name: Scalars['String']['input'];
  /** Avatar picture. */
  picture?: InputMaybe<Scalars['String']['input']>;
  /** Unique slug name for the team */
  slug: Scalars['String']['input'];
};

export type CreateUserInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  /** Email */
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  /** Password */
  password: Scalars['String']['input'];
  picture?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type Inspection = {
  __typename?: 'Inspection';
  /** Inspection Address */
  address?: Maybe<InspectionAddress>;
  /** Agency */
  agency?: Maybe<Team>;
  /** Brand */
  brand: Team;
  checklist?: Maybe<Checklist>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  /** Inspection Id */
  id: Scalars['Float']['output'];
  manDays?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  /** Quotes */
  quotes: Array<InspectionQuote>;
  rate?: Maybe<Scalars['Float']['output']>;
  /** Reference Code */
  ref: Scalars['String']['output'];
  samples: Array<Sample>;
  serviceDate?: Maybe<Scalars['DateTimeISO']['output']>;
  serviceType?: Maybe<Scalars['String']['output']>;
  /** Status */
  status: InspectionStatus;
  /** Supplier */
  supplier?: Maybe<Supplier>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
  /** Visibility */
  visibility: InspectionVisibility;
};

export type InspectionAddress = {
  __typename?: 'InspectionAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type InspectionAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type InspectionList = {
  __typename?: 'InspectionList';
  inspections: Array<Inspection>;
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
};

export type InspectionQuote = {
  __typename?: 'InspectionQuote';
  /** Agency */
  agency: Team;
  /** Agency Id */
  agencyId: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  manDays: Scalars['Float']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  serviceDateEnd: Scalars['DateTimeISO']['output'];
  serviceDateStart: Scalars['DateTimeISO']['output'];
  totalCosts?: Maybe<Scalars['Float']['output']>;
};

/** The status of inspection */
export enum InspectionStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Draft = 'DRAFT',
  Inprogress = 'INPROGRESS',
  Pending = 'PENDING'
}

/** The visibility of inspection */
export enum InspectionVisibility {
  Assigned = 'ASSIGNED',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

/** Pending, Approved or Rejected */
export enum JoinStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type Mutation = {
  __typename?: 'Mutation';
  anvyl_config_update: Anvyl;
  authorization_key_create: AuthorizationKey;
  authorization_key_revoke: AuthorizationKey;
  contact_upsert: SupplierContact;
  inspection_create_draft: Inspection;
  inspection_quote_create: Inspection;
  inspection_update: Inspection;
  sku_upsert: Sku;
  supplier_upsert: Supplier;
  team_add_user?: Maybe<Team>;
  team_create: Team;
  team_delete?: Maybe<Team>;
  team_request_approve?: Maybe<TeamJoinRequest>;
  team_request_reject?: Maybe<TeamJoinRequest>;
  team_request_to_join?: Maybe<TeamJoinRequest>;
  team_update: Team;
  user_create: User;
  user_delete?: Maybe<User>;
  user_send_verification_email: User;
  user_update: User;
};


export type MutationAnvyl_Config_UpdateArgs = {
  anvyl: AnvylInput;
};


export type MutationAuthorization_Key_CreateArgs = {
  authorizationKey: AuthorizationKeyInput;
};


export type MutationAuthorization_Key_RevokeArgs = {
  authorizationKeyId: Scalars['Float']['input'];
};


export type MutationContact_UpsertArgs = {
  contact: UpsertSupplierContactInput;
};


export type MutationInspection_Create_DraftArgs = {
  draft: CreateInspectionDraftInput;
};


export type MutationInspection_Quote_CreateArgs = {
  quote: CreateInspectionQuoteInput;
};


export type MutationInspection_UpdateArgs = {
  updates: UpdateInspectionInput;
};


export type MutationSku_UpsertArgs = {
  sku: SkuInput;
};


export type MutationSupplier_UpsertArgs = {
  supplier: UpsertSupplierInput;
};


export type MutationTeam_Add_UserArgs = {
  roles: Array<TeamRole>;
  teamId: Scalars['Float']['input'];
  userId: Scalars['Float']['input'];
};


export type MutationTeam_CreateArgs = {
  team: CreateTeamInput;
};


export type MutationTeam_DeleteArgs = {
  id: Scalars['Float']['input'];
};


export type MutationTeam_Request_ApproveArgs = {
  id: Scalars['Float']['input'];
};


export type MutationTeam_Request_RejectArgs = {
  id: Scalars['Float']['input'];
};


export type MutationTeam_Request_To_JoinArgs = {
  note?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['Float']['input'];
};


export type MutationTeam_UpdateArgs = {
  team: UpdateTeamInput;
};


export type MutationUser_CreateArgs = {
  user: CreateUserInput;
};


export type MutationUser_DeleteArgs = {
  id: Scalars['Float']['input'];
};


export type MutationUser_UpdateArgs = {
  user: UpdateUserInput;
};

export type Query = {
  __typename?: 'Query';
  anvyl_config?: Maybe<Anvyl>;
  authorization_key_list: Array<AuthorizationKey>;
  checklist_list?: Maybe<ChecklistList>;
  contact_by_id?: Maybe<SupplierContact>;
  contact_list?: Maybe<ContactList>;
  country_list?: Maybe<Array<Country>>;
  inspection_by_id?: Maybe<Inspection>;
  inspection_by_ref?: Maybe<Inspection>;
  inspection_list?: Maybe<InspectionList>;
  sku_by_id?: Maybe<Supplier>;
  sku_list?: Maybe<SkuList>;
  supplier_by_id?: Maybe<Supplier>;
  supplier_list?: Maybe<SupplierList>;
  team_by_id?: Maybe<Team>;
  team_by_slug?: Maybe<Team>;
  team_list?: Maybe<TeamList>;
  team_request_list: TeamJoinRequestList;
  user_by_id?: Maybe<User>;
  user_by_slug?: Maybe<User>;
  user_me: User;
};


export type QueryAnvyl_ConfigArgs = {
  teamId: Scalars['Float']['input'];
};


export type QueryChecklist_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
};


export type QueryContact_By_IdArgs = {
  uniqueId: Scalars['String']['input'];
};


export type QueryContact_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
};


export type QueryInspection_By_IdArgs = {
  id: Scalars['Float']['input'];
};


export type QueryInspection_By_RefArgs = {
  ref: Scalars['String']['input'];
};


export type QueryInspection_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
  teamKind: Scalars['String']['input'];
  visibility?: InputMaybe<InspectionVisibility>;
};


export type QuerySku_By_IdArgs = {
  uniqueId: Scalars['String']['input'];
};


export type QuerySku_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
};


export type QuerySupplier_By_IdArgs = {
  uniqueId: Scalars['String']['input'];
};


export type QuerySupplier_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  teamId: Scalars['Float']['input'];
};


export type QueryTeam_By_IdArgs = {
  id: Scalars['Float']['input'];
};


export type QueryTeam_By_SlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryTeam_ListArgs = {
  cursor?: InputMaybe<Scalars['Float']['input']>;
  filterBy?: InputMaybe<Scalars['String']['input']>;
  filterExp?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTeam_Request_ListArgs = {
  teamId: Scalars['Float']['input'];
};


export type QueryUser_By_IdArgs = {
  id: Scalars['Float']['input'];
};


export type QueryUser_By_SlugArgs = {
  slug: Scalars['String']['input'];
};

export type Sample = {
  __typename?: 'Sample';
  criticalDefects: Aql;
  inspectinLevel: Scalars['String']['output'];
  majorDefects: Aql;
  minorDefects: Aql;
  notes: Scalars['String']['output'];
  quantity: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
};

export type Sku = {
  __typename?: 'Sku';
  /** ASIN code */
  asin?: Maybe<Scalars['String']['output']>;
  /** Category */
  category?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Sku Description */
  description?: Maybe<Scalars['String']['output']>;
  /** HS code */
  hs?: Maybe<Scalars['String']['output']>;
  /** HTS code */
  hts?: Maybe<Scalars['String']['output']>;
  /** Sku Id */
  id: Scalars['Float']['output'];
  /** Material */
  material?: Maybe<Scalars['String']['output']>;
  /** Sku Name */
  name: Scalars['String']['output'];
  /** SKU number */
  sku: Scalars['String']['output'];
  /** Team Id */
  teamId: Scalars['Float']['output'];
  /** UPC code */
  upc?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
};

export type SkuInput = {
  /** ASIN code */
  asin?: InputMaybe<Scalars['String']['input']>;
  /** Category */
  category?: InputMaybe<Scalars['String']['input']>;
  /** Sku Description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** HS code */
  hs?: InputMaybe<Scalars['String']['input']>;
  /** HTS code */
  hts?: InputMaybe<Scalars['String']['input']>;
  /** SKU ID */
  id?: InputMaybe<Scalars['Float']['input']>;
  /** Material */
  material?: InputMaybe<Scalars['String']['input']>;
  /** Sku Name */
  name: Scalars['String']['input'];
  /** SKU number */
  sku: Scalars['String']['input'];
  /** Team ID */
  teamId: Scalars['Float']['input'];
  /** SKU Unique ID */
  uniqueId?: InputMaybe<Scalars['String']['input']>;
  /** UPC code */
  upc?: InputMaybe<Scalars['String']['input']>;
};

export type SkuList = {
  __typename?: 'SkuList';
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
  skus: Array<Sku>;
};

export type Supplier = {
  __typename?: 'Supplier';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  /** Supplier Capabilities */
  capabilities: Array<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Supplier Id */
  id: Scalars['Float']['output'];
  /** Supplier Name */
  name: Scalars['String']['output'];
  /** Parent Supplier Id */
  parentId?: Maybe<Scalars['Float']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  /** Team Id */
  teamId: Scalars['Float']['output'];
  /** Supplier Unique Id */
  uniqueId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
};

export type SupplierContact = {
  __typename?: 'SupplierContact';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contactMethods: Array<ContactMethod>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Contact Id */
  id: Scalars['Float']['output'];
  /** Contact Name */
  name: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  /** Contact Unique Id */
  uniqueId: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
};

export type SupplierList = {
  __typename?: 'SupplierList';
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
  suppliers: Array<Supplier>;
};

export type Team = {
  __typename?: 'Team';
  /** Tell more about your company. */
  about?: Maybe<Scalars['String']['output']>;
  /** Team background picture. */
  background?: Maybe<Scalars['String']['output']>;
  contacts: Array<ContactMethod>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Short Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Team ID */
  id: Scalars['Float']['output'];
  /** Brand or Agency */
  kind: TeamKind;
  /** Team Name */
  name: Scalars['String']['output'];
  /** Team picture. */
  picture?: Maybe<Scalars['String']['output']>;
  /** Unique Team Slug */
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
  /** Team Users */
  userEdges: Array<UserEdge>;
  verified: Scalars['Boolean']['output'];
};

export type TeamEdge = {
  __typename?: 'TeamEdge';
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Team User Role. */
  roles: Array<TeamRole>;
  /** Team */
  team: Team;
  updatedAt: Scalars['DateTimeISO']['output'];
  updatedBy: Scalars['Float']['output'];
};

export type TeamJoinRequest = {
  __typename?: 'TeamJoinRequest';
  createdAt: Scalars['DateTimeISO']['output'];
  createdBy: Scalars['Float']['output'];
  /** Team Join Request ID */
  id: Scalars['Float']['output'];
  /** Optional Note */
  note?: Maybe<Scalars['String']['output']>;
  /** Status */
  status: JoinStatus;
  /** Team */
  team: Team;
  /** Team ID */
  teamId: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
  /** User */
  user: User;
  /** User ID */
  userId: Scalars['Float']['output'];
};

export type TeamJoinRequestList = {
  __typename?: 'TeamJoinRequestList';
  items: Array<TeamJoinRequest>;
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
};

/** Brand or Agency */
export enum TeamKind {
  Agency = 'AGENCY',
  Brand = 'BRAND'
}

export type TeamList = {
  __typename?: 'TeamList';
  /** Next cursor */
  nextCursor?: Maybe<Scalars['Float']['output']>;
  teams: Array<Team>;
};

/** User Team Roles */
export enum TeamRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type UpdateInspectionInput = {
  /** Inspection Address */
  address?: InputMaybe<InspectionAddressInput>;
  /** Agency Id */
  agencyId?: InputMaybe<Scalars['Float']['input']>;
  /** Inspection Id */
  id: Scalars['Float']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  serviceDate?: InputMaybe<Scalars['DateTimeISO']['input']>;
  /** Service Type */
  serviceType?: InputMaybe<Scalars['String']['input']>;
  /** Status */
  status?: InputMaybe<InspectionStatus>;
  /** Supplier Id */
  supplierId?: InputMaybe<Scalars['Float']['input']>;
  /** Visibility */
  visibility?: InputMaybe<InspectionVisibility>;
};

export type UpdateTeamInput = {
  /** Tell more about your company. */
  about?: InputMaybe<Scalars['String']['input']>;
  /** Team background picture. */
  background?: InputMaybe<Scalars['String']['input']>;
  contacts: Array<ContactMethodInput>;
  /** Team Description. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Team ID */
  id: Scalars['Float']['input'];
  /** Team Name. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Avatar picture. */
  picture?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertSupplierContactInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactMethods?: InputMaybe<Array<ContactMethodInput>>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** SupplierContact ID */
  id?: InputMaybe<Scalars['Float']['input']>;
  /** Fulle name */
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  /** Team ID */
  teamId: Scalars['Float']['input'];
  /** SupplierContact Unique ID */
  uniqueId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertSupplierInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Factory capabilities */
  capabilities?: InputMaybe<Array<Scalars['String']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** Supplier ID */
  id?: InputMaybe<Scalars['Float']['input']>;
  /** Name of the factory */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Parent Supplier ID */
  parentId?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  /** Team ID */
  teamId: Scalars['Float']['input'];
  /** Supplier Unique ID */
  uniqueId?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  /** Profile background */
  background?: Maybe<Scalars['String']['output']>;
  /** Free form text user profile bio */
  bio?: Maybe<Scalars['String']['output']>;
  contacts: Array<ContactMethod>;
  country: Country;
  countryCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTimeISO']['output']>;
  createdBy?: Maybe<Scalars['Float']['output']>;
  /** First Name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** User ID */
  id: Scalars['Float']['output'];
  /** Last Name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Avatar */
  picture?: Maybe<Scalars['String']['output']>;
  /** Unique User Slug used for the user profile page, e.g. https://qcsku.com/profile/doronin.dmitry */
  slug: Scalars['String']['output'];
  /** User Team Edges */
  teamEdges: Array<TeamEdge>;
  /** Team Join Requests */
  teamJoinRequests: Array<TeamJoinRequest>;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedBy?: Maybe<Scalars['Float']['output']>;
  verifiedEmail: Scalars['Boolean']['output'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  createdAt: Scalars['DateTimeISO']['output'];
  /** Team User Role. */
  roles: Array<TeamRole>;
  /** Team User. */
  user: User;
};

export type AgencySearchFragment = { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, picture?: string | null };

export type AgencyListQueryVariables = Exact<{ [key: string]: never; }>;


export type AgencyListQuery = { __typename?: 'Query', team_list?: { __typename: 'TeamList', nextCursor?: number | null, teams: Array<{ __typename: 'Team', id: number, verified: boolean, slug: string, name: string, picture?: string | null }> } | null };

export type ChecklistFragment = { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename?: 'ChecklistItem', name: string, description: string, passCriteria: string, aqlCode: string }> };

export type ChecklistListQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type ChecklistListQuery = { __typename?: 'Query', checklist_list?: { __typename: 'ChecklistList', checklists: Array<{ __typename: 'Checklist', id: number, name: string, items: Array<{ __typename?: 'ChecklistItem', name: string, description: string, passCriteria: string, aqlCode: string }> }> } | null };

export type CountryListQueryVariables = Exact<{ [key: string]: never; }>;


export type CountryListQuery = { __typename?: 'Query', country_list?: Array<{ __typename: 'Country', label: string, code: string, phone: string }> | null };

export type InspectionAddressFragment = { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null };

export type SampleFragment = { __typename: 'Sample', sku: string, quantity: number, inspectinLevel: string, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } };

export type InspectionChecklistFragment = { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> };

export type InspectionTableItemFragment = { __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', totalCosts?: number | null }>, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samples: Array<{ __typename: 'Sample', sku: string, quantity: number, inspectinLevel: string, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null };

export type InspectionFragment = { __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', totalCosts?: number | null, currency: string, agency: { __typename: 'Team', id: number, name: string, slug: string, picture?: string | null, verified: boolean } }>, agency?: { __typename: 'Team', id: number, name: string, slug: string, picture?: string | null, verified: boolean } | null, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samples: Array<{ __typename: 'Sample', sku: string, quantity: number, inspectinLevel: string, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null };

export type InspectionListQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
  teamKind: Scalars['String']['input'];
}>;


export type InspectionListQuery = { __typename?: 'Query', inspection_list?: { __typename: 'InspectionList', inspections: Array<{ __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', totalCosts?: number | null }>, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samples: Array<{ __typename: 'Sample', sku: string, quantity: number, inspectinLevel: string, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null }> } | null };

export type InspectionByRefQueryVariables = Exact<{
  ref: Scalars['String']['input'];
}>;


export type InspectionByRefQuery = { __typename?: 'Query', inspection_by_ref?: { __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', manDays: number, serviceDateStart: any, serviceDateEnd: any, notes?: string | null, createdAt: any, createdBy: number, totalCosts?: number | null, currency: string, agency: { __typename: 'Team', id: number, name: string, slug: string, picture?: string | null, verified: boolean } }>, agency?: { __typename: 'Team', id: number, name: string, slug: string, picture?: string | null, verified: boolean } | null, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samples: Array<{ __typename: 'Sample', sku: string, quantity: number, inspectinLevel: string, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null } | null };

export type InspectionCreateDraftMutationVariables = Exact<{
  draft: CreateInspectionDraftInput;
}>;


export type InspectionCreateDraftMutation = { __typename?: 'Mutation', inspection_create_draft: { __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', totalCosts?: number | null }>, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samples: Array<{ __typename: 'Sample', sku: string, quantity: number, inspectinLevel: string, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null } };

export type InspectionUpdateMutationVariables = Exact<{
  updates: UpdateInspectionInput;
}>;


export type InspectionUpdateMutation = { __typename?: 'Mutation', inspection_update: { __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', totalCosts?: number | null }>, address?: { __typename: 'InspectionAddress', addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, country?: string | null, postalCode?: string | null } | null, samples: Array<{ __typename: 'Sample', sku: string, quantity: number, inspectinLevel: string, criticalDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, majorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number }, minorDefects: { __typename?: 'AQL', aql: number, sampleSize: number, acceptPoint: number, rejectPoint: number } }>, checklist?: { __typename: 'Checklist', id: number, name: string, items: Array<{ __typename: 'ChecklistItem', aqlCode: string, name: string, description: string, passCriteria: string }> } | null } };

export type AnvylConfigFragment = { __typename: 'Anvyl', id: number, teamId: number, anvylTeamId: number, anvylApiKey: string, enabled: boolean, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number };

export type AnvylConfigQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type AnvylConfigQuery = { __typename?: 'Query', anvyl_config?: { __typename: 'Anvyl', id: number, teamId: number, anvylTeamId: number, anvylApiKey: string, enabled: boolean, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number } | null };

export type AnvylConfigUpdateMutationVariables = Exact<{
  teamId: Scalars['Float']['input'];
  anvylTeamId: Scalars['Float']['input'];
  anvylApiKey: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
}>;


export type AnvylConfigUpdateMutation = { __typename?: 'Mutation', anvyl_config_update: { __typename: 'Anvyl', id: number, teamId: number, anvylTeamId: number, anvylApiKey: string, enabled: boolean, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number } };

export type InspectionOpeningFragment = { __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', totalCosts?: number | null, currency: string, serviceDateStart: any, serviceDateEnd: any, manDays: number, notes?: string | null }>, supplier?: { __typename: 'Supplier', id: number, name: string, countryCode?: string | null } | null, brand: { __typename: 'Team', id: number, name: string, slug: string, picture?: string | null, verified: boolean } };

export type TopOpeningsQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type TopOpeningsQuery = { __typename?: 'Query', inspection_list?: { __typename: 'InspectionList', inspections: Array<{ __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', totalCosts?: number | null, currency: string, serviceDateStart: any, serviceDateEnd: any, manDays: number, notes?: string | null }>, supplier?: { __typename: 'Supplier', id: number, name: string, countryCode?: string | null } | null, brand: { __typename: 'Team', id: number, name: string, slug: string, picture?: string | null, verified: boolean } }> } | null };

export type InspectionQuoteCreateMutationVariables = Exact<{
  quote: CreateInspectionQuoteInput;
}>;


export type InspectionQuoteCreateMutation = { __typename?: 'Mutation', inspection_quote_create: { __typename: 'Inspection', id: number, ref: string, status: InspectionStatus, visibility: InspectionVisibility, serviceType?: string | null, serviceDate?: any | null, manDays?: number | null, rate?: number | null, currency?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, quotes: Array<{ __typename: 'InspectionQuote', totalCosts?: number | null, currency: string, serviceDateStart: any, serviceDateEnd: any, manDays: number, notes?: string | null }>, supplier?: { __typename: 'Supplier', id: number, name: string, countryCode?: string | null } | null, brand: { __typename: 'Team', id: number, name: string, slug: string, picture?: string | null, verified: boolean } } };

export type SkuFragment = { __typename: 'Sku', id: number, teamId: number, name: string, description?: string | null, category?: string | null, material?: string | null, sku: string, upc?: string | null, asin?: string | null, hs?: string | null, hts?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null };

export type SkuListQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type SkuListQuery = { __typename?: 'Query', sku_list?: { __typename: 'SkuList', skus: Array<{ __typename: 'Sku', id: number, teamId: number, name: string, description?: string | null, category?: string | null, material?: string | null, sku: string, upc?: string | null, asin?: string | null, hs?: string | null, hts?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null }> } | null };

export type TeamCreateMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  name: Scalars['String']['input'];
  kind: TeamKind;
  contacts?: InputMaybe<Array<ContactMethodInput> | ContactMethodInput>;
}>;


export type TeamCreateMutation = { __typename?: 'Mutation', team_create: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null, verified: boolean, createdAt: any, createdBy: number, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }> } };

export type TeamUpdateMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  about?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  contacts: Array<ContactMethodInput> | ContactMethodInput;
}>;


export type TeamUpdateMutation = { __typename?: 'Mutation', team_update: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, about?: string | null, kind: TeamKind, picture?: string | null, verified: boolean, createdAt: any, createdBy: number, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }> } };

export type TeamInfoFragment = { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null, background?: string | null, createdAt: any, updatedAt: any, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }>, userEdges: Array<{ __typename: 'UserEdge', createdAt: any, roles: Array<TeamRole>, user: { __typename: 'User', id: number, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, bio?: string | null, picture?: string | null, timezone?: string | null, countryCode: string, contacts: Array<{ __typename?: 'ContactMethod', key: string, value: string }> } }> };

export type TeamSearchFragment = { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null, createdAt: any, updatedAt: any };

export type GetTeamByIdQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type GetTeamByIdQuery = { __typename?: 'Query', team_by_id?: { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null, background?: string | null, createdAt: any, updatedAt: any, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }>, userEdges: Array<{ __typename: 'UserEdge', createdAt: any, roles: Array<TeamRole>, user: { __typename: 'User', id: number, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, bio?: string | null, picture?: string | null, timezone?: string | null, countryCode: string, contacts: Array<{ __typename?: 'ContactMethod', key: string, value: string }> } }> } | null };

export type TeamListQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamListQuery = { __typename?: 'Query', team_list?: { __typename: 'TeamList', nextCursor?: number | null, teams: Array<{ __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null, createdAt: any, updatedAt: any }> } | null };

export type TeamInfoQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TeamInfoQuery = { __typename?: 'Query', team_by_slug?: { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null, createdAt: any, updatedAt: any, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }>, userEdges: Array<{ __typename: 'UserEdge', createdAt: any, roles: Array<TeamRole>, user: { __typename: 'User', id: number, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, bio?: string | null, picture?: string | null, timezone?: string | null, countryCode: string, contacts: Array<{ __typename?: 'ContactMethod', key: string, value: string }> } }> } | null };

export type TeamAssignUserMutationVariables = Exact<{
  roles: Array<TeamRole> | TeamRole;
  userId: Scalars['Float']['input'];
  teamId: Scalars['Float']['input'];
}>;


export type TeamAssignUserMutation = { __typename?: 'Mutation', team_add_user?: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, picture?: string | null, verified: boolean, createdAt: any, createdBy: number, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }> } | null };

export type TeamJoinMutationVariables = Exact<{
  teamId: Scalars['Float']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
}>;


export type TeamJoinMutation = { __typename?: 'Mutation', team_request_to_join?: { __typename?: 'TeamJoinRequest', status: JoinStatus } | null };

export type CountryFragment = { __typename: 'Country', code: string, label: string, phone: string };

export type ContactMethodFragment = { __typename: 'ContactMethod', key: string, value: string };

export type MyTeamJoinRequestFragment = { __typename: 'TeamJoinRequest', id: number, status: JoinStatus, note?: string | null, createdAt: any, createdBy: number, updatedAt?: any | null, updatedBy?: number | null, team: { __typename: 'Team', id: number, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null } };

export type MyTeamFragment = { __typename: 'Team', id: number, slug: string, name: string, picture?: string | null };

export type MyTeamEdgeFragment = { __typename: 'TeamEdge', roles: Array<TeamRole>, team: { __typename: 'Team', id: number, slug: string, name: string, picture?: string | null } };

export type MyProfileFragment = { __typename: 'User', id: number, slug: string, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, bio?: string | null, picture?: string | null, background?: string | null, timezone?: string | null, countryCode: string, teamEdges: Array<{ __typename: 'TeamEdge', roles: Array<TeamRole>, team: { __typename: 'Team', id: number, slug: string, name: string, picture?: string | null } }> };

export type UserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type UserMeQuery = { __typename?: 'Query', user_me: { __typename: 'User', id: number, slug: string, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, bio?: string | null, picture?: string | null, background?: string | null, timezone?: string | null, countryCode: string, teamEdges: Array<{ __typename: 'TeamEdge', roles: Array<TeamRole>, team: { __typename: 'Team', id: number, slug: string, name: string, picture?: string | null } }> } };

export type TeamUsersQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type TeamUsersQuery = { __typename?: 'Query', team_by_id?: { __typename: 'Team', id: number, slug: string, name: string, verified: boolean, description?: string | null, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number, userEdges: Array<{ __typename: 'UserEdge', roles: Array<TeamRole>, user: { __typename: 'User', id: number, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, bio?: string | null, picture?: string | null, countryCode: string } }> } | null };

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  picture?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', user_update: { __typename: 'User', id: number } };

export type UserByIdQueryVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type UserByIdQuery = { __typename?: 'Query', user_by_id?: { __typename: 'User', firstName?: string | null, lastName?: string | null, slug: string, picture?: string | null } | null };

export type AutocompleteSuppliersQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type AutocompleteSuppliersQuery = { __typename?: 'Query', supplier_list?: { __typename: 'SupplierList', suppliers: Array<{ __typename: 'Supplier', id: number, name: string, capabilities: Array<string>, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, postalCode?: string | null, countryCode?: string | null }> } | null };

export type UserTooltipBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type UserTooltipBySlugQuery = { __typename?: 'Query', user_by_slug?: { __typename: 'User', slug: string, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, picture?: string | null, background?: string | null, timezone?: string | null, countryCode: string } | null };

export type UserSendVerificationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type UserSendVerificationEmailMutation = { __typename?: 'Mutation', user_send_verification_email: { __typename?: 'User', id: number, verifiedEmail: boolean } };

export type MyTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTeamsQuery = { __typename?: 'Query', user_me: { __typename: 'User', id: number, teamEdges: Array<{ __typename: 'TeamEdge', roles: Array<TeamRole>, team: { __typename?: 'Team', name: string, picture?: string | null, slug: string } }> } };

export type AuthorizationKeyFragment = { __typename: 'AuthorizationKey', id: number, name?: string | null, key: string, exp: any };

export type ApiKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiKeysQuery = { __typename?: 'Query', authorization_key_list: Array<{ __typename: 'AuthorizationKey', id: number, name?: string | null, key: string, exp: any }> };

export type CreateApiKeyMutationVariables = Exact<{
  authorizationKey: AuthorizationKeyInput;
}>;


export type CreateApiKeyMutation = { __typename?: 'Mutation', authorization_key_create: { __typename: 'AuthorizationKey', id: number, name?: string | null, key: string, exp: any } };

export type RevokeApiKeyMutationVariables = Exact<{
  authorizationKeyId: Scalars['Float']['input'];
}>;


export type RevokeApiKeyMutation = { __typename?: 'Mutation', authorization_key_revoke: { __typename: 'AuthorizationKey', id: number, name?: string | null, key: string, exp: any } };

export type AllBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllBrandsQuery = { __typename?: 'Query', team_list?: { __typename: 'TeamList', nextCursor?: number | null, teams: Array<{ __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null, createdAt: any }> } | null };

export type UserProfileFragment = { __typename: 'User', id: number, slug: string, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, bio?: string | null, picture?: string | null, background?: string | null, timezone?: string | null, countryCode: string, createdAt?: any | null, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }>, country: { __typename: 'Country', code: string, label: string, phone: string }, teamEdges: Array<{ __typename: 'TeamEdge', roles: Array<TeamRole>, team: { __typename: 'Team', id: number, slug: string, name: string, kind: TeamKind, picture?: string | null } }> };

export type UserBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type UserBySlugQuery = { __typename?: 'Query', user_by_slug?: { __typename: 'User', id: number, slug: string, firstName?: string | null, lastName?: string | null, verifiedEmail: boolean, bio?: string | null, picture?: string | null, background?: string | null, timezone?: string | null, countryCode: string, createdAt?: any | null, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }>, country: { __typename: 'Country', code: string, label: string, phone: string }, teamEdges: Array<{ __typename: 'TeamEdge', roles: Array<TeamRole>, team: { __typename: 'Team', id: number, slug: string, name: string, kind: TeamKind, picture?: string | null } }> } | null };

export type TeamProfileQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TeamProfileQuery = { __typename?: 'Query', team_by_slug?: { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, about?: string | null, kind: TeamKind, picture?: string | null, background?: string | null, createdAt: any, updatedAt: any, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }>, userEdges: Array<{ __typename?: 'UserEdge', user: { __typename?: 'User', id: number, slug: string, picture?: string | null } }> } | null };

export type ContactTableItemFragment = { __typename: 'SupplierContact', id: number, uniqueId: string, name: string, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, postalCode?: string | null, countryCode?: string | null, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number, contactMethods: Array<{ __typename: 'ContactMethod', key: string, value: string }> };

export type TableViewContactsQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type TableViewContactsQuery = { __typename?: 'Query', contact_list?: { __typename: 'ContactList', contacts: Array<{ __typename: 'SupplierContact', id: number, uniqueId: string, name: string, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, postalCode?: string | null, countryCode?: string | null, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number, contactMethods: Array<{ __typename: 'ContactMethod', key: string, value: string }> }> } | null };

export type ContactUpsertMutationVariables = Exact<{
  contact: UpsertSupplierContactInput;
}>;


export type ContactUpsertMutation = { __typename?: 'Mutation', contact_upsert: { __typename: 'SupplierContact', id: number, uniqueId: string, name: string, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, postalCode?: string | null, countryCode?: string | null, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number, contactMethods: Array<{ __typename: 'ContactMethod', key: string, value: string }> } };

export type SupplierTooltipQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SupplierTooltipQuery = { __typename?: 'Query', supplier_by_id?: { __typename: 'Supplier', id: number, name: string, capabilities: Array<string>, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, postalCode?: string | null, countryCode?: string | null, createdAt: any } | null };

export type GenralTeamSettingsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type GenralTeamSettingsQuery = { __typename?: 'Query', team_by_slug?: { __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, about?: string | null, kind: TeamKind, picture?: string | null, background?: string | null, createdAt: any, updatedAt: any, contacts: Array<{ __typename: 'ContactMethod', key: string, value: string }> } | null };

export type TeamJoinRequestsQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type TeamJoinRequestsQuery = { __typename?: 'Query', team_request_list: { __typename: 'TeamJoinRequestList', items: Array<{ __typename?: 'TeamJoinRequest', id: number, status: JoinStatus, note?: string | null, createdAt: any, user: { __typename: 'User', id: number, firstName?: string | null, lastName?: string | null, slug: string, picture?: string | null } }> } };

export type ApproveTeamJoinRequestMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type ApproveTeamJoinRequestMutation = { __typename?: 'Mutation', team_request_approve?: { __typename: 'TeamJoinRequest', id: number } | null };

export type RejectTeamJoinRequestMutationVariables = Exact<{
  id: Scalars['Float']['input'];
}>;


export type RejectTeamJoinRequestMutation = { __typename?: 'Mutation', team_request_reject?: { __typename: 'TeamJoinRequest', id: number } | null };

export type TeamMembersQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TeamMembersQuery = { __typename?: 'Query', team_by_slug?: { __typename: 'Team', userEdges: Array<{ __typename?: 'UserEdge', roles: Array<TeamRole>, createdAt: any, user: { __typename: 'User', id: number, slug: string, firstName?: string | null, lastName?: string | null, countryCode: string } }> } | null };

export type TeamAddUserMutationVariables = Exact<{
  teamId: Scalars['Float']['input'];
  userId: Scalars['Float']['input'];
  roles: Array<TeamRole> | TeamRole;
}>;


export type TeamAddUserMutation = { __typename?: 'Mutation', team_add_user?: { __typename: 'Team', userEdges: Array<{ __typename?: 'UserEdge', roles: Array<TeamRole>, createdAt: any, user: { __typename: 'User', id: number, slug: string, firstName?: string | null, lastName?: string | null, countryCode: string } }> } | null };

export type SupplierTableItemFragment = { __typename: 'Supplier', id: number, parentId?: number | null, name: string, capabilities: Array<string>, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, postalCode?: string | null, countryCode?: string | null, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number };

export type TableViewSuppliersQueryVariables = Exact<{
  teamId: Scalars['Float']['input'];
}>;


export type TableViewSuppliersQuery = { __typename?: 'Query', supplier_list?: { __typename: 'SupplierList', suppliers: Array<{ __typename: 'Supplier', id: number, parentId?: number | null, name: string, capabilities: Array<string>, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, postalCode?: string | null, countryCode?: string | null, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number }> } | null };

export type SupplierUpsertMutationVariables = Exact<{
  supplier: UpsertSupplierInput;
}>;


export type SupplierUpsertMutation = { __typename?: 'Mutation', supplier_upsert: { __typename: 'Supplier', id: number, parentId?: number | null, name: string, capabilities: Array<string>, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: string | null, postalCode?: string | null, countryCode?: string | null, createdAt: any, createdBy: number, updatedAt: any, updatedBy: number } };

export type XTeamsAutocompleteQueryVariables = Exact<{ [key: string]: never; }>;


export type XTeamsAutocompleteQuery = { __typename?: 'Query', team_list?: { __typename: 'TeamList', nextCursor?: number | null, teams: Array<{ __typename: 'Team', id: number, verified: boolean, slug: string, name: string, description?: string | null, kind: TeamKind, picture?: string | null, createdAt: any }> } | null };

export const AgencySearchFragmentDoc = gql`
    fragment AgencySearch on Team {
  __typename
  id
  verified
  slug
  name
  picture
}
    `;
export const ChecklistFragmentDoc = gql`
    fragment Checklist on Checklist {
  __typename
  id
  name
  items {
    name
    description
    passCriteria
    aqlCode
  }
}
    `;
export const InspectionAddressFragmentDoc = gql`
    fragment InspectionAddress on InspectionAddress {
  __typename
  addressLine1
  addressLine2
  city
  state
  country
  postalCode
}
    `;
export const SampleFragmentDoc = gql`
    fragment Sample on Sample {
  __typename
  sku
  quantity
  inspectinLevel
  criticalDefects {
    aql
    sampleSize
    acceptPoint
    rejectPoint
  }
  majorDefects {
    aql
    sampleSize
    acceptPoint
    rejectPoint
  }
  minorDefects {
    aql
    sampleSize
    acceptPoint
    rejectPoint
  }
}
    `;
export const InspectionChecklistFragmentDoc = gql`
    fragment InspectionChecklist on Checklist {
  __typename
  id
  name
  items {
    __typename
    aqlCode
    name
    description
    passCriteria
  }
}
    `;
export const InspectionTableItemFragmentDoc = gql`
    fragment InspectionTableItem on Inspection {
  __typename
  id
  ref
  status
  visibility
  serviceType
  quotes {
    __typename
    totalCosts
  }
  serviceDate
  manDays
  rate
  currency
  address {
    ...InspectionAddress
  }
  samples {
    ...Sample
  }
  checklist {
    ...InspectionChecklist
  }
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    ${InspectionAddressFragmentDoc}
${SampleFragmentDoc}
${InspectionChecklistFragmentDoc}`;
export const InspectionFragmentDoc = gql`
    fragment Inspection on Inspection {
  __typename
  id
  ref
  status
  visibility
  serviceType
  quotes {
    __typename
    totalCosts
    currency
    agency {
      __typename
      id
      name
      slug
      picture
      verified
    }
  }
  agency {
    __typename
    id
    name
    slug
    picture
    verified
  }
  serviceDate
  manDays
  rate
  currency
  address {
    ...InspectionAddress
  }
  samples {
    ...Sample
  }
  checklist {
    ...InspectionChecklist
  }
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    ${InspectionAddressFragmentDoc}
${SampleFragmentDoc}
${InspectionChecklistFragmentDoc}`;
export const AnvylConfigFragmentDoc = gql`
    fragment AnvylConfig on Anvyl {
  __typename
  id
  teamId
  anvylTeamId
  anvylApiKey
  enabled
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const InspectionOpeningFragmentDoc = gql`
    fragment InspectionOpening on Inspection {
  __typename
  id
  ref
  status
  visibility
  serviceType
  quotes {
    __typename
    totalCosts
    currency
    serviceDateStart
    serviceDateEnd
    manDays
    totalCosts
    notes
  }
  supplier {
    __typename
    id
    name
    countryCode
  }
  brand {
    __typename
    id
    name
    slug
    picture
    verified
  }
  serviceDate
  manDays
  rate
  currency
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const SkuFragmentDoc = gql`
    fragment Sku on Sku {
  __typename
  id
  teamId
  name
  description
  category
  material
  sku
  upc
  asin
  hs
  hts
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const TeamInfoFragmentDoc = gql`
    fragment TeamInfo on Team {
  __typename
  id
  verified
  slug
  name
  description
  kind
  picture
  background
  contacts {
    __typename
    key
    value
  }
  createdAt
  updatedAt
  userEdges {
    __typename
    createdAt
    roles
    user {
      __typename
      id
      firstName
      lastName
      verifiedEmail
      bio
      picture
      timezone
      contacts {
        key
        value
      }
      countryCode
    }
  }
}
    `;
export const TeamSearchFragmentDoc = gql`
    fragment TeamSearch on Team {
  __typename
  id
  verified
  slug
  name
  description
  kind
  picture
  createdAt
  updatedAt
}
    `;
export const MyTeamJoinRequestFragmentDoc = gql`
    fragment MyTeamJoinRequest on TeamJoinRequest {
  __typename
  id
  team {
    __typename
    id
    slug
    name
    description
    kind
    picture
  }
  status
  note
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const MyTeamFragmentDoc = gql`
    fragment MyTeam on Team {
  __typename
  id
  slug
  name
  picture
}
    `;
export const MyTeamEdgeFragmentDoc = gql`
    fragment MyTeamEdge on TeamEdge {
  __typename
  roles
  team {
    ...MyTeam
  }
}
    ${MyTeamFragmentDoc}`;
export const MyProfileFragmentDoc = gql`
    fragment MyProfile on User {
  __typename
  id
  slug
  firstName
  lastName
  verifiedEmail
  bio
  picture
  background
  timezone
  countryCode
  teamEdges {
    ...MyTeamEdge
  }
}
    ${MyTeamEdgeFragmentDoc}`;
export const AuthorizationKeyFragmentDoc = gql`
    fragment AuthorizationKey on AuthorizationKey {
  __typename
  id
  name
  key
  exp
}
    `;
export const ContactMethodFragmentDoc = gql`
    fragment ContactMethod on ContactMethod {
  __typename
  key
  value
}
    `;
export const CountryFragmentDoc = gql`
    fragment Country on Country {
  __typename
  code
  label
  phone
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on User {
  __typename
  id
  slug
  firstName
  lastName
  verifiedEmail
  bio
  picture
  background
  timezone
  countryCode
  contacts {
    ...ContactMethod
  }
  country {
    ...Country
  }
  teamEdges {
    __typename
    roles
    team {
      __typename
      id
      slug
      name
      kind
      picture
    }
  }
  createdAt
}
    ${ContactMethodFragmentDoc}
${CountryFragmentDoc}`;
export const ContactTableItemFragmentDoc = gql`
    fragment ContactTableItem on SupplierContact {
  __typename
  id
  uniqueId
  name
  contactMethods {
    __typename
    key
    value
  }
  addressLine1
  addressLine2
  city
  state
  postalCode
  countryCode
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const SupplierTableItemFragmentDoc = gql`
    fragment SupplierTableItem on Supplier {
  __typename
  id
  parentId
  name
  capabilities
  addressLine1
  addressLine2
  city
  state
  postalCode
  countryCode
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const AgencyListDocument = gql`
    query agencyList {
  team_list {
    __typename
    nextCursor
    teams {
      ...AgencySearch
    }
  }
}
    ${AgencySearchFragmentDoc}`;

/**
 * __useAgencyListQuery__
 *
 * To run a query within a React component, call `useAgencyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgencyListQuery(baseOptions?: Apollo.QueryHookOptions<AgencyListQuery, AgencyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgencyListQuery, AgencyListQueryVariables>(AgencyListDocument, options);
      }
export function useAgencyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyListQuery, AgencyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgencyListQuery, AgencyListQueryVariables>(AgencyListDocument, options);
        }
export function useAgencyListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AgencyListQuery, AgencyListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AgencyListQuery, AgencyListQueryVariables>(AgencyListDocument, options);
        }
export type AgencyListQueryHookResult = ReturnType<typeof useAgencyListQuery>;
export type AgencyListLazyQueryHookResult = ReturnType<typeof useAgencyListLazyQuery>;
export type AgencyListSuspenseQueryHookResult = ReturnType<typeof useAgencyListSuspenseQuery>;
export type AgencyListQueryResult = Apollo.QueryResult<AgencyListQuery, AgencyListQueryVariables>;
export const ChecklistListDocument = gql`
    query checklistList($teamId: Float!) {
  checklist_list(teamId: $teamId) {
    __typename
    checklists {
      ...Checklist
    }
  }
}
    ${ChecklistFragmentDoc}`;

/**
 * __useChecklistListQuery__
 *
 * To run a query within a React component, call `useChecklistListQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useChecklistListQuery(baseOptions: Apollo.QueryHookOptions<ChecklistListQuery, ChecklistListQueryVariables> & ({ variables: ChecklistListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChecklistListQuery, ChecklistListQueryVariables>(ChecklistListDocument, options);
      }
export function useChecklistListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChecklistListQuery, ChecklistListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChecklistListQuery, ChecklistListQueryVariables>(ChecklistListDocument, options);
        }
export function useChecklistListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChecklistListQuery, ChecklistListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChecklistListQuery, ChecklistListQueryVariables>(ChecklistListDocument, options);
        }
export type ChecklistListQueryHookResult = ReturnType<typeof useChecklistListQuery>;
export type ChecklistListLazyQueryHookResult = ReturnType<typeof useChecklistListLazyQuery>;
export type ChecklistListSuspenseQueryHookResult = ReturnType<typeof useChecklistListSuspenseQuery>;
export type ChecklistListQueryResult = Apollo.QueryResult<ChecklistListQuery, ChecklistListQueryVariables>;
export const CountryListDocument = gql`
    query countryList {
  country_list {
    __typename
    label
    code
    phone
  }
}
    `;

/**
 * __useCountryListQuery__
 *
 * To run a query within a React component, call `useCountryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryListQuery(baseOptions?: Apollo.QueryHookOptions<CountryListQuery, CountryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountryListQuery, CountryListQueryVariables>(CountryListDocument, options);
      }
export function useCountryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryListQuery, CountryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountryListQuery, CountryListQueryVariables>(CountryListDocument, options);
        }
export function useCountryListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CountryListQuery, CountryListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountryListQuery, CountryListQueryVariables>(CountryListDocument, options);
        }
export type CountryListQueryHookResult = ReturnType<typeof useCountryListQuery>;
export type CountryListLazyQueryHookResult = ReturnType<typeof useCountryListLazyQuery>;
export type CountryListSuspenseQueryHookResult = ReturnType<typeof useCountryListSuspenseQuery>;
export type CountryListQueryResult = Apollo.QueryResult<CountryListQuery, CountryListQueryVariables>;
export const InspectionListDocument = gql`
    query inspectionList($teamId: Float!, $teamKind: String!) {
  inspection_list(teamId: $teamId, teamKind: $teamKind) {
    __typename
    inspections {
      ...InspectionTableItem
    }
  }
}
    ${InspectionTableItemFragmentDoc}`;

/**
 * __useInspectionListQuery__
 *
 * To run a query within a React component, call `useInspectionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      teamKind: // value for 'teamKind'
 *   },
 * });
 */
export function useInspectionListQuery(baseOptions: Apollo.QueryHookOptions<InspectionListQuery, InspectionListQueryVariables> & ({ variables: InspectionListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InspectionListQuery, InspectionListQueryVariables>(InspectionListDocument, options);
      }
export function useInspectionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InspectionListQuery, InspectionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InspectionListQuery, InspectionListQueryVariables>(InspectionListDocument, options);
        }
export function useInspectionListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InspectionListQuery, InspectionListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InspectionListQuery, InspectionListQueryVariables>(InspectionListDocument, options);
        }
export type InspectionListQueryHookResult = ReturnType<typeof useInspectionListQuery>;
export type InspectionListLazyQueryHookResult = ReturnType<typeof useInspectionListLazyQuery>;
export type InspectionListSuspenseQueryHookResult = ReturnType<typeof useInspectionListSuspenseQuery>;
export type InspectionListQueryResult = Apollo.QueryResult<InspectionListQuery, InspectionListQueryVariables>;
export const InspectionByRefDocument = gql`
    query inspectionByRef($ref: String!) {
  inspection_by_ref(ref: $ref) {
    ...Inspection
    quotes {
      __typename
      manDays
      serviceDateStart
      serviceDateEnd
      notes
      createdAt
      createdBy
      totalCosts
      currency
      agency {
        __typename
        id
        name
        slug
        picture
        verified
      }
    }
  }
}
    ${InspectionFragmentDoc}`;

/**
 * __useInspectionByRefQuery__
 *
 * To run a query within a React component, call `useInspectionByRefQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectionByRefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectionByRefQuery({
 *   variables: {
 *      ref: // value for 'ref'
 *   },
 * });
 */
export function useInspectionByRefQuery(baseOptions: Apollo.QueryHookOptions<InspectionByRefQuery, InspectionByRefQueryVariables> & ({ variables: InspectionByRefQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InspectionByRefQuery, InspectionByRefQueryVariables>(InspectionByRefDocument, options);
      }
export function useInspectionByRefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InspectionByRefQuery, InspectionByRefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InspectionByRefQuery, InspectionByRefQueryVariables>(InspectionByRefDocument, options);
        }
export function useInspectionByRefSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InspectionByRefQuery, InspectionByRefQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InspectionByRefQuery, InspectionByRefQueryVariables>(InspectionByRefDocument, options);
        }
export type InspectionByRefQueryHookResult = ReturnType<typeof useInspectionByRefQuery>;
export type InspectionByRefLazyQueryHookResult = ReturnType<typeof useInspectionByRefLazyQuery>;
export type InspectionByRefSuspenseQueryHookResult = ReturnType<typeof useInspectionByRefSuspenseQuery>;
export type InspectionByRefQueryResult = Apollo.QueryResult<InspectionByRefQuery, InspectionByRefQueryVariables>;
export const InspectionCreateDraftDocument = gql`
    mutation inspectionCreateDraft($draft: CreateInspectionDraftInput!) {
  inspection_create_draft(draft: $draft) {
    ...InspectionTableItem
  }
}
    ${InspectionTableItemFragmentDoc}`;
export type InspectionCreateDraftMutationFn = Apollo.MutationFunction<InspectionCreateDraftMutation, InspectionCreateDraftMutationVariables>;

/**
 * __useInspectionCreateDraftMutation__
 *
 * To run a mutation, you first call `useInspectionCreateDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInspectionCreateDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inspectionCreateDraftMutation, { data, loading, error }] = useInspectionCreateDraftMutation({
 *   variables: {
 *      draft: // value for 'draft'
 *   },
 * });
 */
export function useInspectionCreateDraftMutation(baseOptions?: Apollo.MutationHookOptions<InspectionCreateDraftMutation, InspectionCreateDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InspectionCreateDraftMutation, InspectionCreateDraftMutationVariables>(InspectionCreateDraftDocument, options);
      }
export type InspectionCreateDraftMutationHookResult = ReturnType<typeof useInspectionCreateDraftMutation>;
export type InspectionCreateDraftMutationResult = Apollo.MutationResult<InspectionCreateDraftMutation>;
export type InspectionCreateDraftMutationOptions = Apollo.BaseMutationOptions<InspectionCreateDraftMutation, InspectionCreateDraftMutationVariables>;
export const InspectionUpdateDocument = gql`
    mutation inspectionUpdate($updates: UpdateInspectionInput!) {
  inspection_update(updates: $updates) {
    ...InspectionTableItem
  }
}
    ${InspectionTableItemFragmentDoc}`;
export type InspectionUpdateMutationFn = Apollo.MutationFunction<InspectionUpdateMutation, InspectionUpdateMutationVariables>;

/**
 * __useInspectionUpdateMutation__
 *
 * To run a mutation, you first call `useInspectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInspectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inspectionUpdateMutation, { data, loading, error }] = useInspectionUpdateMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useInspectionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InspectionUpdateMutation, InspectionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InspectionUpdateMutation, InspectionUpdateMutationVariables>(InspectionUpdateDocument, options);
      }
export type InspectionUpdateMutationHookResult = ReturnType<typeof useInspectionUpdateMutation>;
export type InspectionUpdateMutationResult = Apollo.MutationResult<InspectionUpdateMutation>;
export type InspectionUpdateMutationOptions = Apollo.BaseMutationOptions<InspectionUpdateMutation, InspectionUpdateMutationVariables>;
export const AnvylConfigDocument = gql`
    query anvylConfig($teamId: Float!) {
  anvyl_config(teamId: $teamId) {
    ...AnvylConfig
  }
}
    ${AnvylConfigFragmentDoc}`;

/**
 * __useAnvylConfigQuery__
 *
 * To run a query within a React component, call `useAnvylConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnvylConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnvylConfigQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAnvylConfigQuery(baseOptions: Apollo.QueryHookOptions<AnvylConfigQuery, AnvylConfigQueryVariables> & ({ variables: AnvylConfigQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnvylConfigQuery, AnvylConfigQueryVariables>(AnvylConfigDocument, options);
      }
export function useAnvylConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnvylConfigQuery, AnvylConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnvylConfigQuery, AnvylConfigQueryVariables>(AnvylConfigDocument, options);
        }
export function useAnvylConfigSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnvylConfigQuery, AnvylConfigQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnvylConfigQuery, AnvylConfigQueryVariables>(AnvylConfigDocument, options);
        }
export type AnvylConfigQueryHookResult = ReturnType<typeof useAnvylConfigQuery>;
export type AnvylConfigLazyQueryHookResult = ReturnType<typeof useAnvylConfigLazyQuery>;
export type AnvylConfigSuspenseQueryHookResult = ReturnType<typeof useAnvylConfigSuspenseQuery>;
export type AnvylConfigQueryResult = Apollo.QueryResult<AnvylConfigQuery, AnvylConfigQueryVariables>;
export const AnvylConfigUpdateDocument = gql`
    mutation anvylConfigUpdate($teamId: Float!, $anvylTeamId: Float!, $anvylApiKey: String!, $enabled: Boolean!) {
  anvyl_config_update(
    anvyl: {teamId: $teamId, anvylTeamId: $anvylTeamId, anvylApiKey: $anvylApiKey, enabled: $enabled}
  ) {
    ...AnvylConfig
  }
}
    ${AnvylConfigFragmentDoc}`;
export type AnvylConfigUpdateMutationFn = Apollo.MutationFunction<AnvylConfigUpdateMutation, AnvylConfigUpdateMutationVariables>;

/**
 * __useAnvylConfigUpdateMutation__
 *
 * To run a mutation, you first call `useAnvylConfigUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnvylConfigUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anvylConfigUpdateMutation, { data, loading, error }] = useAnvylConfigUpdateMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      anvylTeamId: // value for 'anvylTeamId'
 *      anvylApiKey: // value for 'anvylApiKey'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useAnvylConfigUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AnvylConfigUpdateMutation, AnvylConfigUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnvylConfigUpdateMutation, AnvylConfigUpdateMutationVariables>(AnvylConfigUpdateDocument, options);
      }
export type AnvylConfigUpdateMutationHookResult = ReturnType<typeof useAnvylConfigUpdateMutation>;
export type AnvylConfigUpdateMutationResult = Apollo.MutationResult<AnvylConfigUpdateMutation>;
export type AnvylConfigUpdateMutationOptions = Apollo.BaseMutationOptions<AnvylConfigUpdateMutation, AnvylConfigUpdateMutationVariables>;
export const TopOpeningsDocument = gql`
    query topOpenings($teamId: Float!) {
  inspection_list(teamId: $teamId, teamKind: "AGENCY", visibility: PUBLIC) {
    __typename
    inspections {
      ...InspectionOpening
    }
  }
}
    ${InspectionOpeningFragmentDoc}`;

/**
 * __useTopOpeningsQuery__
 *
 * To run a query within a React component, call `useTopOpeningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopOpeningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopOpeningsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTopOpeningsQuery(baseOptions: Apollo.QueryHookOptions<TopOpeningsQuery, TopOpeningsQueryVariables> & ({ variables: TopOpeningsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopOpeningsQuery, TopOpeningsQueryVariables>(TopOpeningsDocument, options);
      }
export function useTopOpeningsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopOpeningsQuery, TopOpeningsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopOpeningsQuery, TopOpeningsQueryVariables>(TopOpeningsDocument, options);
        }
export function useTopOpeningsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TopOpeningsQuery, TopOpeningsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TopOpeningsQuery, TopOpeningsQueryVariables>(TopOpeningsDocument, options);
        }
export type TopOpeningsQueryHookResult = ReturnType<typeof useTopOpeningsQuery>;
export type TopOpeningsLazyQueryHookResult = ReturnType<typeof useTopOpeningsLazyQuery>;
export type TopOpeningsSuspenseQueryHookResult = ReturnType<typeof useTopOpeningsSuspenseQuery>;
export type TopOpeningsQueryResult = Apollo.QueryResult<TopOpeningsQuery, TopOpeningsQueryVariables>;
export const InspectionQuoteCreateDocument = gql`
    mutation inspectionQuoteCreate($quote: CreateInspectionQuoteInput!) {
  inspection_quote_create(quote: $quote) {
    ...InspectionOpening
  }
}
    ${InspectionOpeningFragmentDoc}`;
export type InspectionQuoteCreateMutationFn = Apollo.MutationFunction<InspectionQuoteCreateMutation, InspectionQuoteCreateMutationVariables>;

/**
 * __useInspectionQuoteCreateMutation__
 *
 * To run a mutation, you first call `useInspectionQuoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInspectionQuoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inspectionQuoteCreateMutation, { data, loading, error }] = useInspectionQuoteCreateMutation({
 *   variables: {
 *      quote: // value for 'quote'
 *   },
 * });
 */
export function useInspectionQuoteCreateMutation(baseOptions?: Apollo.MutationHookOptions<InspectionQuoteCreateMutation, InspectionQuoteCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InspectionQuoteCreateMutation, InspectionQuoteCreateMutationVariables>(InspectionQuoteCreateDocument, options);
      }
export type InspectionQuoteCreateMutationHookResult = ReturnType<typeof useInspectionQuoteCreateMutation>;
export type InspectionQuoteCreateMutationResult = Apollo.MutationResult<InspectionQuoteCreateMutation>;
export type InspectionQuoteCreateMutationOptions = Apollo.BaseMutationOptions<InspectionQuoteCreateMutation, InspectionQuoteCreateMutationVariables>;
export const SkuListDocument = gql`
    query skuList($teamId: Float!) {
  sku_list(teamId: $teamId) {
    __typename
    skus {
      ...Sku
    }
  }
}
    ${SkuFragmentDoc}`;

/**
 * __useSkuListQuery__
 *
 * To run a query within a React component, call `useSkuListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkuListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkuListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSkuListQuery(baseOptions: Apollo.QueryHookOptions<SkuListQuery, SkuListQueryVariables> & ({ variables: SkuListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkuListQuery, SkuListQueryVariables>(SkuListDocument, options);
      }
export function useSkuListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkuListQuery, SkuListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkuListQuery, SkuListQueryVariables>(SkuListDocument, options);
        }
export function useSkuListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SkuListQuery, SkuListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SkuListQuery, SkuListQueryVariables>(SkuListDocument, options);
        }
export type SkuListQueryHookResult = ReturnType<typeof useSkuListQuery>;
export type SkuListLazyQueryHookResult = ReturnType<typeof useSkuListLazyQuery>;
export type SkuListSuspenseQueryHookResult = ReturnType<typeof useSkuListSuspenseQuery>;
export type SkuListQueryResult = Apollo.QueryResult<SkuListQuery, SkuListQueryVariables>;
export const TeamCreateDocument = gql`
    mutation teamCreate($slug: String!, $name: String!, $kind: TeamKind!, $contacts: [ContactMethodInput!]) {
  team_create(team: {slug: $slug, name: $name, kind: $kind, contacts: $contacts}) {
    __typename
    id
    slug
    name
    description
    kind
    picture
    contacts {
      __typename
      key
      value
    }
    verified
    createdAt
    createdBy
  }
}
    `;
export type TeamCreateMutationFn = Apollo.MutationFunction<TeamCreateMutation, TeamCreateMutationVariables>;

/**
 * __useTeamCreateMutation__
 *
 * To run a mutation, you first call `useTeamCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamCreateMutation, { data, loading, error }] = useTeamCreateMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      name: // value for 'name'
 *      kind: // value for 'kind'
 *      contacts: // value for 'contacts'
 *   },
 * });
 */
export function useTeamCreateMutation(baseOptions?: Apollo.MutationHookOptions<TeamCreateMutation, TeamCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamCreateMutation, TeamCreateMutationVariables>(TeamCreateDocument, options);
      }
export type TeamCreateMutationHookResult = ReturnType<typeof useTeamCreateMutation>;
export type TeamCreateMutationResult = Apollo.MutationResult<TeamCreateMutation>;
export type TeamCreateMutationOptions = Apollo.BaseMutationOptions<TeamCreateMutation, TeamCreateMutationVariables>;
export const TeamUpdateDocument = gql`
    mutation teamUpdate($id: Float!, $name: String, $description: String, $about: String, $picture: String, $contacts: [ContactMethodInput!]!) {
  team_update(
    team: {id: $id, name: $name, description: $description, about: $about, picture: $picture, contacts: $contacts}
  ) {
    __typename
    id
    slug
    name
    description
    about
    kind
    picture
    contacts {
      __typename
      key
      value
    }
    verified
    createdAt
    createdBy
  }
}
    `;
export type TeamUpdateMutationFn = Apollo.MutationFunction<TeamUpdateMutation, TeamUpdateMutationVariables>;

/**
 * __useTeamUpdateMutation__
 *
 * To run a mutation, you first call `useTeamUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamUpdateMutation, { data, loading, error }] = useTeamUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      about: // value for 'about'
 *      picture: // value for 'picture'
 *      contacts: // value for 'contacts'
 *   },
 * });
 */
export function useTeamUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TeamUpdateMutation, TeamUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamUpdateMutation, TeamUpdateMutationVariables>(TeamUpdateDocument, options);
      }
export type TeamUpdateMutationHookResult = ReturnType<typeof useTeamUpdateMutation>;
export type TeamUpdateMutationResult = Apollo.MutationResult<TeamUpdateMutation>;
export type TeamUpdateMutationOptions = Apollo.BaseMutationOptions<TeamUpdateMutation, TeamUpdateMutationVariables>;
export const GetTeamByIdDocument = gql`
    query getTeamById($id: Float!) {
  team_by_id(id: $id) {
    ...TeamInfo
  }
}
    ${TeamInfoFragmentDoc}`;

/**
 * __useGetTeamByIdQuery__
 *
 * To run a query within a React component, call `useGetTeamByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTeamByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables> & ({ variables: GetTeamByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
      }
export function useGetTeamByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
        }
export function useGetTeamByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTeamByIdQuery, GetTeamByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTeamByIdQuery, GetTeamByIdQueryVariables>(GetTeamByIdDocument, options);
        }
export type GetTeamByIdQueryHookResult = ReturnType<typeof useGetTeamByIdQuery>;
export type GetTeamByIdLazyQueryHookResult = ReturnType<typeof useGetTeamByIdLazyQuery>;
export type GetTeamByIdSuspenseQueryHookResult = ReturnType<typeof useGetTeamByIdSuspenseQuery>;
export type GetTeamByIdQueryResult = Apollo.QueryResult<GetTeamByIdQuery, GetTeamByIdQueryVariables>;
export const TeamListDocument = gql`
    query teamList {
  team_list {
    __typename
    nextCursor
    teams {
      ...TeamSearch
    }
  }
}
    ${TeamSearchFragmentDoc}`;

/**
 * __useTeamListQuery__
 *
 * To run a query within a React component, call `useTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamListQuery(baseOptions?: Apollo.QueryHookOptions<TeamListQuery, TeamListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamListQuery, TeamListQueryVariables>(TeamListDocument, options);
      }
export function useTeamListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamListQuery, TeamListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamListQuery, TeamListQueryVariables>(TeamListDocument, options);
        }
export function useTeamListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamListQuery, TeamListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamListQuery, TeamListQueryVariables>(TeamListDocument, options);
        }
export type TeamListQueryHookResult = ReturnType<typeof useTeamListQuery>;
export type TeamListLazyQueryHookResult = ReturnType<typeof useTeamListLazyQuery>;
export type TeamListSuspenseQueryHookResult = ReturnType<typeof useTeamListSuspenseQuery>;
export type TeamListQueryResult = Apollo.QueryResult<TeamListQuery, TeamListQueryVariables>;
export const TeamInfoDocument = gql`
    query teamInfo($slug: String!) {
  team_by_slug(slug: $slug) {
    __typename
    id
    verified
    slug
    name
    description
    kind
    picture
    contacts {
      __typename
      key
      value
    }
    createdAt
    updatedAt
    userEdges {
      __typename
      createdAt
      roles
      user {
        __typename
        id
        firstName
        lastName
        verifiedEmail
        bio
        picture
        timezone
        contacts {
          key
          value
        }
        countryCode
      }
    }
  }
}
    `;

/**
 * __useTeamInfoQuery__
 *
 * To run a query within a React component, call `useTeamInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamInfoQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTeamInfoQuery(baseOptions: Apollo.QueryHookOptions<TeamInfoQuery, TeamInfoQueryVariables> & ({ variables: TeamInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamInfoQuery, TeamInfoQueryVariables>(TeamInfoDocument, options);
      }
export function useTeamInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamInfoQuery, TeamInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamInfoQuery, TeamInfoQueryVariables>(TeamInfoDocument, options);
        }
export function useTeamInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamInfoQuery, TeamInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamInfoQuery, TeamInfoQueryVariables>(TeamInfoDocument, options);
        }
export type TeamInfoQueryHookResult = ReturnType<typeof useTeamInfoQuery>;
export type TeamInfoLazyQueryHookResult = ReturnType<typeof useTeamInfoLazyQuery>;
export type TeamInfoSuspenseQueryHookResult = ReturnType<typeof useTeamInfoSuspenseQuery>;
export type TeamInfoQueryResult = Apollo.QueryResult<TeamInfoQuery, TeamInfoQueryVariables>;
export const TeamAssignUserDocument = gql`
    mutation teamAssignUser($roles: [TeamRole!]!, $userId: Float!, $teamId: Float!) {
  team_add_user(roles: $roles, userId: $userId, teamId: $teamId) {
    __typename
    id
    slug
    name
    description
    picture
    contacts {
      __typename
      key
      value
    }
    verified
    createdAt
    createdBy
  }
}
    `;
export type TeamAssignUserMutationFn = Apollo.MutationFunction<TeamAssignUserMutation, TeamAssignUserMutationVariables>;

/**
 * __useTeamAssignUserMutation__
 *
 * To run a mutation, you first call `useTeamAssignUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamAssignUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamAssignUserMutation, { data, loading, error }] = useTeamAssignUserMutation({
 *   variables: {
 *      roles: // value for 'roles'
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamAssignUserMutation(baseOptions?: Apollo.MutationHookOptions<TeamAssignUserMutation, TeamAssignUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamAssignUserMutation, TeamAssignUserMutationVariables>(TeamAssignUserDocument, options);
      }
export type TeamAssignUserMutationHookResult = ReturnType<typeof useTeamAssignUserMutation>;
export type TeamAssignUserMutationResult = Apollo.MutationResult<TeamAssignUserMutation>;
export type TeamAssignUserMutationOptions = Apollo.BaseMutationOptions<TeamAssignUserMutation, TeamAssignUserMutationVariables>;
export const TeamJoinDocument = gql`
    mutation teamJoin($teamId: Float!, $note: String) {
  team_request_to_join(teamId: $teamId, note: $note) {
    status
  }
}
    `;
export type TeamJoinMutationFn = Apollo.MutationFunction<TeamJoinMutation, TeamJoinMutationVariables>;

/**
 * __useTeamJoinMutation__
 *
 * To run a mutation, you first call `useTeamJoinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamJoinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamJoinMutation, { data, loading, error }] = useTeamJoinMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useTeamJoinMutation(baseOptions?: Apollo.MutationHookOptions<TeamJoinMutation, TeamJoinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamJoinMutation, TeamJoinMutationVariables>(TeamJoinDocument, options);
      }
export type TeamJoinMutationHookResult = ReturnType<typeof useTeamJoinMutation>;
export type TeamJoinMutationResult = Apollo.MutationResult<TeamJoinMutation>;
export type TeamJoinMutationOptions = Apollo.BaseMutationOptions<TeamJoinMutation, TeamJoinMutationVariables>;
export const UserMeDocument = gql`
    query userMe {
  user_me {
    ...MyProfile
  }
}
    ${MyProfileFragmentDoc}`;

/**
 * __useUserMeQuery__
 *
 * To run a query within a React component, call `useUserMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserMeQuery(baseOptions?: Apollo.QueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
      }
export function useUserMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
        }
export function useUserMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserMeQuery, UserMeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserMeQuery, UserMeQueryVariables>(UserMeDocument, options);
        }
export type UserMeQueryHookResult = ReturnType<typeof useUserMeQuery>;
export type UserMeLazyQueryHookResult = ReturnType<typeof useUserMeLazyQuery>;
export type UserMeSuspenseQueryHookResult = ReturnType<typeof useUserMeSuspenseQuery>;
export type UserMeQueryResult = Apollo.QueryResult<UserMeQuery, UserMeQueryVariables>;
export const TeamUsersDocument = gql`
    query teamUsers($teamId: Float!) {
  team_by_id(id: $teamId) {
    __typename
    id
    slug
    name
    verified
    description
    createdAt
    createdBy
    updatedAt
    updatedBy
    userEdges {
      __typename
      roles
      user {
        __typename
        id
        firstName
        lastName
        verifiedEmail
        bio
        picture
        countryCode
      }
    }
  }
}
    `;

/**
 * __useTeamUsersQuery__
 *
 * To run a query within a React component, call `useTeamUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamUsersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamUsersQuery(baseOptions: Apollo.QueryHookOptions<TeamUsersQuery, TeamUsersQueryVariables> & ({ variables: TeamUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamUsersQuery, TeamUsersQueryVariables>(TeamUsersDocument, options);
      }
export function useTeamUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamUsersQuery, TeamUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamUsersQuery, TeamUsersQueryVariables>(TeamUsersDocument, options);
        }
export function useTeamUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamUsersQuery, TeamUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamUsersQuery, TeamUsersQueryVariables>(TeamUsersDocument, options);
        }
export type TeamUsersQueryHookResult = ReturnType<typeof useTeamUsersQuery>;
export type TeamUsersLazyQueryHookResult = ReturnType<typeof useTeamUsersLazyQuery>;
export type TeamUsersSuspenseQueryHookResult = ReturnType<typeof useTeamUsersSuspenseQuery>;
export type TeamUsersQueryResult = Apollo.QueryResult<TeamUsersQuery, TeamUsersQueryVariables>;
export const UserUpdateDocument = gql`
    mutation userUpdate($id: Float!, $firstName: String, $lastName: String, $bio: String, $picture: String, $timezone: String, $countryCode: String) {
  user_update(
    user: {id: $id, firstName: $firstName, lastName: $lastName, bio: $bio, picture: $picture, timezone: $timezone, countryCode: $countryCode}
  ) {
    __typename
    id
  }
}
    `;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      bio: // value for 'bio'
 *      picture: // value for 'picture'
 *      timezone: // value for 'timezone'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserByIdDocument = gql`
    query userById($id: Float!) {
  user_by_id(id: $id) {
    __typename
    firstName
    lastName
    slug
    picture
  }
}
    `;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables> & ({ variables: UserByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export function useUserByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdSuspenseQueryHookResult = ReturnType<typeof useUserByIdSuspenseQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const AutocompleteSuppliersDocument = gql`
    query autocompleteSuppliers($teamId: Float!) {
  supplier_list(teamId: $teamId) {
    __typename
    suppliers {
      __typename
      id
      name
      capabilities
      addressLine1
      addressLine2
      city
      state
      postalCode
      countryCode
    }
  }
}
    `;

/**
 * __useAutocompleteSuppliersQuery__
 *
 * To run a query within a React component, call `useAutocompleteSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteSuppliersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAutocompleteSuppliersQuery(baseOptions: Apollo.QueryHookOptions<AutocompleteSuppliersQuery, AutocompleteSuppliersQueryVariables> & ({ variables: AutocompleteSuppliersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutocompleteSuppliersQuery, AutocompleteSuppliersQueryVariables>(AutocompleteSuppliersDocument, options);
      }
export function useAutocompleteSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutocompleteSuppliersQuery, AutocompleteSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutocompleteSuppliersQuery, AutocompleteSuppliersQueryVariables>(AutocompleteSuppliersDocument, options);
        }
export function useAutocompleteSuppliersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AutocompleteSuppliersQuery, AutocompleteSuppliersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AutocompleteSuppliersQuery, AutocompleteSuppliersQueryVariables>(AutocompleteSuppliersDocument, options);
        }
export type AutocompleteSuppliersQueryHookResult = ReturnType<typeof useAutocompleteSuppliersQuery>;
export type AutocompleteSuppliersLazyQueryHookResult = ReturnType<typeof useAutocompleteSuppliersLazyQuery>;
export type AutocompleteSuppliersSuspenseQueryHookResult = ReturnType<typeof useAutocompleteSuppliersSuspenseQuery>;
export type AutocompleteSuppliersQueryResult = Apollo.QueryResult<AutocompleteSuppliersQuery, AutocompleteSuppliersQueryVariables>;
export const UserTooltipBySlugDocument = gql`
    query UserTooltipBySlug($slug: String!) {
  user_by_slug(slug: $slug) {
    __typename
    slug
    firstName
    lastName
    verifiedEmail
    picture
    background
    timezone
    countryCode
  }
}
    `;

/**
 * __useUserTooltipBySlugQuery__
 *
 * To run a query within a React component, call `useUserTooltipBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTooltipBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTooltipBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserTooltipBySlugQuery(baseOptions: Apollo.QueryHookOptions<UserTooltipBySlugQuery, UserTooltipBySlugQueryVariables> & ({ variables: UserTooltipBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTooltipBySlugQuery, UserTooltipBySlugQueryVariables>(UserTooltipBySlugDocument, options);
      }
export function useUserTooltipBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTooltipBySlugQuery, UserTooltipBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTooltipBySlugQuery, UserTooltipBySlugQueryVariables>(UserTooltipBySlugDocument, options);
        }
export function useUserTooltipBySlugSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserTooltipBySlugQuery, UserTooltipBySlugQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserTooltipBySlugQuery, UserTooltipBySlugQueryVariables>(UserTooltipBySlugDocument, options);
        }
export type UserTooltipBySlugQueryHookResult = ReturnType<typeof useUserTooltipBySlugQuery>;
export type UserTooltipBySlugLazyQueryHookResult = ReturnType<typeof useUserTooltipBySlugLazyQuery>;
export type UserTooltipBySlugSuspenseQueryHookResult = ReturnType<typeof useUserTooltipBySlugSuspenseQuery>;
export type UserTooltipBySlugQueryResult = Apollo.QueryResult<UserTooltipBySlugQuery, UserTooltipBySlugQueryVariables>;
export const UserSendVerificationEmailDocument = gql`
    mutation UserSendVerificationEmail {
  user_send_verification_email {
    id
    verifiedEmail
  }
}
    `;
export type UserSendVerificationEmailMutationFn = Apollo.MutationFunction<UserSendVerificationEmailMutation, UserSendVerificationEmailMutationVariables>;

/**
 * __useUserSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useUserSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSendVerificationEmailMutation, { data, loading, error }] = useUserSendVerificationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserSendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<UserSendVerificationEmailMutation, UserSendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSendVerificationEmailMutation, UserSendVerificationEmailMutationVariables>(UserSendVerificationEmailDocument, options);
      }
export type UserSendVerificationEmailMutationHookResult = ReturnType<typeof useUserSendVerificationEmailMutation>;
export type UserSendVerificationEmailMutationResult = Apollo.MutationResult<UserSendVerificationEmailMutation>;
export type UserSendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<UserSendVerificationEmailMutation, UserSendVerificationEmailMutationVariables>;
export const MyTeamsDocument = gql`
    query MyTeams {
  user_me {
    __typename
    id
    teamEdges {
      __typename
      roles
      team {
        name
        picture
        slug
      }
    }
  }
}
    `;

/**
 * __useMyTeamsQuery__
 *
 * To run a query within a React component, call `useMyTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTeamsQuery(baseOptions?: Apollo.QueryHookOptions<MyTeamsQuery, MyTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTeamsQuery, MyTeamsQueryVariables>(MyTeamsDocument, options);
      }
export function useMyTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTeamsQuery, MyTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTeamsQuery, MyTeamsQueryVariables>(MyTeamsDocument, options);
        }
export function useMyTeamsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyTeamsQuery, MyTeamsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyTeamsQuery, MyTeamsQueryVariables>(MyTeamsDocument, options);
        }
export type MyTeamsQueryHookResult = ReturnType<typeof useMyTeamsQuery>;
export type MyTeamsLazyQueryHookResult = ReturnType<typeof useMyTeamsLazyQuery>;
export type MyTeamsSuspenseQueryHookResult = ReturnType<typeof useMyTeamsSuspenseQuery>;
export type MyTeamsQueryResult = Apollo.QueryResult<MyTeamsQuery, MyTeamsQueryVariables>;
export const ApiKeysDocument = gql`
    query apiKeys {
  authorization_key_list {
    ...AuthorizationKey
  }
}
    ${AuthorizationKeyFragmentDoc}`;

/**
 * __useApiKeysQuery__
 *
 * To run a query within a React component, call `useApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiKeysQuery(baseOptions?: Apollo.QueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
      }
export function useApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
        }
export function useApiKeysSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ApiKeysQuery, ApiKeysQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiKeysQuery, ApiKeysQueryVariables>(ApiKeysDocument, options);
        }
export type ApiKeysQueryHookResult = ReturnType<typeof useApiKeysQuery>;
export type ApiKeysLazyQueryHookResult = ReturnType<typeof useApiKeysLazyQuery>;
export type ApiKeysSuspenseQueryHookResult = ReturnType<typeof useApiKeysSuspenseQuery>;
export type ApiKeysQueryResult = Apollo.QueryResult<ApiKeysQuery, ApiKeysQueryVariables>;
export const CreateApiKeyDocument = gql`
    mutation createAPIKey($authorizationKey: AuthorizationKeyInput!) {
  authorization_key_create(authorizationKey: $authorizationKey) {
    ...AuthorizationKey
  }
}
    ${AuthorizationKeyFragmentDoc}`;
export type CreateApiKeyMutationFn = Apollo.MutationFunction<CreateApiKeyMutation, CreateApiKeyMutationVariables>;

/**
 * __useCreateApiKeyMutation__
 *
 * To run a mutation, you first call `useCreateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiKeyMutation, { data, loading, error }] = useCreateApiKeyMutation({
 *   variables: {
 *      authorizationKey: // value for 'authorizationKey'
 *   },
 * });
 */
export function useCreateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiKeyMutation, CreateApiKeyMutationVariables>(CreateApiKeyDocument, options);
      }
export type CreateApiKeyMutationHookResult = ReturnType<typeof useCreateApiKeyMutation>;
export type CreateApiKeyMutationResult = Apollo.MutationResult<CreateApiKeyMutation>;
export type CreateApiKeyMutationOptions = Apollo.BaseMutationOptions<CreateApiKeyMutation, CreateApiKeyMutationVariables>;
export const RevokeApiKeyDocument = gql`
    mutation revokeAPIKey($authorizationKeyId: Float!) {
  authorization_key_revoke(authorizationKeyId: $authorizationKeyId) {
    ...AuthorizationKey
  }
}
    ${AuthorizationKeyFragmentDoc}`;
export type RevokeApiKeyMutationFn = Apollo.MutationFunction<RevokeApiKeyMutation, RevokeApiKeyMutationVariables>;

/**
 * __useRevokeApiKeyMutation__
 *
 * To run a mutation, you first call `useRevokeApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeApiKeyMutation, { data, loading, error }] = useRevokeApiKeyMutation({
 *   variables: {
 *      authorizationKeyId: // value for 'authorizationKeyId'
 *   },
 * });
 */
export function useRevokeApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<RevokeApiKeyMutation, RevokeApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeApiKeyMutation, RevokeApiKeyMutationVariables>(RevokeApiKeyDocument, options);
      }
export type RevokeApiKeyMutationHookResult = ReturnType<typeof useRevokeApiKeyMutation>;
export type RevokeApiKeyMutationResult = Apollo.MutationResult<RevokeApiKeyMutation>;
export type RevokeApiKeyMutationOptions = Apollo.BaseMutationOptions<RevokeApiKeyMutation, RevokeApiKeyMutationVariables>;
export const AllBrandsDocument = gql`
    query AllBrands {
  team_list {
    __typename
    nextCursor
    teams {
      __typename
      id
      verified
      slug
      name
      description
      kind
      picture
      createdAt
    }
  }
}
    `;

/**
 * __useAllBrandsQuery__
 *
 * To run a query within a React component, call `useAllBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBrandsQuery(baseOptions?: Apollo.QueryHookOptions<AllBrandsQuery, AllBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBrandsQuery, AllBrandsQueryVariables>(AllBrandsDocument, options);
      }
export function useAllBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBrandsQuery, AllBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBrandsQuery, AllBrandsQueryVariables>(AllBrandsDocument, options);
        }
export function useAllBrandsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllBrandsQuery, AllBrandsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllBrandsQuery, AllBrandsQueryVariables>(AllBrandsDocument, options);
        }
export type AllBrandsQueryHookResult = ReturnType<typeof useAllBrandsQuery>;
export type AllBrandsLazyQueryHookResult = ReturnType<typeof useAllBrandsLazyQuery>;
export type AllBrandsSuspenseQueryHookResult = ReturnType<typeof useAllBrandsSuspenseQuery>;
export type AllBrandsQueryResult = Apollo.QueryResult<AllBrandsQuery, AllBrandsQueryVariables>;
export const UserBySlugDocument = gql`
    query userBySlug($slug: String!) {
  user_by_slug(slug: $slug) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useUserBySlugQuery__
 *
 * To run a query within a React component, call `useUserBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUserBySlugQuery(baseOptions: Apollo.QueryHookOptions<UserBySlugQuery, UserBySlugQueryVariables> & ({ variables: UserBySlugQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserBySlugQuery, UserBySlugQueryVariables>(UserBySlugDocument, options);
      }
export function useUserBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserBySlugQuery, UserBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserBySlugQuery, UserBySlugQueryVariables>(UserBySlugDocument, options);
        }
export function useUserBySlugSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserBySlugQuery, UserBySlugQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserBySlugQuery, UserBySlugQueryVariables>(UserBySlugDocument, options);
        }
export type UserBySlugQueryHookResult = ReturnType<typeof useUserBySlugQuery>;
export type UserBySlugLazyQueryHookResult = ReturnType<typeof useUserBySlugLazyQuery>;
export type UserBySlugSuspenseQueryHookResult = ReturnType<typeof useUserBySlugSuspenseQuery>;
export type UserBySlugQueryResult = Apollo.QueryResult<UserBySlugQuery, UserBySlugQueryVariables>;
export const TeamProfileDocument = gql`
    query TeamProfile($slug: String!) {
  team_by_slug(slug: $slug) {
    __typename
    id
    verified
    slug
    name
    description
    about
    kind
    picture
    background
    contacts {
      __typename
      key
      value
    }
    userEdges {
      user {
        id
        slug
        picture
      }
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useTeamProfileQuery__
 *
 * To run a query within a React component, call `useTeamProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamProfileQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTeamProfileQuery(baseOptions: Apollo.QueryHookOptions<TeamProfileQuery, TeamProfileQueryVariables> & ({ variables: TeamProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamProfileQuery, TeamProfileQueryVariables>(TeamProfileDocument, options);
      }
export function useTeamProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamProfileQuery, TeamProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamProfileQuery, TeamProfileQueryVariables>(TeamProfileDocument, options);
        }
export function useTeamProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamProfileQuery, TeamProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamProfileQuery, TeamProfileQueryVariables>(TeamProfileDocument, options);
        }
export type TeamProfileQueryHookResult = ReturnType<typeof useTeamProfileQuery>;
export type TeamProfileLazyQueryHookResult = ReturnType<typeof useTeamProfileLazyQuery>;
export type TeamProfileSuspenseQueryHookResult = ReturnType<typeof useTeamProfileSuspenseQuery>;
export type TeamProfileQueryResult = Apollo.QueryResult<TeamProfileQuery, TeamProfileQueryVariables>;
export const TableViewContactsDocument = gql`
    query TableViewContacts($teamId: Float!) {
  contact_list(teamId: $teamId) {
    __typename
    contacts {
      ...ContactTableItem
    }
  }
}
    ${ContactTableItemFragmentDoc}`;

/**
 * __useTableViewContactsQuery__
 *
 * To run a query within a React component, call `useTableViewContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableViewContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableViewContactsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTableViewContactsQuery(baseOptions: Apollo.QueryHookOptions<TableViewContactsQuery, TableViewContactsQueryVariables> & ({ variables: TableViewContactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableViewContactsQuery, TableViewContactsQueryVariables>(TableViewContactsDocument, options);
      }
export function useTableViewContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableViewContactsQuery, TableViewContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableViewContactsQuery, TableViewContactsQueryVariables>(TableViewContactsDocument, options);
        }
export function useTableViewContactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TableViewContactsQuery, TableViewContactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableViewContactsQuery, TableViewContactsQueryVariables>(TableViewContactsDocument, options);
        }
export type TableViewContactsQueryHookResult = ReturnType<typeof useTableViewContactsQuery>;
export type TableViewContactsLazyQueryHookResult = ReturnType<typeof useTableViewContactsLazyQuery>;
export type TableViewContactsSuspenseQueryHookResult = ReturnType<typeof useTableViewContactsSuspenseQuery>;
export type TableViewContactsQueryResult = Apollo.QueryResult<TableViewContactsQuery, TableViewContactsQueryVariables>;
export const ContactUpsertDocument = gql`
    mutation ContactUpsert($contact: UpsertSupplierContactInput!) {
  contact_upsert(contact: $contact) {
    ...ContactTableItem
  }
}
    ${ContactTableItemFragmentDoc}`;
export type ContactUpsertMutationFn = Apollo.MutationFunction<ContactUpsertMutation, ContactUpsertMutationVariables>;

/**
 * __useContactUpsertMutation__
 *
 * To run a mutation, you first call `useContactUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUpsertMutation, { data, loading, error }] = useContactUpsertMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useContactUpsertMutation(baseOptions?: Apollo.MutationHookOptions<ContactUpsertMutation, ContactUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactUpsertMutation, ContactUpsertMutationVariables>(ContactUpsertDocument, options);
      }
export type ContactUpsertMutationHookResult = ReturnType<typeof useContactUpsertMutation>;
export type ContactUpsertMutationResult = Apollo.MutationResult<ContactUpsertMutation>;
export type ContactUpsertMutationOptions = Apollo.BaseMutationOptions<ContactUpsertMutation, ContactUpsertMutationVariables>;
export const SupplierTooltipDocument = gql`
    query supplierTooltip($id: String!) {
  supplier_by_id(uniqueId: $id) {
    __typename
    id
    name
    capabilities
    addressLine1
    addressLine2
    city
    state
    postalCode
    countryCode
    createdAt
  }
}
    `;

/**
 * __useSupplierTooltipQuery__
 *
 * To run a query within a React component, call `useSupplierTooltipQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierTooltipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierTooltipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplierTooltipQuery(baseOptions: Apollo.QueryHookOptions<SupplierTooltipQuery, SupplierTooltipQueryVariables> & ({ variables: SupplierTooltipQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupplierTooltipQuery, SupplierTooltipQueryVariables>(SupplierTooltipDocument, options);
      }
export function useSupplierTooltipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupplierTooltipQuery, SupplierTooltipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupplierTooltipQuery, SupplierTooltipQueryVariables>(SupplierTooltipDocument, options);
        }
export function useSupplierTooltipSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SupplierTooltipQuery, SupplierTooltipQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SupplierTooltipQuery, SupplierTooltipQueryVariables>(SupplierTooltipDocument, options);
        }
export type SupplierTooltipQueryHookResult = ReturnType<typeof useSupplierTooltipQuery>;
export type SupplierTooltipLazyQueryHookResult = ReturnType<typeof useSupplierTooltipLazyQuery>;
export type SupplierTooltipSuspenseQueryHookResult = ReturnType<typeof useSupplierTooltipSuspenseQuery>;
export type SupplierTooltipQueryResult = Apollo.QueryResult<SupplierTooltipQuery, SupplierTooltipQueryVariables>;
export const GenralTeamSettingsDocument = gql`
    query GenralTeamSettings($slug: String!) {
  team_by_slug(slug: $slug) {
    __typename
    id
    verified
    slug
    name
    description
    about
    kind
    picture
    background
    contacts {
      __typename
      key
      value
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGenralTeamSettingsQuery__
 *
 * To run a query within a React component, call `useGenralTeamSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenralTeamSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenralTeamSettingsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGenralTeamSettingsQuery(baseOptions: Apollo.QueryHookOptions<GenralTeamSettingsQuery, GenralTeamSettingsQueryVariables> & ({ variables: GenralTeamSettingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenralTeamSettingsQuery, GenralTeamSettingsQueryVariables>(GenralTeamSettingsDocument, options);
      }
export function useGenralTeamSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenralTeamSettingsQuery, GenralTeamSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenralTeamSettingsQuery, GenralTeamSettingsQueryVariables>(GenralTeamSettingsDocument, options);
        }
export function useGenralTeamSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GenralTeamSettingsQuery, GenralTeamSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GenralTeamSettingsQuery, GenralTeamSettingsQueryVariables>(GenralTeamSettingsDocument, options);
        }
export type GenralTeamSettingsQueryHookResult = ReturnType<typeof useGenralTeamSettingsQuery>;
export type GenralTeamSettingsLazyQueryHookResult = ReturnType<typeof useGenralTeamSettingsLazyQuery>;
export type GenralTeamSettingsSuspenseQueryHookResult = ReturnType<typeof useGenralTeamSettingsSuspenseQuery>;
export type GenralTeamSettingsQueryResult = Apollo.QueryResult<GenralTeamSettingsQuery, GenralTeamSettingsQueryVariables>;
export const TeamJoinRequestsDocument = gql`
    query TeamJoinRequests($teamId: Float!) {
  team_request_list(teamId: $teamId) {
    __typename
    items {
      id
      user {
        __typename
        id
        firstName
        lastName
        slug
        picture
      }
      status
      note
      createdAt
    }
  }
}
    `;

/**
 * __useTeamJoinRequestsQuery__
 *
 * To run a query within a React component, call `useTeamJoinRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamJoinRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamJoinRequestsQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamJoinRequestsQuery(baseOptions: Apollo.QueryHookOptions<TeamJoinRequestsQuery, TeamJoinRequestsQueryVariables> & ({ variables: TeamJoinRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamJoinRequestsQuery, TeamJoinRequestsQueryVariables>(TeamJoinRequestsDocument, options);
      }
export function useTeamJoinRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamJoinRequestsQuery, TeamJoinRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamJoinRequestsQuery, TeamJoinRequestsQueryVariables>(TeamJoinRequestsDocument, options);
        }
export function useTeamJoinRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamJoinRequestsQuery, TeamJoinRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamJoinRequestsQuery, TeamJoinRequestsQueryVariables>(TeamJoinRequestsDocument, options);
        }
export type TeamJoinRequestsQueryHookResult = ReturnType<typeof useTeamJoinRequestsQuery>;
export type TeamJoinRequestsLazyQueryHookResult = ReturnType<typeof useTeamJoinRequestsLazyQuery>;
export type TeamJoinRequestsSuspenseQueryHookResult = ReturnType<typeof useTeamJoinRequestsSuspenseQuery>;
export type TeamJoinRequestsQueryResult = Apollo.QueryResult<TeamJoinRequestsQuery, TeamJoinRequestsQueryVariables>;
export const ApproveTeamJoinRequestDocument = gql`
    mutation ApproveTeamJoinRequest($id: Float!) {
  team_request_approve(id: $id) {
    __typename
    id
  }
}
    `;
export type ApproveTeamJoinRequestMutationFn = Apollo.MutationFunction<ApproveTeamJoinRequestMutation, ApproveTeamJoinRequestMutationVariables>;

/**
 * __useApproveTeamJoinRequestMutation__
 *
 * To run a mutation, you first call `useApproveTeamJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTeamJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTeamJoinRequestMutation, { data, loading, error }] = useApproveTeamJoinRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveTeamJoinRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveTeamJoinRequestMutation, ApproveTeamJoinRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveTeamJoinRequestMutation, ApproveTeamJoinRequestMutationVariables>(ApproveTeamJoinRequestDocument, options);
      }
export type ApproveTeamJoinRequestMutationHookResult = ReturnType<typeof useApproveTeamJoinRequestMutation>;
export type ApproveTeamJoinRequestMutationResult = Apollo.MutationResult<ApproveTeamJoinRequestMutation>;
export type ApproveTeamJoinRequestMutationOptions = Apollo.BaseMutationOptions<ApproveTeamJoinRequestMutation, ApproveTeamJoinRequestMutationVariables>;
export const RejectTeamJoinRequestDocument = gql`
    mutation RejectTeamJoinRequest($id: Float!) {
  team_request_reject(id: $id) {
    __typename
    id
  }
}
    `;
export type RejectTeamJoinRequestMutationFn = Apollo.MutationFunction<RejectTeamJoinRequestMutation, RejectTeamJoinRequestMutationVariables>;

/**
 * __useRejectTeamJoinRequestMutation__
 *
 * To run a mutation, you first call `useRejectTeamJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectTeamJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectTeamJoinRequestMutation, { data, loading, error }] = useRejectTeamJoinRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRejectTeamJoinRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectTeamJoinRequestMutation, RejectTeamJoinRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectTeamJoinRequestMutation, RejectTeamJoinRequestMutationVariables>(RejectTeamJoinRequestDocument, options);
      }
export type RejectTeamJoinRequestMutationHookResult = ReturnType<typeof useRejectTeamJoinRequestMutation>;
export type RejectTeamJoinRequestMutationResult = Apollo.MutationResult<RejectTeamJoinRequestMutation>;
export type RejectTeamJoinRequestMutationOptions = Apollo.BaseMutationOptions<RejectTeamJoinRequestMutation, RejectTeamJoinRequestMutationVariables>;
export const TeamMembersDocument = gql`
    query TeamMembers($slug: String!) {
  team_by_slug(slug: $slug) {
    __typename
    userEdges {
      roles
      createdAt
      user {
        __typename
        id
        slug
        firstName
        lastName
        countryCode
      }
    }
  }
}
    `;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables> & ({ variables: TeamMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
      }
export function useTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
        }
export function useTeamMembersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
        }
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersSuspenseQueryHookResult = ReturnType<typeof useTeamMembersSuspenseQuery>;
export type TeamMembersQueryResult = Apollo.QueryResult<TeamMembersQuery, TeamMembersQueryVariables>;
export const TeamAddUserDocument = gql`
    mutation TeamAddUser($teamId: Float!, $userId: Float!, $roles: [TeamRole!]!) {
  team_add_user(roles: $roles, userId: $userId, teamId: $teamId) {
    __typename
    userEdges {
      roles
      createdAt
      user {
        __typename
        id
        slug
        firstName
        lastName
        countryCode
      }
    }
  }
}
    `;
export type TeamAddUserMutationFn = Apollo.MutationFunction<TeamAddUserMutation, TeamAddUserMutationVariables>;

/**
 * __useTeamAddUserMutation__
 *
 * To run a mutation, you first call `useTeamAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamAddUserMutation, { data, loading, error }] = useTeamAddUserMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useTeamAddUserMutation(baseOptions?: Apollo.MutationHookOptions<TeamAddUserMutation, TeamAddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamAddUserMutation, TeamAddUserMutationVariables>(TeamAddUserDocument, options);
      }
export type TeamAddUserMutationHookResult = ReturnType<typeof useTeamAddUserMutation>;
export type TeamAddUserMutationResult = Apollo.MutationResult<TeamAddUserMutation>;
export type TeamAddUserMutationOptions = Apollo.BaseMutationOptions<TeamAddUserMutation, TeamAddUserMutationVariables>;
export const TableViewSuppliersDocument = gql`
    query tableViewSuppliers($teamId: Float!) {
  supplier_list(teamId: $teamId) {
    __typename
    suppliers {
      ...SupplierTableItem
    }
  }
}
    ${SupplierTableItemFragmentDoc}`;

/**
 * __useTableViewSuppliersQuery__
 *
 * To run a query within a React component, call `useTableViewSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableViewSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableViewSuppliersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTableViewSuppliersQuery(baseOptions: Apollo.QueryHookOptions<TableViewSuppliersQuery, TableViewSuppliersQueryVariables> & ({ variables: TableViewSuppliersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TableViewSuppliersQuery, TableViewSuppliersQueryVariables>(TableViewSuppliersDocument, options);
      }
export function useTableViewSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TableViewSuppliersQuery, TableViewSuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TableViewSuppliersQuery, TableViewSuppliersQueryVariables>(TableViewSuppliersDocument, options);
        }
export function useTableViewSuppliersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TableViewSuppliersQuery, TableViewSuppliersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TableViewSuppliersQuery, TableViewSuppliersQueryVariables>(TableViewSuppliersDocument, options);
        }
export type TableViewSuppliersQueryHookResult = ReturnType<typeof useTableViewSuppliersQuery>;
export type TableViewSuppliersLazyQueryHookResult = ReturnType<typeof useTableViewSuppliersLazyQuery>;
export type TableViewSuppliersSuspenseQueryHookResult = ReturnType<typeof useTableViewSuppliersSuspenseQuery>;
export type TableViewSuppliersQueryResult = Apollo.QueryResult<TableViewSuppliersQuery, TableViewSuppliersQueryVariables>;
export const SupplierUpsertDocument = gql`
    mutation supplierUpsert($supplier: UpsertSupplierInput!) {
  supplier_upsert(supplier: $supplier) {
    ...SupplierTableItem
  }
}
    ${SupplierTableItemFragmentDoc}`;
export type SupplierUpsertMutationFn = Apollo.MutationFunction<SupplierUpsertMutation, SupplierUpsertMutationVariables>;

/**
 * __useSupplierUpsertMutation__
 *
 * To run a mutation, you first call `useSupplierUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupplierUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supplierUpsertMutation, { data, loading, error }] = useSupplierUpsertMutation({
 *   variables: {
 *      supplier: // value for 'supplier'
 *   },
 * });
 */
export function useSupplierUpsertMutation(baseOptions?: Apollo.MutationHookOptions<SupplierUpsertMutation, SupplierUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SupplierUpsertMutation, SupplierUpsertMutationVariables>(SupplierUpsertDocument, options);
      }
export type SupplierUpsertMutationHookResult = ReturnType<typeof useSupplierUpsertMutation>;
export type SupplierUpsertMutationResult = Apollo.MutationResult<SupplierUpsertMutation>;
export type SupplierUpsertMutationOptions = Apollo.BaseMutationOptions<SupplierUpsertMutation, SupplierUpsertMutationVariables>;
export const XTeamsAutocompleteDocument = gql`
    query XTeamsAutocomplete {
  team_list {
    __typename
    nextCursor
    teams {
      __typename
      id
      verified
      slug
      name
      description
      kind
      picture
      createdAt
    }
  }
}
    `;

/**
 * __useXTeamsAutocompleteQuery__
 *
 * To run a query within a React component, call `useXTeamsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useXTeamsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useXTeamsAutocompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useXTeamsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<XTeamsAutocompleteQuery, XTeamsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<XTeamsAutocompleteQuery, XTeamsAutocompleteQueryVariables>(XTeamsAutocompleteDocument, options);
      }
export function useXTeamsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<XTeamsAutocompleteQuery, XTeamsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<XTeamsAutocompleteQuery, XTeamsAutocompleteQueryVariables>(XTeamsAutocompleteDocument, options);
        }
export function useXTeamsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<XTeamsAutocompleteQuery, XTeamsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<XTeamsAutocompleteQuery, XTeamsAutocompleteQueryVariables>(XTeamsAutocompleteDocument, options);
        }
export type XTeamsAutocompleteQueryHookResult = ReturnType<typeof useXTeamsAutocompleteQuery>;
export type XTeamsAutocompleteLazyQueryHookResult = ReturnType<typeof useXTeamsAutocompleteLazyQuery>;
export type XTeamsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useXTeamsAutocompleteSuspenseQuery>;
export type XTeamsAutocompleteQueryResult = Apollo.QueryResult<XTeamsAutocompleteQuery, XTeamsAutocompleteQueryVariables>;