import {
  Alert,
  Button,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { useTranslation } from "../../../../i18n";
import {
  useGenralTeamSettingsQuery,
  useTeamUpdateMutation,
} from "../../../../__generated__/types-and-hooks";
import { XTextArea } from "../../../../x-components/XTextArea";

export interface AboutEditorModalProps {
  open: boolean;
}

export default function AboutEditorModal({ open }: AboutEditorModalProps) {
  const t = useTranslation();
  const about$ = useMemo(() => new BehaviorSubject<string | null>(null), []);
  const navigate = useNavigate();
  const showError = true;

  const [teamUpdateMutation, { error }] = useTeamUpdateMutation({});

  const { teamSlug } = useParams();
  const genralTeamSettingsQuery = useGenralTeamSettingsQuery({
    variables: { slug: teamSlug! },
  });

  const team = genralTeamSettingsQuery.data?.team_by_slug;

  useEffect(() => {
    about$.next(team?.about ?? "");
  }, [team, about$]);

  const handleClose = useCallback(() => {
    navigate("../");
  }, [navigate]);

  const handleSave = useCallback(
    (e: any) => {
      e.preventDefault();

      if (team) {
        firstValueFrom(about$).then((about) => {
          teamUpdateMutation({
            variables: {
              id: team.id,
              about: about,
              contacts: [],
            },
            onCompleted() {
              genralTeamSettingsQuery.refetch();
              handleClose();
            },
          });
        });
      }
    },
    [teamUpdateMutation, handleClose, genralTeamSettingsQuery, team, about$]
  );

  return (
    <Modal open={open} onClose={() => navigate("../")}>
      <ModalDialog size="md" variant="outlined">
        <DialogTitle>
          {t("Change About")}
          <ModalClose />
        </DialogTitle>
        <form>
          <Stack spacing={2}>
            <Typography level="body-xs" sx={{ marginTop: 1 }}>
              {t(
                "Highlight your company's expertise, accomplishments, and background."
              )}
            </Typography>
            <XTextArea
              label={t("About")}
              size="sm"
              placeholder={t("")}
              val$={about$}
              showError={showError}
              minRows={10}
            />
            <Button onClick={handleSave} type="submit" fullWidth>
              {t("Save")}
            </Button>
            {error && <Alert color="danger">{error.message}</Alert>}
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
