import Button from "@mui/joy/Button";
import CardOverflow from "@mui/joy/CardOverflow";
import {
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import Box from "@mui/joy/Box";
import { QCSKULogo } from "../../../components/QCSKULogo";
import { useTranslation } from "../../../i18n";

export interface GetStartedProps {
  open: boolean;
}

export default function GetStarted({ open }: GetStartedProps) {
  const t = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={() => navigate("..")}>
      <ModalDialog size="lg" variant="outlined">
        <DialogTitle>
          {t("Get Started")}
          <ModalClose />
        </DialogTitle>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            "--icon-size": "100px",
            bgcolor: "background.surface",
          }}
        >
          <CardOverflow variant="soft">
            <QCSKULogo />
          </CardOverflow>
          <DialogContent
            sx={{ mt: "calc(var(--icon-size) / 2)", maxWidth: "40ch" }}
          >
            <Typography sx={{ mt: 3, mb: 3 }}>
              {t("What role suites you best?")}
            </Typography>
            <Box sx={{ width: 300 }}></Box>
          </DialogContent>
          <Stack gap={2}>
            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={() => navigate("/create-brand")}
            >
              {t("I'm a Brand: Manage inventory & schedule QC inspections.")}
            </Button>

            <Button
              variant={"outlined"}
              color={"primary"}
              onClick={() => navigate("/create-agency")}
            >
              {t("I'm an Agency: Provide QC inspection services.")}
            </Button>
            <Button
              variant="plain"
              color="primary"
              onClick={() => navigate("/join")}
            >
              {t("Join Brand or Agency")}
            </Button>
          </Stack>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
