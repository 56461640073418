import AnvylConfigurationModal from "./AnvylConfigurationModal";
import General from "./general";
import AboutEditorModal from "./general/AboutEditorModal";
import NameEditorModal from "./general/NameEditorModal";
import Integrations from "./Integrations";
import InviteMemberModal from "./InviteMemberModal";
import JoinRequests from "./join-requests";
import Members from "./members";
import Settings from "./Settings";

export const teamSettingsRoutes = {
  path: "/:teamSlug/settings",
  element: <Settings />,
  children: [
    {
      path: "/:teamSlug/settings",
      element: <General />,
      children: [
        {
          path: "/:teamSlug/settings/about",
          element: <AboutEditorModal open={true} />,
        },
        {
          path: "/:teamSlug/settings/edit-name",
          element: <NameEditorModal open={true} />,
        },
      ],
    },
    {
      path: "/:teamSlug/settings/general",
      element: <General />,
      children: [
        {
          path: "/:teamSlug/settings/general/about",
          element: <AboutEditorModal open={true} />,
        },
        {
          path: "/:teamSlug/settings/general/edit-name",
          element: <NameEditorModal open={true} />,
        },
      ],
    },
    {
      path: "/:teamSlug/settings/integrations",
      element: (
        <>
          <Integrations />
          <AnvylConfigurationModal open={false} />
        </>
      ),
    },
    {
      path: "/:teamSlug/settings/integrations/anvyl",
      element: (
        <>
          <Integrations />
          <AnvylConfigurationModal open={true} />
        </>
      ),
    },
    {
      path: "/:teamSlug/settings/members",
      element: (
        <>
          <Members />
          <JoinRequests />
        </>
      ),
      children: [
        {
          path: "/:teamSlug/settings/members/add",
          element: <InviteMemberModal open={true} />,
        },
      ],
    },
  ],
};
