import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardOverflow,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemContent,
  ModalClose,
  Sheet,
  Typography,
  useColorScheme,
} from "@mui/joy";
import { useTranslation } from "../../../../i18n";
import {
  InspectionVisibility,
  useInspectionByRefQuery,
  useInspectionUpdateMutation,
} from "../../../../__generated__/types-and-hooks";
import Money from "../../../../components/Money";
import { CardActions, DialogTitle } from "@mui/material";
import { DateTime } from "../../../../components/DateTime";
import Check from "@mui/icons-material/Check";
import ChatBubbleOutlineOutlined from "@mui/icons-material/ChatBubbleOutlineOutlined";
import InspectionRef from "../../../../components/InspectionRef";
import { useTeam } from "../../TeamLayout";
import DOMPurify from "dompurify";

export interface QuotesModalProps {
  inspectionRef: string;
  open: boolean;
  onClose: () => void;
}

export default function QuotesModal({
  inspectionRef,
  open,
  onClose,
}: QuotesModalProps) {
  const { mode } = useColorScheme();
  const t = useTranslation();
  const { data: inspection } = useInspectionByRefQuery({
    variables: { ref: inspectionRef },
  });
  const teamCtx = useTeam();

  const [inspectionUpdateMutation] = useInspectionUpdateMutation({});

  const handleAcceptQuote = async (quote: {
    agencyId: number;
    serviceDateStart: Date;
  }) => {
    inspectionUpdateMutation({
      variables: {
        updates: {
          id: inspection!.inspection_by_ref!.id,
          agencyId: quote.agencyId,
          serviceDate: quote.serviceDateStart,
          visibility: InspectionVisibility.Assigned,
          // TODO: total costs
        },
      },
      onCompleted(data) {
        console.log(data);
        onClose();
      },
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      color="neutral"
      variant="soft"
      slotProps={{
        content: {
          sx: {
            // bgcolor: "rgba(0, 0, 0, 0.5)",
            // p: { md: 3, sm: 0 },
            // boxShadow: "none",
          },
        },
        backdrop: {
          sx: {
            backdropFilter: "none", // Disable blur
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Optional: semi-transparent background
          },
        },
      }}
    >
      <DialogTitle>
        <Typography>{t("Agencies Quotes")}</Typography>
        <InspectionRef
          inspectionRef={inspectionRef}
          teamSlug={teamCtx.team.slug}
        />
      </DialogTitle>
      <ModalClose />
      <Divider />
      <Sheet
        sx={{
          borderRadius: "md",
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
          overflow: "auto",
        }}
      >
        {inspection?.inspection_by_ref?.quotes.map((quote, i) => (
          <Card key={i} size="lg" variant="outlined">
            <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
              <CardContent
                orientation="horizontal"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <Box
                  sx={{
                    position: "relative",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      m: "-2px",
                      borderRadius: "50%",
                      background:
                        "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
                    },
                  }}
                >
                  <Avatar
                    size="sm"
                    src={
                      quote.agency.picture && quote.agency.picture.length > 0
                        ? quote.agency.picture
                        : mode === "light"
                        ? "/images/qcsku_light.jpg"
                        : "/images/qcsku_dark.jpg"
                    }
                  />
                </Box>
                <Typography sx={{ fontWeight: "lg", flex: 1 }}>
                  {quote.agency.name}
                </Typography>
                <Typography>
                  <IconButton variant="outlined">
                    <ChatBubbleOutlineOutlined />
                  </IconButton>
                </Typography>
              </CardContent>
              <Divider inset="context" />
            </CardOverflow>

            <CardContent>
              <List size="sm">
                <ListItem>
                  <ListItemContent>
                    <DateTime value={quote.serviceDateStart} /> -{" "}
                    <DateTime value={quote.serviceDateEnd} />
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent>
                    {t("{manDays} Man Day(s)", { manDays: quote.manDays })}
                  </ListItemContent>
                </ListItem>
                {quote.notes && (
                  <ListItem>
                    <ListItemContent>
                      <Sheet
                        variant="outlined"
                        sx={{
                          bgcolor: "background.level1", // Light background for the comment area
                          borderRadius: "md", // Rounded corners
                          p: 1.5, // Padding for the comment
                          border: "1px solid",
                          borderColor: "divider", // Subtle border for definition
                          color: "text.secondary", // Softer text color
                          boxShadow: "inset 0 0px 1px rgba(0, 0, 0, 0.1)", // Inset shadow
                        }}
                      >
                        <div
                          className="quill-content"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(quote.notes),
                          }}
                        />
                      </Sheet>
                    </ListItemContent>
                  </ListItem>
                )}
              </List>
            </CardContent>

            <CardActions>
              <Typography level="title-lg" sx={{ mr: "auto" }}>
                <Money value={quote.totalCosts!} currency={quote.currency} />
                <Typography textColor="text.tertiary" sx={{ fontSize: "sm" }}>
                  {" "}
                  {t("Total")}
                </Typography>
              </Typography>
              <Button
                onClick={() =>
                  handleAcceptQuote({ ...quote, agencyId: quote.agency.id })
                }
                endDecorator={<Check />}
                variant="soft"
              >
                {t("Accept")}
              </Button>
            </CardActions>
          </Card>
        ))}
      </Sheet>
    </Drawer>
  );
}
