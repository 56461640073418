import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton, { MenuButtonProps } from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import { useTranslation, useLang } from "../../i18n";
import { AspectRatio } from "@mui/joy";

const languages = [
  {
    primary: "Chinese",
    secondary: "Simplified",
    lang: "zh-CN",
  },
  {
    primary: "Chinese",
    secondary: "Traditional",
    lang: "zh-TW",
  },
  {
    primary: "English",
    secondary: "American",
    lang: "en-US",
  },
  {
    primary: "Russian",
    secondary: null,
    lang: "ru-RU",
  },
  {
    primary: "Spanish",
    secondary: null,
    lang: "es-MX",
  },
  {
    primary: "Japanese",
    secondary: null,
    lang: "ja-JP",
  },
];

export default function LanguageToggle(props: MenuButtonProps) {
  const { onClick, ...other } = props;
  const t = useTranslation();
  const [lang, setLang] = useLang();

  return (
    <Dropdown>
      <MenuButton
        variant="soft"
        size="sm"
        sx={{
          maxWidth: "32px",
          maxHeight: "32px",
          borderRadius: "50%",
        }}
        {...other}
      >
        {/* <TranslateIcon /> */}
        <AspectRatio
          ratio="1"
          sx={{
            minWidth: 24,
            borderRadius: (other.sx as any["borderRadius"]) ?? "50%",
          }}
        >
          <img
            loading="lazy"
            srcSet={`https://flagcdn.com/w40/${lang
              .toLowerCase()
              .slice(3)}.png 2x`}
            src={`https://flagcdn.com/w20/${lang.toLowerCase().slice(3)}.png`}
            alt={lang}
          />
        </AspectRatio>
      </MenuButton>
      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: "99999",
          p: 1,
          gap: 1,
          "--ListItem-radius": "var(--joy-radius-sm)",
        }}
      >
        {languages.map((option) => (
          <MenuItem
            key={option.lang}
            selected={lang === option.lang}
            onClick={() => {
              setLang(option.lang);
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                loading="lazy"
                srcSet={`https://flagcdn.com/w40/${option.lang
                  .toLowerCase()
                  .slice(3)}.png 2x`}
                src={`https://flagcdn.com/w20/${option.lang
                  .toLowerCase()
                  .slice(3)}.png`}
                alt={option.lang}
              />
              <Box sx={{ ml: 1.5 }}>
                <Typography level="title-sm" textColor="text.primary">
                  {t(option.primary)}
                </Typography>
                {option.secondary && (
                  <Typography level="body-xs" textColor="text.tertiary">
                    {t(option.secondary)}
                  </Typography>
                )}
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}
