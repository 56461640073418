import React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import MemoryIcon from "@mui/icons-material/MemoryRounded";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLongRounded";
import Layout from "../../components/Layout";
import Navigation from "../../components/Navigation";
import {
  Link,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { useTeamInfoQuery } from "../../__generated__/types-and-hooks";
import {
  Avatar,
  Box,
  CircularProgress,
  DialogTitle,
  Drawer,
  IconButton,
  ModalClose,
  Typography,
  useColorScheme,
} from "@mui/joy";
import { useTranslation } from "../../i18n";
import LanguageToggle from "../../components/header/LanguageToggle";
import ColorSchemeToggle from "../../components/header/ColorSchemeToggle";
import ProfileDropDown from "../../components/header/ProfileDropDown";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PageNotFound from "../PageNotFound";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
// import HomeIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

export interface TeamContext {
  team: Exclude<
    Exclude<
      ReturnType<typeof useTeamInfoQuery>["data"],
      undefined
    >["team_by_slug"],
    undefined | null
  >;
}

export function useTeam() {
  return useOutletContext<TeamContext>();
}

export default function TeamLayout() {
  const t = useTranslation();
  const { mode } = useColorScheme();
  const { teamSlug } = useParams();
  const { pathname } = useLocation();
  const [, section] = pathname.split("/").slice(1);
  const teamInfoQuery = useTeamInfoQuery({ variables: { slug: teamSlug! } });
  const [open, setOpen] = React.useState(false);

  const team = teamInfoQuery.data?.team_by_slug;
  if (!teamInfoQuery.loading && !team) {
    return <PageNotFound />;
  }

  return (
    <>
      <Stack
        id="tab-bar"
        direction="row"
        justifyContent="space-around"
        spacing={1}
        sx={{
          display: { xs: "flex", sm: "none" },
          zIndex: "999",
          bottom: 0,
          position: "fixed",
          width: "100dvw",
          p: 1,
          // backgroundColor: "background.body",
          backdropFilter: "blur(10px)",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        {team?.kind === "AGENCY" && (
          <Button
            variant={!section || section === "inquiries" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/inquiries`}
            size="sm"
            startDecorator={<PublicOutlinedIcon fontSize="small" />}
            sx={{ width: "100%", flexDirection: "column", "--Button-gap": 0 }}
          >
            {t("Inquiries")}
          </Button>
        )}

        <Button
          variant={!section || section === "inspections" ? "solid" : "plain"}
          color="neutral"
          component={Link}
          to={`/${teamSlug}/inspections`}
          size="sm"
          startDecorator={<FactCheckOutlinedIcon fontSize="small" />}
          sx={{
            fontSize: 10,
            width: "100%",
            flexDirection: "column",
            "--Button-gap": 0,
          }}
        >
          {t("Inspections")}
        </Button>

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "sku" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/sku`}
            size="sm"
            startDecorator={<MemoryIcon fontSize="small" />}
            sx={{
              fontSize: 10,
              width: "100%",
              flexDirection: "column",
              "--Button-gap": 0,
            }}
          >
            {t("SKUs")}
          </Button>
        )}

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "purchase-orders" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/purchase-orders`}
            size="sm"
            startDecorator={<ReceiptLongIcon fontSize="small" />}
            sx={{
              fontSize: 10,
              width: "100%",
              flexDirection: "column",
              "--Button-gap": 0,
            }}
          >
            {t("POs")}
          </Button>
        )}

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "suppliers" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/suppliers`}
            size="sm"
            startDecorator={<FactoryOutlinedIcon fontSize="small" />}
            sx={{
              fontSize: 10,
              width: "100%",
              flexDirection: "column",
              "--Button-gap": 0,
            }}
          >
            {t("Suppliers")}
          </Button>
        )}

        {team?.kind === "BRAND" && (
          <Button
            variant={section === "contacts" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/contacts`}
            size="sm"
            startDecorator={<ContactPageOutlinedIcon fontSize="small" />}
            sx={{
              fontSize: 10,
              width: "100%",
              flexDirection: "column",
              "--Button-gap": 0,
            }}
          >
            {t("Contacts")}
          </Button>
        )}

        {/* {team?.kind === "AGENCY" && (
          <Button
            variant={section === "messaging" ? "solid" : "plain"}
            color="neutral"
            component={Link}
            to={`/${teamSlug}/chat`}
            size="sm"
            startDecorator={<SupportAgentIcon fontSize="small" />}
            sx={{ width: "100%", flexDirection: "column", "--Button-gap": 0 }}
          >
            {t("Messaging")}
          </Button>
        )} */}
      </Stack>
      <Layout.Root>
        <Layout.Header
          sx={{
            bgcolor:
              team?.kind === "AGENCY" ? "danger.softBg" : "primary.softBg",
          }}
        >
          {/* <IconButton
            sx={{ display: { xs: "none", sm: "flex" } }}
            variant="soft"
            component={Link}
            to={"/"}
          >
            <HomeIcon />
          </IconButton> */}
          <Box
            sx={{
              ml: 1,
              display: "flex",
              flexGrow: 1,
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ display: { xs: "none", sm: "flex" } }}
            >
              <Box
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    m: "-2px",
                    borderRadius: "50%",
                    background:
                      "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
                  },
                }}
              >
                <Avatar
                  size="sm"
                  src={
                    teamInfoQuery.data?.team_by_slug?.picture
                      ? process.env.REACT_APP_API_URL +
                        "/" +
                        teamInfoQuery.data?.team_by_slug?.picture
                      : mode === "light"
                      ? "/images/qcsku_light.jpg"
                      : "/images/qcsku_dark.jpg"
                  }
                />
              </Box>
              <Typography sx={{ fontWeight: "lg" }}>
                {teamInfoQuery.data?.team_by_slug?.name}
              </Typography>
            </Stack>
            <Box sx={{ display: { xs: "inline-flex", sm: "none" } }}>
              <IconButton
                variant="plain"
                color="neutral"
                onClick={() => setOpen(true)}
              >
                <MenuRoundedIcon />
              </IconButton>

              <Typography sx={{ lineHeight: "40px" }}>
                {teamInfoQuery.data?.team_by_slug?.name}
              </Typography>
              <Drawer
                sx={{ display: { xs: "inline-flex", sm: "none" } }}
                open={open}
                onClose={() => setOpen(false)}
              >
                <ModalClose />
                <DialogTitle>
                  {teamInfoQuery.data?.team_by_slug?.name}
                </DialogTitle>
                <Box sx={{ px: 1 }}>
                  {team && (
                    <Navigation teamKind={team.kind as "BRAND" | "AGENCY"} />
                  )}
                </Box>
              </Drawer>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1.5,
                alignItems: "center",
              }}
            >
              {/* <SearchBar />
              <NotificationsDropDown /> */}
              <LanguageToggle />
              <ColorSchemeToggle />
              <ProfileDropDown />
            </Box>
          </Box>
        </Layout.Header>
        <Layout.SideNav>
          {team && <Navigation teamKind={team.kind as "BRAND" | "AGENCY"} />}
        </Layout.SideNav>
        <Layout.Main sx={{ mb: 10, overflow: "auto" }}>
          {teamInfoQuery.loading && <CircularProgress size="lg" />}
          {teamInfoQuery.data?.team_by_slug && (
            <Outlet
              context={
                {
                  team: teamInfoQuery.data?.team_by_slug,
                } satisfies TeamContext & any
              }
            />
          )}
        </Layout.Main>
      </Layout.Root>
    </>
  );
}
