import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import { Link, useLocation } from "react-router-dom";
import Tooltip from "@mui/joy/Tooltip";
// import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useTranslation } from "../../i18n";
import { ComponentType } from "react";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { useMyTeamsQuery } from "../../__generated__/types-and-hooks";
import { Avatar, Box, Divider, useColorScheme } from "@mui/joy";

export interface NavItemProps {
  selected: boolean;
  to: string;
  title: string;
  icon: ComponentType<{ fontSize?: any; sx?: object }>;
}

function NavItem({ title, selected, to, icon: Icon }: NavItemProps) {
  return (
    <ListItem>
      <ListItemButton component={Link} selected={selected} to={to}>
        <ListItemDecorator>
          <Tooltip
            sx={{ display: { xs: "none", sm: "block", lg: "none" } }}
            title={title}
            placement="right"
            size="md"
            variant="solid"
          >
            <Icon fontSize="small" />
          </Tooltip>
          <Icon
            fontSize="small"
            sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
          />
        </ListItemDecorator>
        <ListItemContent
          sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
        >
          {title}
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}

export default function Navigation() {
  const t = useTranslation();
  const { pathname } = useLocation();
  const [, section] = pathname.split("/");
  const myTeamsQuery = useMyTeamsQuery();
  const { mode } = useColorScheme();

  return (
    <List
      size="sm"
      sx={{ "--ListItem-radius": "var(--joy-radius-sm)", "--List-gap": "4px" }}
    >
      <ListItem nested>
        <List
          aria-labelledby="nav-list-browse"
          sx={{
            "& .JoyListItemButton-root": { p: "8px" },
          }}
        >
          {myTeamsQuery.data?.user_me.teamEdges.map(({ team }) => (
            <NavItem
              title={team.name}
              selected={section === team.slug}
              to={"/" + team.slug}
              icon={(props) => (
                <Box
                  sx={{
                    position: "relative",
                    mr: 1,
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      m: "-1px",
                      borderRadius: "50%",
                      background:
                        "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
                    },
                    ...props.sx,
                  }}
                >
                  <Avatar
                    size="sm"
                    sx={{
                      height: 23,
                      width: 23,
                    }}
                    src={
                      team?.picture
                        ? process.env.REACT_APP_API_URL + "/" + team?.picture
                        : mode === "light"
                        ? "/images/qcsku_light.jpg"
                        : "/images/qcsku_dark.jpg"
                    }
                  />
                </Box>
              )}
            />
          ))}
          {(myTeamsQuery.data?.user_me.teamEdges ?? []).length > 0 && (
            <Divider />
          )}
          <NavItem
            title={t("User Profile")}
            selected={!section}
            to={"/"}
            icon={(props) => <AccountBoxOutlinedIcon {...props} />}
          />
          <NavItem
            title={t("QC Network")}
            selected={section === "network"}
            to={"/network"}
            icon={(props) => <LanguageOutlinedIcon {...props} />}
          />
        </List>
      </ListItem>
    </List>
  );
}
