import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import {
  useContactUpsertMutation,
  useTableViewContactsQuery,
} from "../../../__generated__/types-and-hooks";
import { AspectRatio, Button } from "@mui/joy";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import TableView from "./TableView";
import { useCallback } from "react";

export default function Contacts() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const contactsListQuery = useTableViewContactsQuery({
    variables: { teamId: teamCtx.team.id },
  });
  const [contactUpsertMutation] = useContactUpsertMutation({});
  const handleCreateContact = useCallback(() => {
    contactUpsertMutation({
      variables: {
        contact: {
          teamId: teamCtx.team.id,
        },
      },
      onCompleted() {
        contactsListQuery.refetch();
      },
    });
  }, [contactUpsertMutation, teamCtx, contactsListQuery]);

  const contacts =
    contactsListQuery.data && contactsListQuery.data.contact_list?.contacts;

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Contacts")}
        </Typography>
        <Button
          startDecorator={<AddIcon />}
          variant="solid"
          onClick={handleCreateContact}
        >
          {t("Contact")}
        </Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {contactsListQuery.loading && <div>Loading...</div>}
        {contacts && contacts.length === 0 && (
          <>
            <Typography color="neutral" level="body-md">
              {t(
                "Manage all your contacts in one place to streamline your supply chain operations. Add contacts manually or connect with integrations like Anvyl or NetSuite."
              )}
              <br />
              <Link to={`/${teamCtx.team.slug}/settings/integrations`}>
                {t("Settings/Integrations")}
              </Link>
            </Typography>
            <AspectRatio
              variant="plain"
              ratio={1}
              objectFit="contain"
              maxHeight={"50vh"}
              sx={{ bgcolor: "transparent" }}
            >
              <img
                alt="No contacts yet"
                src="/images/contacts_empty_state.png"
              />
            </AspectRatio>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Button
                startDecorator={<AddIcon />}
                sx={{ mb: 2 }}
                variant="outlined"
                onClick={handleCreateContact}
              >
                {t("Add New Contact")}
              </Button>
            </Box>
          </>
        )}
        {contacts && contacts.length > 0 && <TableView contacts={contacts} />}
      </Box>
    </>
  );
}
