import { AspectRatio, Box } from "@mui/joy";
import { Link } from "react-router-dom";

export const ErrorBoundary = ({ error }: any) => {
  return (
    <Link to="/">
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AspectRatio
          variant="plain"
          ratio={1}
          objectFit="contain"
          maxHeight={"80vh"}
          sx={{ bgcolor: "transparent" }}
        >
          <img alt="Page not found" src="/images/page_not_found.png" />
        </AspectRatio>
      </Box>
    </Link>
  );
};
