import Button from "@mui/joy/Button";
import {
  Box,
  Chip,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import CardOverflow from "@mui/joy/CardOverflow";
import { Stack } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "../../../i18n";
import {
  TeamKind,
  useTeamCreateMutation,
  useUserMeQuery,
} from "../../../__generated__/types-and-hooks";
import { QCSKULogo } from "../../../components/QCSKULogo";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Subject,
  switchMap,
} from "rxjs";
import { computed } from "../../../decorators/computed";
import { XTextInput } from "../../../x-components/XTextInput";

export class CreateTeamVM {
  public slug$: Subject<string> = new BehaviorSubject("");
  public name$: Subject<string> = new BehaviorSubject("");

  constructor(private t: ReturnType<typeof useTranslation>) {
    this.name$.subscribe((name) => {
      this.slug$.next(
        name
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll(".", "_")
          .replaceAll(/[^a-z0-9_-]/gi, "")
      );
    });
  }

  @computed
  get name$error$() {
    return this.name$.pipe(
      map((name) => {
        if (name === null || name.length === 0) {
          return this.t("Please enter a name.");
        }
        return null;
      })
    );
  }

  @computed
  get slug$error$() {
    return this.slug$.pipe(
      switchMap(async (slug) => {
        if (slug === null || slug.length === 0) {
          return this.t(
            "Please choose a unique identifier for your Profile URL."
          );
        }

        if (slug.length < 5 || slug.length > 32) {
          return this.t(
            "The unique identifier must be between 5 and 32 characters long."
          );
        }

        if (!/^[a-z0-9._-]+$/.test(slug)) {
          return this.t(
            "The unique identifier can only include lowercase letters (a-z), numbers (0-9), underscores (_), dots (.), or dashes (-)."
          );
        }

        const exists = await this.slugExists(slug);
        if (exists) {
          return this.t("Please choose another user Profile URL.");
        }

        return null;
      }),
      switchMap((validationMessage) => Promise.resolve(validationMessage))
    );
  }

  async slugExists(slug: string) {
    const res = await fetch(
      process.env.REACT_APP_API_URL +
        "/auth/profile/" +
        encodeURIComponent(slug),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { exists } = await res.json();
    return exists;
  }

  @computed
  get json$() {
    return combineLatest({
      name: this.name$,
      slug: this.slug$,
    });
  }
}
export interface CreateTeamProps {
  kind: TeamKind;
}

export default function CreateTeam({ kind }: CreateTeamProps) {
  const navigate = useNavigate();
  const t = useTranslation();
  const vm = useMemo(() => new CreateTeamVM(t), [t]);
  const [showError, setShowError] = useState(false);
  const userMeQuery = useUserMeQuery();

  useEffect(() => {
    const sub = vm.json$.subscribe(({ name }) => {
      if (name.length > 0) {
        setShowError(true);
      }
    });

    return () => sub.unsubscribe();
  }, [vm]);

  const [teamCreateMutation, { loading }] = useTeamCreateMutation();

  const handleCreate = useCallback(async () => {
    firstValueFrom(vm.json$).then(async (data) => {
      await teamCreateMutation({
        variables: {
          slug: data.slug,
          name: data.name,
          kind: kind,
          contacts: [],
        },
        onCompleted() {
          userMeQuery.refetch();
          navigate("..");
        },
      });
    });
  }, [teamCreateMutation, vm, kind, navigate, userMeQuery]);

  return (
    <Modal open onClose={() => navigate("..")}>
      <ModalDialog size="lg" variant="outlined">
        <DialogTitle>
          {kind === TeamKind.Brand && t("Create Brand")}
          {kind === TeamKind.Agency && t("Create Agency")}
          <ModalClose />
        </DialogTitle>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            "--icon-size": "100px",
            bgcolor: "background.surface",
          }}
        >
          <CardOverflow variant="soft">
            <QCSKULogo />
          </CardOverflow>
          <form>
            <Stack gap={2} sx={{ mt: "calc(var(--icon-size) / 2)" }}>
              <XTextInput
                autoFocus
                required
                label={t("Company Name")}
                size="sm"
                placeholder={t("ABC")}
                val$={vm.name$}
                error$={vm.name$error$}
                showError={showError}
              />
              <XTextInput
                required
                label={t("A unique identifier for your team.")}
                size="sm"
                placeholder={t("e.g., company-co")}
                val$={vm.slug$}
                error$={vm.slug$error$}
                showError={showError}
                startDecorator={
                  <Chip sx={{ borderRadius: 4 }} color="primary">
                    https://app.qcsku.com/
                  </Chip>
                }
              />
              <Button
                sx={{ mt: 4 }}
                loading={loading}
                variant={"solid"}
                color={"primary"}
                onClick={handleCreate}
                type="submit"
              >
                {t("Create")}
              </Button>
            </Stack>
          </form>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
