import { Box, Button, Typography } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "../../../i18n";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserMeQuery } from "../../../__generated__/types-and-hooks";
import TeamMiniCard from "./TeamMiniCard";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";

export default function Teams() {
  const t = useTranslation();
  const navigate = useNavigate();
  const userMeQuery = useUserMeQuery();
  const user = userMeQuery.data?.user_me;

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }} gap={2}>
        <Typography sx={{ flex: 1 }} level="h4">
          {t("Organizations")}
        </Typography>
        <Button
          startDecorator={<AddIcon />}
          variant="outlined"
          onClick={() => navigate("./create-brand")}
        >
          {t("Brand")}
        </Button>
        <Button
          startDecorator={<AddIcon />}
          variant="outlined"
          onClick={() => navigate("./create-agency")}
        >
          {t("Agency")}
        </Button>
        <Button
          startDecorator={<GroupOutlinedIcon />}
          variant="solid"
          onClick={() => navigate("./join")}
        >
          {t("Join")}
        </Button>
      </Box>
      <Box sx={{ m: 2 }}>
        {/* <Typography
          level="title-md"
          endDecorator={
            <IconButton
              variant="soft"
              color="primary"
              size="sm"
              onClick={() => navigate("./get-started")}
            >
              <GroupsOutlinedIcon />
            </IconButton>
          }
        >
          {t("My Brands & Agencies")}
        </Typography> */}

        {/* Teams Section */}
        {user?.teamEdges.length === 0 && (
          <Typography level="body-sm">
            {t("You are not a member of any brands or agencies yet.")}
          </Typography>
        )}
        {(user?.teamEdges.length ?? 0) > 0 && (
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns:
                "repeat(auto-fill, minmax(min(100%, 200px), 1fr))",
              gap: 2,
              marginBottom: 3,
            }}
          >
            {user?.teamEdges.map((teamEdge) => (
              <TeamMiniCard
                key={teamEdge.team.id}
                name={teamEdge.team.name}
                slug={teamEdge.team.slug}
                roles={teamEdge.roles}
              />
            ))}
          </Box>
        )}
      </Box>
      {/* {(user?.teamJoinRequests.length ?? 0) > 0 && (
        <Box sx={{ m: 2 }}>
          <Typography level="title-sm">
            {t("Active requests to join")}
          </Typography>

          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns:
                "repeat(auto-fill, minmax(min(100%, 200px), 1fr))",
              gap: 2,
              marginBottom: 3,
            }}
          >
            {user?.teamJoinRequests.map((teamEdge) => (
              <TeamMiniCard
                key={teamEdge.team.id}
                name={teamEdge.team.name}
                slug={teamEdge.team.slug}
                description={teamEdge.team.description}
                joinStatus={teamEdge.status}
              />
            ))}
          </Box>
        </Box>
      )} */}

      <Outlet />
    </>
  );
}
