import { Typography, TypographyProps } from "@mui/joy";
import { useLang } from "../i18n";

const formatValue = (number: number, currency: string, locale: string) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number || 0);
};

export interface MoneyProps extends TypographyProps {
  value: number;
  currency: string;
}

export default function Money({ value, currency, ...props }: MoneyProps) {
  const [lang] = useLang();

  const formatted = formatValue(value, currency, lang);
  return <Typography {...props}>{formatted}</Typography>;
}
