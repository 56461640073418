import React, { useCallback, useRef } from "react";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { Input } from "@mui/joy";

const EditArrayCell = ({ id, value, field, api }: GridRenderEditCellParams) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const handleChanges = useCallback(() => {
    const value = ref.current?.value;
    if (value) {
      api.setEditCellValue({ id, field, value: value.split(",") }, true); // Commit changes
      api.stopCellEditMode({ id, field }); // Exit edit mode
    }
  }, [api, field, id, ref]);

  return (
    <Input
      slotProps={{
        input: { ref },
      }}
      autoFocus
      type="text"
      defaultValue={value.join(",")}
      onBlur={handleChanges}
      onKeyDown={(e) => e.key === "Enter" && handleChanges()}
    />
  );
};

export default EditArrayCell;
