import { Avatar, Box, Typography, useColorScheme } from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/joy/Link";

export interface TeamMiniCardProps {
  name: string;
  slug: string;
  picture?: string | null;
}

export default function TeamLinkButton({
  name,
  slug,
  picture,
}: TeamMiniCardProps) {
  const { mode } = useColorScheme();

  return (
    <Link
      variant="soft"
      component={RouterLink}
      to={`/${slug}`}
      sx={{ p: 1, m: 1, borderRadius: 8 }}
    >
      <Box
        sx={{
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            m: "-2px",
            borderRadius: "50%",
            background:
              "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
          },
        }}
      >
        <Avatar
          size="sm"
          src={
            picture
              ? process.env.REACT_APP_API_URL + "/" + picture
              : mode === "light"
              ? "/images/qcsku_light.jpg"
              : "/images/qcsku_dark.jpg"
          }
        />
      </Box>
      <Typography noWrap sx={{ ml: 1, fontWeight: "lg", flex: 1 }}>
        {name}
      </Typography>
    </Link>
  );
}
