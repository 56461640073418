import * as React from "react";
import Button from "@mui/joy/Button";
import { XTextInput } from "../../x-components/XTextInput";
import EmailIcon from "@mui/icons-material/Email";
import { useRxVal } from "../../hooks/useRx";
import { Alert } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { computed } from "../../decorators/computed";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Subject,
} from "rxjs";
import { accessTokenManager } from "../../models/AccessTokenManager";

export class ForgotPasswordVM {
  public email$: Subject<string> = new BehaviorSubject("");

  public status$ = new BehaviorSubject<{
    status: "ready" | "pending" | "error" | "submitted";
    error?: string;
  }>({ status: "ready" });

  @computed
  get email$error$() {
    return this.email$.pipe(
      map((email) => {
        if (email === null || email.length === 0) {
          return "Email is required.";
        }
        return null;
      })
    );
  }

  @computed
  get json$() {
    return combineLatest({
      email: this.email$,
    });
  }

  async resetPassword(): Promise<boolean> {
    try {
      const { email } = await firstValueFrom(this.json$);
      this.status$.next({ status: "pending" });
      await accessTokenManager.forgotPassword(email);
      this.status$.next({ status: "submitted" });
      return true;
    } catch (e: any) {
      console.error(e.message);
      this.status$.next({ status: "error", error: e.message });
    }
    return false;
  }
}

export interface ForgotPasswordFormProps {
  vm: ForgotPasswordVM;
}

export default function ForgotPasswordForm({ vm }: ForgotPasswordFormProps) {
  const navagate = useNavigate();
  const [showError, setShowError] = React.useState<boolean>(false);
  const status = useRxVal(vm.status$);
  const t = useTranslation();

  const onSubmit = React.useCallback(
    async (e: any) => {
      e.preventDefault();

      setShowError(true);
      if (await vm.resetPassword()) {
        navagate("/auth/sign-in");
      }
    },
    [vm, navagate]
  );

  return (
    <form>
      <XTextInput
        aria-label="Email"
        autoFocus
        required
        label={t("Email")}
        size="sm"
        placeholder={t("Enter your email address")}
        type="email"
        startDecorator={<EmailIcon />}
        val$={vm.email$}
        error$={vm.email$error$}
        showError={showError}
      />

      <Button onClick={onSubmit} type="submit" fullWidth>
        {t("Reset Password")}
      </Button>
      {status.status === "submitted" && (
        <Alert color="success">
          {t(
            "We've sent you an email with instructions to reset your password. Please check your inbox."
          )}
        </Alert>
      )}
      {status.error === "error" && (
        <Alert color="danger">
          {status.error ?? "Please enter a valid email address."}
        </Alert>
      )}
    </form>
  );
}
