import {
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { useTranslation } from "../../../i18n";
import {
  InspectionOpeningFragment,
  useInspectionQuoteCreateMutation,
} from "../../../__generated__/types-and-hooks";
import { BehaviorSubject, combineLatest, firstValueFrom, Subject } from "rxjs";
import { addDays } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import { XDatePicker } from "../../../x-components/XDatePicker";
import { XTextArea } from "../../../x-components/XTextArea";
import { XMoneyInput } from "../../../x-components/XMoneyInput";
import { XNumericInput } from "../../../x-components/XNumericInput";
import { computed } from "../../../decorators/computed";
import { useTeam } from "../TeamLayout";

class QuoteVM {
  public startDate$: Subject<Date | null>;
  public endDate$: Subject<Date | null>;
  public manDays$: Subject<number>;
  public rate$: Subject<number>;
  public currency$: Subject<string>;
  public total$: Subject<number>;
  public notes$: Subject<string | null>;

  constructor(serviceDate: Date | null) {
    this.startDate$ = new BehaviorSubject(serviceDate);
    this.endDate$ = new BehaviorSubject(
      serviceDate ? addDays(serviceDate, 1) : null
    );
    this.manDays$ = new BehaviorSubject(1);
    this.rate$ = new BehaviorSubject(150);
    this.currency$ = new BehaviorSubject("USD");
    this.total$ = new BehaviorSubject(150);
    this.notes$ = new BehaviorSubject<string | null>(null);
  }

  @computed
  get json$() {
    return combineLatest({
      startDate: this.startDate$,
      endDate: this.endDate$,
      manDays: this.manDays$,
      rate: this.rate$,
      currency: this.currency$,
      total: this.total$,
      notes: this.notes$,
    });
  }

  async json() {
    return firstValueFrom(this.json$);
  }
}

export interface QuoteModalProps {
  inspection: InspectionOpeningFragment | null;
  onClose: () => void;
}

export default function QuoteModal({ inspection, onClose }: QuoteModalProps) {
  const teamCtx = useTeam();
  const vm = useMemo(() => new QuoteVM(inspection?.serviceDate), [inspection]);
  const t = useTranslation();
  const [inspectionQuoteCreate] = useInspectionQuoteCreateMutation();
  const handleSubmitQuote = useCallback(async () => {
    const json = await vm.json();
    inspectionQuoteCreate({
      variables: {
        quote: {
          agencyId: teamCtx.team.id!,
          inspectionId: inspection?.id!,
          serviceDateStart: json.startDate,
          serviceDateEnd: json.endDate,
          manDays: json.manDays,
          totalCosts: json.total,
          currency: json.currency,
          notes: json.notes,
        },
      },
    });
    onClose();
  }, [vm, onClose, inspection, inspectionQuoteCreate, teamCtx]);

  useEffect(() => {
    if (inspection?.quotes && inspection?.quotes.length > 0) {
      const quote = inspection?.quotes[inspection?.quotes.length - 1];
      if (quote.currency) vm.currency$.next(quote.currency);
      if (quote.totalCosts) vm.total$.next(quote.totalCosts);
      if (quote.manDays) vm.manDays$.next(quote.manDays);
      if (quote.notes) vm.notes$.next(quote.notes);
    }
  }, [inspection, vm]);

  return (
    <Modal open={!!inspection} onClose={onClose}>
      <ModalDialog size="lg">
        <ModalClose onClick={onClose} />
        <DialogTitle>
          {t("Inspection #{ref}", {
            ref: inspection?.ref.toUpperCase() ?? "",
          })}
        </DialogTitle>
        {inspection && (
          <DialogContent>
            <Stack gap={2}>
              <Stack direction="row" gap={2}>
                <XDatePicker
                  label={t("Service Start Date")}
                  val$={vm.startDate$}
                />
                <XDatePicker label={t("Service End Date")} val$={vm.endDate$} />
              </Stack>
              <XNumericInput
                label={t("Estimated Man Days")}
                val$={vm.manDays$}
              />
              <XMoneyInput
                label={t("Estimated Total Costs")}
                val$={vm.total$}
                currency$={vm.currency$}
              />
              <XTextArea
                placeholder={t("Additional comments or notes.")}
                label={t("Notes")}
                val$={vm.notes$}
                minRows={3}
              />
              <Stack gap={4} sx={{ mt: 2 }}>
                <Button type="submit" fullWidth onClick={handleSubmitQuote}>
                  {t("Submit Quote")}
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        )}
      </ModalDialog>
    </Modal>
  );
}
