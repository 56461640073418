import { jwtDecode } from "jwt-decode";

const NETWORK_LATENCY_MS = 100;

type AccessToken = {
  accessToken: string;
  jwt: {
    email: string;
    id: number;
    exp: number;
    iat: number;
    iss: "qcsku.com";
  };
};

type SignUpData = {
  email: string;
  password: string;
  countryCode: string;
  firstName: string;
  lastName: string;
  slug: string;
};

export class AccessTokenManager {
  private controller = new AbortController();
  private token: AccessToken | null = null;

  public async signIn(email: string, password: string, persistent: boolean) {
    localStorage.removeItem("access_token");
    const { accessToken } = await this.fetchAccessToken(email, password);
    await this.save(accessToken, persistent);
  }

  public async signUp(data: SignUpData) {
    const { accessToken } = await this.register(data);
    await this.save(accessToken, true);
  }

  public async forgotPassword(email: string) {
    await this.resetPassword(email);
  }

  private save(accessToken: string, persistent: boolean) {
    this.token = {
      accessToken,
      jwt: jwtDecode(accessToken),
    };
    if (persistent) {
      localStorage.setItem("access_token", accessToken);
    }
  }

  retrieve() {
    if (this.token === null) {
      const accessToken = localStorage.getItem("access_token");
      if (accessToken) {
        this.token = {
          accessToken,
          jwt: jwtDecode(accessToken),
        };
      }
    }

    if (
      this.token === null ||
      this.token.jwt.exp * 1000 < Date.now() + NETWORK_LATENCY_MS
    ) {
      window.location.href = "/auth/sign-in";
    }

    return this.token?.accessToken;
  }

  public async fetchAccessToken(email: string, password: string) {
    const res = await fetch(process.env.REACT_APP_API_URL + "/auth/sign-in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      signal: this.controller.signal,
    });

    const json = await res.json();
    if (res.status === 400) {
      throw new Error(json.message);
    }

    if (res.status === 401) {
      throw new Error("Invalid email or password.");
    }

    return json;
  }

  private async fetchRefreshToken() {
    const res = await fetch(
      process.env.REACT_APP_API_URL + "/auth/refresh-token",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        signal: this.controller.signal,
      }
    );

    const json = await res.json();
    if (res.status === 400) {
      throw new Error(json.message);
    }

    if (res.status === 401) {
      throw new Error("Invalid email or password.");
    }

    return json;
  }

  private async register(data: SignUpData) {
    const res = await fetch(process.env.REACT_APP_API_URL + "/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      signal: this.controller.signal,
    });

    const json = await res.json();
    if (!res.ok) {
      if (json.message) {
        if (/^\[already_exists\]/.test(json.message))
          throw new Error(
            "An account with this email address already exists. Please use a different email or sign in if you already have an account."
          );
        throw new Error(json.message);
      }
      throw new Error("Invalid email or password.");
    }

    return json;
  }

  public async resetPassword(email: string) {
    const res = await fetch(
      process.env.REACT_APP_API_URL + "/auth/reset-password",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
        signal: this.controller.signal,
      }
    );

    const json = await res.json();
    if (res.status === 400) {
      throw new Error(json.message);
    }

    if (res.status === 401) {
      throw new Error("Invalid email.");
    }

    return json;
  }
}

export const accessTokenManager = new AccessTokenManager();
