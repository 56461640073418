import React from "react";

export interface LogoIconProps {
  size?: "sm" | "md" | "lg";
  dark?: boolean;
}

const LogoIcon = (props: React.SVGProps<SVGSVGElement> & LogoIconProps) => {
  const color = props.dark ? "white" : "black";
  let wh = [18, 18];

  if (props.size === "md") {
    wh = [24, 24];
  }

  if (props.size === "lg") {
    wh = [32, 32];
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 24 24`}
      width={wh[0]}
      height={wh[1]}
      {...props}
    >
      <path
        fill={color}
        d="M 10.397 11.114 L 10.161 10.994 C 10.059 10.944 9.993 10.905 9.968 10.875 C 9.942 10.847 9.93 10.813 9.93 10.779 C 9.93 10.733 9.951 10.695 9.991 10.664 C 10.032 10.633 10.089 10.618 10.163 10.618 C 10.295 10.618 10.443 10.698 10.604 10.859 L 11.003 10.461 C 10.902 10.324 10.775 10.219 10.63 10.143 C 10.483 10.068 10.324 10.033 10.156 10.033 C 9.913 10.033 9.711 10.107 9.554 10.251 C 9.397 10.395 9.317 10.57 9.317 10.774 C 9.317 11.088 9.525 11.351 9.942 11.561 L 10.158 11.671 C 10.347 11.765 10.441 11.862 10.441 11.962 C 10.441 12.016 10.414 12.064 10.362 12.104 C 10.311 12.145 10.241 12.165 10.156 12.165 C 10.078 12.165 9.988 12.139 9.886 12.085 C 9.784 12.032 9.696 11.959 9.618 11.87 L 9.221 12.302 C 9.446 12.596 9.746 12.741 10.12 12.741 C 10.403 12.741 10.627 12.665 10.796 12.511 C 10.966 12.358 11.048 12.168 11.048 11.939 C 11.048 11.766 11.001 11.616 10.908 11.49 C 10.815 11.361 10.642 11.236 10.395 11.114 L 10.397 11.114 Z"
        transform="matrix(1, 0, 0, 1, 0, )"
      />
      <polygon
        fill={color}
        points="14.336 10.102 13.523 10.102 12.572 11.163 12.572 9.106 11.928 9.106 11.928 12.677 12.572 12.677 12.572 11.49 13.665 12.677 14.468 12.677 13.225 11.335 14.336 10.102"
        transform="matrix(1, 0, 0, 1, 0, 0)"
      />
      <path
        fill={color}
        d="M 16.711 11.363 C 16.711 11.607 16.697 11.765 16.671 11.839 C 16.635 11.939 16.579 12.014 16.504 12.066 C 16.429 12.117 16.336 12.142 16.23 12.142 C 16.122 12.142 16.031 12.116 15.958 12.064 C 15.885 12.012 15.832 11.939 15.8 11.845 C 15.767 11.751 15.749 11.584 15.749 11.342 L 15.749 10.102 L 15.096 10.102 L 15.096 11.172 C 15.096 11.584 15.121 11.868 15.171 12.025 C 15.242 12.252 15.365 12.428 15.539 12.554 C 15.715 12.68 15.942 12.743 16.225 12.743 C 16.487 12.743 16.699 12.69 16.869 12.583 C 17.037 12.475 17.164 12.32 17.253 12.117 C 17.324 11.952 17.358 11.646 17.358 11.195 L 17.358 10.103 L 16.711 10.103 L 16.711 11.365 L 16.711 11.363 Z"
        transform="matrix(1, 0, 0, 1, 0, 0)"
      />
      <path
        fill={color}
        d="M 20.162 19.5 C 22.075 17.342 23.093 14.719 23.093 11.73 C 23.093 4.978 17.727 0.044 11.592 0.044 C 5.456 0.044 0.059 5.07 0.059 11.638 C 0.059 18.205 5.085 23.261 11.622 23.261 C 13.596 23.261 15.445 22.861 17.049 21.968 L 18.993 24.464 L 24.018 24.464 L 20.164 19.5 L 20.162 19.5 Z M 17.51 16.077 L 15.535 13.52 L 10.479 13.52 L 12.206 15.741 C 12.073 15.753 11.937 15.761 11.795 15.761 C 9.503 15.761 7.793 14.163 7.793 11.776 C 7.793 9.389 9.567 7.824 11.745 7.824 C 13.101 7.824 14.245 8.404 15.294 9.517 L 16.844 8.033 C 15.569 6.548 13.713 5.71 11.793 5.71 C 8.468 5.71 5.63 8.114 5.63 11.776 C 5.63 15.438 8.131 17.875 11.762 17.875 C 12.48 17.875 13.105 17.795 13.675 17.635 L 14.454 18.639 C 13.591 19.01 12.575 19.225 11.617 19.225 C 7.734 19.225 4.311 16.265 4.311 11.732 C 4.311 7.198 7.579 4.084 11.587 4.084 C 15.595 4.084 18.833 7.599 18.833 11.701 C 18.833 13.274 18.37 14.786 17.506 16.079 L 17.51 16.077 Z"
        transform="matrix(1, 0, 0, 1, 0, 0)"
      />
    </svg>
  );
};

export default LogoIcon;
