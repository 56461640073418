import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  useTeamJoinMutation,
  useTeamProfileQuery,
  useUserMeQuery,
} from "../../__generated__/types-and-hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../../i18n";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import DOMPurify from "dompurify";
import UserTooltip from "../../components/tooltips/UserTooltip";
import { useCallback } from "react";

export default function TeamProfile() {
  const t = useTranslation();
  const navigate = useNavigate();
  const userMeQuery = useUserMeQuery();

  const { teamSlug } = useParams();
  const teamProfileQuery = useTeamProfileQuery({
    variables: { slug: teamSlug! },
  });
  const team = teamProfileQuery.data?.team_by_slug;

  const [teamJoinMutation] = useTeamJoinMutation();
  const handleJoinTeam = useCallback(async () => {
    if (team) {
      await teamJoinMutation({
        variables: { teamId: team.id },
        onCompleted() {
          userMeQuery.refetch();
        },
      });
    }
  }, [teamJoinMutation, team, userMeQuery]);

  const isItMyTeam = userMeQuery.data?.user_me.teamEdges.find(
    (edge) => edge.team.id === team?.id
  );

  return (
    <>
      <Box sx={{ padding: 4, maxWidth: 900, margin: "0 auto" }}>
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 0,
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: 180,
              width: "100%",
            }}
          >
            <img
              src={
                team?.background
                  ? process.env.REACT_APP_API_URL + "/" + team?.background
                  : "/images/inspections_empty_state.png"
              }
              alt="Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 8,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
          </Box>
          <Avatar
            sx={{
              position: "absolute",
              width: 160,
              height: 160,
              marginBottom: 2,
              boxShadow: "lg",
              marginTop: 0,
              top: 90,
              border: 2,
              borderColor: "var(--joy-palette-background-level1)",
            }}
            src={
              team?.picture
                ? process.env.REACT_APP_API_URL + "/" + team?.picture
                : "/images/qcsku_light.jpg"
            }
            alt={t("Company Logo")}
          />

          <Stack
            gap={1}
            sx={{
              width: "100%",
              p: 2,
              pt: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              level="h4"
              fontWeight="bold"
              endDecorator={
                team?.verified && <VerifiedOutlinedIcon color="primary" />
              }
            >
              {team?.name}
            </Typography>
            <Typography level="body-xs" sx={{ color: "text.secondary" }}>
              {team?.description}
            </Typography>
            {team && (
              <AvatarGroup>
                {team.userEdges.slice(0, 3).map(({ user }) => (
                  <Tooltip
                    key={user.id}
                    variant="outlined"
                    arrow
                    title={<UserTooltip slug={user.slug} />}
                  >
                    <Avatar
                      sx={{
                        border: 1,
                        borderColor: "var(--joy-palette-background-level1)",
                      }}
                      size="sm"
                      src={process.env.REACT_APP_API_URL + "/" + user.picture}
                    />
                  </Tooltip>
                ))}
                {team.userEdges.length > 3 && (
                  <Avatar variant="solid" size="sm">
                    +{team.userEdges.length - 3}
                  </Avatar>
                )}
              </AvatarGroup>
            )}
          </Stack>
          {!isItMyTeam && (
            <Button size="sm" variant="outlined" onClick={handleJoinTeam}>
              {t("Join")}
            </Button>
          )}
          {isItMyTeam && (
            <Button size="sm" onClick={() => navigate(`/${team?.slug}`)}>
              {t("Inspections")}
            </Button>
          )}
          {/* About Section */}
          {team && team.about && (
            <>
              <Divider />
              <Box sx={{ alignItems: "left", width: "100%", p: 3 }}>
                <Typography sx={{ marginTop: 1 }}>
                  <div
                    className="quill-content"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(team.about),
                    }}
                  />
                </Typography>
              </Box>
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
