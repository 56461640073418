import TeamLayout from "./TeamLayout";
import Inspections from "./inspections/Inspections";
import Skus from "./skus/Skus";
import Suppliers from "./suppliers/Suppliers";
import Chat from "./chats/Chat";
import Files from "./files/Files";
import { inspectionRoutes } from "./inspections/routes";
import { teamSettingsRoutes } from "./settings/teamSettingsRoutes";
import { purchaseOrdersRoutes } from "./purchase-orders/routes";
import Openings from "./openings/Openings";
import Contacts from "./contacts/Contacts";
import Contact from "./contacts/Contact";

export const teamRoutes = {
  path: "/:teamSlug",
  element: <TeamLayout />,
  children: [
    ...inspectionRoutes,
    ...purchaseOrdersRoutes,
    {
      path: "/:teamSlug",
      element: <Inspections />,
      children: [],
    },
    {
      path: "/:teamSlug/suppliers",
      element: <Suppliers />,
      children: [],
    },
    {
      path: "/:teamSlug/contacts",
      element: <Contacts />,
      children: [],
    },
    {
      path: "/:teamSlug/contacts/:uniqueId",
      element: <Contact />,
      children: [],
    },
    {
      path: "/:teamSlug/sku",
      element: <Skus />,
      children: [],
    },
    {
      path: "/:teamSlug/chat",
      element: <Chat />,
      children: [],
    },
    {
      path: "/:teamSlug/files",
      element: <Files />,
      children: [],
    },
    {
      path: "/:teamSlug/agencies",
      element: <h1>Agencies</h1>,
      children: [],
    },
    {
      path: "/:teamSlug/inquiries",
      element: <Openings />,
      children: [],
    },
    { ...teamSettingsRoutes },
  ],
};
