import Autocomplete from "@mui/joy/Autocomplete";
import AutocompleteOption from "@mui/joy/AutocompleteOption";
import AspectRatio from "@mui/joy/AspectRatio";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import { FormControl, FormHelperText, FormLabel, Typography } from "@mui/joy";
import React, { ReactNode } from "react";
import { Observable, Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";
import { useTranslation } from "../i18n";

export interface XCountry$Props {
  label?: string | ReactNode;
  val$: Subject<string>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
}

export function XCountry({
  val$,
  error$,
  showError,
  label,
  required,
  ...props
}: XCountry$Props) {
  const t = useTranslation();
  const [countries, setCountries] = React.useState<null | Array<{
    code: string;
    label: string;
    phone: string;
  }>>(null);

  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/auth/countries")
      .then((res) => res.json())
      .then((data) => setCountries(data));
  }, []);

  const val = useRxVal(val$);
  const error = useRxVal(error$);

  return (
    <FormControl required>
      <FormLabel>{t("Country")}</FormLabel>
      <input type="hidden" name="country_code" value={val} />
      {countries !== null && (
        <Autocomplete
          disableClearable
          onChange={(e, val) => {
            val$.next(val!.code);
          }}
          getOptionKey={(o) => o.code}
          name="country"
          autoHighlight
          isOptionEqualToValue={(option, value) => option.code === value.code}
          defaultValue={{
            code: "US",
            label: "United States",
            phone: "1",
          }}
          options={countries}
          renderOption={(optionProps, option) => (
            <AutocompleteOption {...optionProps}>
              <ListItemDecorator>
                <AspectRatio
                  ratio="1"
                  sx={{ minWidth: 20, borderRadius: "50%" }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    alt=""
                  />
                </AspectRatio>
              </ListItemDecorator>
              {option.label}
              <Typography component="span" textColor="text.tertiary" ml={0.5}>
                (+{option.phone})
              </Typography>
            </AutocompleteOption>
          )}
          slotProps={{
            input: {
              autoComplete: "new-password", // disable autocomplete and autofill
            },
          }}
        />
      )}
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
