import {
  Alert,
  Button,
  DialogTitle,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import {
  useApiKeysQuery,
  useCreateApiKeyMutation,
} from "../../../__generated__/types-and-hooks";
import { useTranslation } from "../../../i18n";
import { XTextInput } from "../../../x-components/XTextInput";
import { XDatePicker } from "../../../x-components/XDatePicker";
import { BehaviorSubject } from "rxjs";
import { addMonths } from "date-fns";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function CreateApiKeyModal() {
  const t = useTranslation();
  const navigate = useNavigate();
  const showError = true;
  const name$ = useMemo(() => new BehaviorSubject(""), []);
  const exp$ = useMemo(
    () => new BehaviorSubject<Date | null>(addMonths(new Date(), 3)),
    []
  );
  const apiKeysQuery = useApiKeysQuery();
  const [copied, setCopied] = useState<string | null>(null);
  const [createApiKeyMutation, { data, error }] = useCreateApiKeyMutation();
  const handleCopyToClipboard = () => {
    if (data) {
      navigator.clipboard.writeText(data.authorization_key_create.key);
      setCopied(data.authorization_key_create.key);
    }
  };

  const handleClose = useCallback(() => {
    navigate("..");
  }, [navigate]);

  const handleCreate = useCallback(() => {
    const name = name$.getValue();
    const exp = exp$.getValue();

    createApiKeyMutation({
      variables: {
        authorizationKey: {
          name,
          exp,
        },
      },
      onCompleted() {
        apiKeysQuery.refetch();
      },
    });
  }, [name$, exp$, createApiKeyMutation, apiKeysQuery]);

  return (
    <Modal open onClose={handleClose}>
      <ModalDialog size="md" variant="outlined">
        <DialogTitle>
          {!data && t("Create API Key")}
          {data && t("API Key Generated")}
          <ModalClose />
        </DialogTitle>
        {data && (
          <>
            <Typography level="body-sm">
              {t(
                "This API key is displayed only once. Save it in a secure location, as it cannot be recovered later."
              )}
            </Typography>
            <Alert
              color="success"
              endDecorator={
                <Button
                  size="sm"
                  variant={
                    copied === data.authorization_key_create.key
                      ? "solid"
                      : "plain"
                  }
                  color={
                    copied === data.authorization_key_create.key
                      ? "success"
                      : "neutral"
                  }
                  onClick={handleCopyToClipboard}
                  startDecorator={<ContentCopyIcon />}
                >
                  {copied !== data.authorization_key_create.key &&
                    t("Copy Key")}
                  {copied === data.authorization_key_create.key && t("Copied!")}
                </Button>
              }
            >
              {data.authorization_key_create.key}
            </Alert>
            <Typography level="body-sm">
              {t(
                "Use the API key below in your application by including it in the request header as shown:"
              )}
            </Typography>
            <Divider />
            <Typography level="body-sm">
              <pre>
                {JSON.stringify(
                  { authorization: data.authorization_key_create.key },
                  null,
                  "  "
                )}
              </pre>
            </Typography>
          </>
        )}
        {!data && (
          <Stack spacing={2}>
            <XTextInput
              autoFocus
              label={t("Name")}
              size="sm"
              placeholder={t("e.g., integration")}
              val$={name$}
              showError={showError}
            />
            <XDatePicker
              label={t("Expiration Date")}
              size="sm"
              val$={exp$}
              showError={showError}
            />
            <Button onClick={handleCreate} fullWidth>
              {t("Create")}
            </Button>
            {error && <Alert color="danger">{error.message}</Alert>}
          </Stack>
        )}
      </ModalDialog>
    </Modal>
  );
}
