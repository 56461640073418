import * as React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import { XTextInput } from "../../x-components/XTextInput";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import { useRxVal } from "../../hooks/useRx";
import { Alert, Chip, Link, Typography } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { computed } from "../../decorators/computed";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Subject,
  switchMap,
} from "rxjs";
import { accessTokenManager } from "../../models/AccessTokenManager";
import { XCountry } from "../../x-components/XCountry";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelIcon from "@mui/icons-material/CircleOutlined";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&_]{8,}$/;

export class SignUpVM {
  public slug$: Subject<string> = new BehaviorSubject("");
  public email$: Subject<string> = new BehaviorSubject("");
  public password$: Subject<string> = new BehaviorSubject("");
  public confirmPassword$: Subject<string> = new BehaviorSubject("");
  public firstName$: Subject<string> = new BehaviorSubject("");
  public lastName$: Subject<string> = new BehaviorSubject("");
  public countryCode$: Subject<string> = new BehaviorSubject("US");

  public status$ = new BehaviorSubject<{
    status: "ready" | "pending" | "error";
    error?: string;
  }>({ status: "ready" });

  constructor(private t: ReturnType<typeof useTranslation>) {
    this.email$.subscribe((email) => {
      if (emailRegex.test(email)) {
        this.slug$.next(email.split("@")[0]);
      }
    });
  }

  async slugExists(slug: string) {
    const res = await fetch(
      process.env.REACT_APP_API_URL +
        "/auth/profile/" +
        encodeURIComponent(slug),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { exists } = await res.json();
    return exists;
  }

  @computed
  get slug$error$() {
    return this.slug$.pipe(
      switchMap(async (slug) => {
        if (slug === null || slug.length === 0) {
          return this.t(
            "Please choose a unique identifier for your Profile URL."
          );
        }

        if (slug.length < 5 || slug.length > 32) {
          return this.t(
            "The unique identifier must be between 5 and 32 characters long."
          );
        }

        if (!/^[a-z0-9._-]+$/.test(slug)) {
          return this.t(
            "The unique identifier can only include lowercase letters (a-z), numbers (0-9), underscores (_), dots (.), or dashes (-)."
          );
        }

        const exists = await this.slugExists(slug);
        if (exists) {
          return this.t("Please choose another user Profile URL.");
        }

        return null;
      }),
      switchMap((validationMessage) => Promise.resolve(validationMessage))
    );
  }

  @computed
  get firstName$error$() {
    return this.firstName$.pipe(
      map((firstName) => {
        if (firstName === null || firstName.length === 0) {
          return this.t("Please enter your first name.");
        }
        return null;
      })
    );
  }

  @computed
  get lastName$error$() {
    return this.lastName$.pipe(
      map((lastName) => {
        if (lastName === null || lastName.length === 0) {
          return this.t("Please enter your last name.");
        }
        return null;
      })
    );
  }

  @computed
  get email$error$() {
    return this.email$.pipe(
      map((email) => {
        if (email === null || email.length === 0) {
          return this.t("Please enter your email.");
        }

        if (!emailRegex.test(email)) {
          return this.t("Please enter a valid email.");
        }
        return null;
      })
    );
  }

  @computed
  get password$error$() {
    return this.password$.pipe(
      map((password) => {
        if (password === null || password.length === 0) {
          return this.t("Please enter your password.");
        }

        if (!passwordRegex.test(password)) {
          return this.t(
            "Invalid password. Please ensure it meets all the requirements."
          );
        }
        return null;
      })
    );
  }

  @computed
  get confirmPassword$error$() {
    return combineLatest({
      confirmPassword: this.confirmPassword$,
      password: this.password$,
    }).pipe(
      map(({ password, confirmPassword }) => {
        if (confirmPassword !== password) {
          return this.t("Password doesn't match.");
        }
        return null;
      })
    );
  }

  @computed
  get json$() {
    return combineLatest({
      email: this.email$,
      password: this.password$,
      firstName: this.firstName$,
      lastName: this.lastName$,
      countryCode: this.countryCode$,
      slug: this.slug$,
    });
  }

  async signUp(): Promise<boolean> {
    try {
      const { email, password, firstName, lastName, countryCode, slug } =
        await firstValueFrom(this.json$);
      this.status$.next({ status: "pending" });

      await accessTokenManager.signUp({
        email,
        password,
        firstName,
        lastName,
        countryCode,
        slug,
      });

      this.status$.next({ status: "ready" });
      return true;
    } catch (e: any) {
      this.status$.next({ status: "error", error: e.message });
    }
    return false;
  }
}

export interface SignUpFormProps {
  vm: SignUpVM;
}

export default function SignUpForm({ vm }: SignUpFormProps) {
  const t = useTranslation();
  const navagate = useNavigate();
  const [showError, setShowError] = React.useState<boolean>(false);
  const status = useRxVal(vm.status$);
  const password = useRxVal(vm.password$);
  const passwordRules = [
    {
      label: t("At least 8 characters long."),
      test: (pw: string) => pw.length >= 8,
    },
    {
      label: t("At least one lowercase letter (a-z)."),
      test: (pw: string) => /[a-z]/.test(pw),
    },
    {
      label: t("At least one uppercase letter (A-Z)."),
      test: (pw: string) => /[A-Z]/.test(pw),
    },
    {
      label: t("At least one number (0-9)."),
      test: (pw: string) => /\d/.test(pw),
    },
  ];

  const onSubmit = React.useCallback(
    async (e: any) => {
      e.preventDefault();

      setShowError(true);
      if (await vm.signUp()) {
        navagate("/auth/next-steps");
      }
    },
    [vm, navagate]
  );

  return (
    <form>
      <Stack direction="row">
        <XTextInput
          required
          label={t("First Name")}
          size="sm"
          placeholder={t("e.g., John")}
          val$={vm.firstName$}
          error$={vm.firstName$error$}
          showError={showError}
          containerSx={{ flex: 1 }}
          sx={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <XTextInput
          required
          label={t("Last Name")}
          size="sm"
          placeholder={t("e.g., Doe")}
          val$={vm.lastName$}
          error$={vm.lastName$error$}
          showError={showError}
          containerSx={{ flex: 1 }}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      </Stack>

      <XTextInput
        required
        label={t("Email")}
        size="sm"
        placeholder={t("e.g., john.doe@example.com")}
        type="email"
        startDecorator={<EmailIcon />}
        val$={vm.email$}
        error$={vm.email$error$}
        showError={showError}
      />
      <XTextInput
        required
        label={t("Profile URL")}
        size="sm"
        placeholder={t("e.g., john-doe")}
        val$={vm.slug$}
        error$={vm.slug$error$}
        showError
        startDecorator={
          <Chip sx={{ borderRadius: 4 }} color="primary">
            https://app.qcsku.com/user/
          </Chip>
        }
      />
      <Typography level="body-xs">
        {t(
          "Choose a unique identifier for your profile URL, such as your name or nickname."
        )}
      </Typography>
      <XTextInput
        required
        label={t("Password")}
        size="sm"
        placeholder={t("password")}
        type="password"
        startDecorator={<PasswordIcon />}
        val$={vm.password$}
        error$={vm.password$error$}
        showError={showError}
      />
      <Stack>
        {passwordRules.map((rule, index) => (
          <Typography
            key={index}
            level="body-xs"
            startDecorator={
              rule.test(password) ? (
                <CheckIcon fontSize="small" color="success" />
              ) : (
                <CancelIcon fontSize="small" color="error" />
              )
            }
            color={rule.test(password) ? "success" : "neutral"}
          >
            {rule.label}
          </Typography>
        ))}
      </Stack>
      <XTextInput
        required
        label={t("Confirm Password")}
        size="sm"
        placeholder={t("confirm password")}
        type="password"
        startDecorator={<PasswordIcon />}
        val$={vm.confirmPassword$}
        error$={vm.confirmPassword$error$}
        showError={showError}
      />
      <XCountry required label={t("Country")} val$={vm.countryCode$} />
      <Typography level="body-xs">
        {t(
          "We use your country to ensure personal data is stored in compliance with local regulations."
        )}
      </Typography>

      <Stack gap={1} sx={{ mt: 2 }}>
        <Button onClick={onSubmit} type="submit" fullWidth>
          {t("Sign Up Now")}
        </Button>
        {status.status === "error" && (
          <Alert
            sx={{
              opacity: status.status === "error" ? 1 : 0,
              transition: "opacity 0.3",
            }}
            color="danger"
          >
            {status.error ?? t("Please enter a valid email and password.")}
          </Alert>
        )}
        <Typography level="body-xs">
          {t("By signing up, you agree to our Terms and Privacy Policy.")}
        </Typography>
        <Link
          level="body-xs"
          href="https://qcsku.com/terms-of-service"
          target="_blank"
        >
          {t("Terms of Service")}
        </Link>
        <Link level="body-xs" href="https://qcsku.com/privacy" target="_blank">
          {t("Privacy Policy")}
        </Link>
      </Stack>
    </form>
  );
}
