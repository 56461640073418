import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  ModalClose,
  Stack,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import { BehaviorSubject, combineLatestWith, map, Subject } from "rxjs";
import { useRxVal } from "../../../hooks/useRx";
import { UserSelect } from "../../../x-components/UserSelect";
import { TeamRolesSelect } from "../../../x-components/TeamRolesSelect";
import { computed } from "../../../decorators/computed";
import {
  TeamRole,
  useTeamAssignUserMutation,
} from "../../../__generated__/types-and-hooks";
import { useNotify } from "../../../hooks/useNotify";

export interface InviteMemberModalProps {
  open: boolean;
}

type UserBase = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
};

type UserTeamRole = {
  id: string;
  label: string;
};

class TeamMemberInvitation$ {
  constructor(
    public user$: Subject<UserBase | null>,
    public roles$: Subject<UserTeamRole[]>
  ) {}

  @computed
  get user$errors$() {
    return this.user$.pipe(
      map((user) => {
        const errors = [];
        if (user === null) {
          errors.push("User name is required.");
        }
        return errors;
      })
    );
  }

  @computed
  get roles$errors$() {
    return this.roles$.pipe(
      map((roles) => {
        const errors = [];
        if (roles.length === 0) {
          errors.push("User role is required.");
        }
        return errors;
      })
    );
  }

  get errors$() {
    return this.user$errors$.pipe(
      combineLatestWith(this.roles$errors$),
      map(([userErrors, rolesErrors]) => {
        return [...userErrors, ...rolesErrors];
      })
    );
  }
}

export default function InviteMemberModal({ open }: InviteMemberModalProps) {
  const notify = useNotify();
  const { user$, user$errors$, roles$, roles$errors$ } = React.useMemo(
    () =>
      new TeamMemberInvitation$(
        new BehaviorSubject<UserBase | null>(null),
        new BehaviorSubject<UserTeamRole[]>([])
      ),
    []
  );

  const [teamAssignUserMutation] = useTeamAssignUserMutation({});
  const user = useRxVal(user$);
  const userErrors = useRxVal(user$errors$);

  const roles = useRxVal(roles$);
  const rolesErrors = useRxVal(roles$errors$);

  const navigate = useNavigate();
  const t = useTranslation();
  const ctx = useTeam();

  const [saving, setSaving] = React.useState(false);

  const [showErrors, setShowErrors] = React.useState(false);
  const handleInvite = React.useCallback(async () => {
    setShowErrors(true);
    if (user && userErrors.length === 0 && rolesErrors.length === 0) {
      setSaving(true);
      const res = await teamAssignUserMutation({
        variables: {
          userId: user.id,
          teamId: ctx.team.id,
          roles: roles.map((role) => TeamRole.Admin),
        },
      });

      setSaving(false);
      if (res.errors) {
        notify({ type: "danger", msg: "An error has been occurred." });
      } else {
        notify({
          type: "success",
          msg: "A new member has been invited successfully.",
        });
        navigate("..");
      }
    }
  }, [
    notify,
    user,
    userErrors,
    roles,
    rolesErrors,
    teamAssignUserMutation,
    ctx,
    navigate,
  ]);

  return (
    <Modal open={open}>
      <ModalDialog>
        <ModalClose
          onClick={() =>
            navigate("..", {
              relative: "path",
            })
          }
        />
        <DialogTitle>{t("Invite Members")}</DialogTitle>
        <DialogContent>
          <Stack gap={4} sx={{ mt: 2 }}>
            <Typography>
              {t("You're inviting members to {team}", {
                team: ctx?.team.name,
              })}
            </Typography>
            <FormControl required error={showErrors && userErrors.length > 0}>
              <FormLabel>{t("Username, name or email address")}</FormLabel>
              <UserSelect user$={user$} />
              {showErrors &&
                userErrors.length > 0 &&
                userErrors.map((error) => (
                  <FormHelperText>{error}</FormHelperText>
                ))}
            </FormControl>
            <FormControl required error={showErrors && rolesErrors.length > 0}>
              <FormLabel>{t("Select a role")}</FormLabel>
              <TeamRolesSelect roles$={roles$} />
              {showErrors &&
                rolesErrors.length > 0 &&
                rolesErrors.map((error) => (
                  <FormHelperText>{error}</FormHelperText>
                ))}
            </FormControl>
            <Stack gap={4} sx={{ mt: 2 }}>
              <Button
                disabled={saving}
                loading={saving}
                type="submit"
                fullWidth
                onClick={handleInvite}
              >
                {t("Add User")}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}
