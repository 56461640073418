import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  useTeamAddUserMutation,
  useTeamMembersQuery,
} from "../../../../__generated__/types-and-hooks";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/joy";
import RolesMultiSelect from "./RolesMultiselect";
import { useTeam } from "../../TeamLayout";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UserTooltip from "../../../../components/tooltips/UserTooltip";
import { useTranslation } from "../../../../i18n";

function UserRenderCell(params: GridRenderCellParams) {
  return (
    <>
      <Chip sx={{ borderRadius: 4 }}>{params.row.slug}</Chip>
      <Tooltip
        placement="top"
        variant="outlined"
        arrow
        title={params.row.slug ? <UserTooltip slug={params.row.slug} /> : null}
      >
        <InfoOutlinedIcon color="disabled" />
      </Tooltip>
    </>
  );
}

export default function Members() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const { teamSlug } = useParams();
  const teamEdges = useTeamMembersQuery({
    variables: { slug: teamSlug as string },
  });
  const [teamAddUserMutation] = useTeamAddUserMutation({});

  const rows = (teamEdges.data?.team_by_slug?.userEdges ?? []).map((e) => ({
    id: e.user.id,
    roles: e.roles,
    slug: e.user.slug,
    firstName: e.user.firstName,
    lastName: e.user.lastName,
    createdAt: e.createdAt,
  }));

  function EditRolesCell(props: GridRenderEditCellParams) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();

    return (
      <RolesMultiSelect
        hasFocus={hasFocus}
        value={value}
        onChange={(roles) => {
          apiRef.current.setEditCellValue({
            id,
            field,
            value: roles,
          });
          apiRef.current.stopCellEditMode({ id, field });
        }}
      />
    );
  }

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 10,
        editable: false,
      },
      {
        field: "slug",
        headerName: "User Name",
        width: 240,
        editable: false,
        renderCell: UserRenderCell,
      },
      {
        field: "firstName",
        headerName: "First Name",
        width: 120,
        editable: false,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 120,
        editable: false,
      },
      {
        field: "roles",
        headerName: "Roles",
        width: 240,
        renderCell: (params) => (
          <Box>
            {params.value.map((role: string) => (
              <Chip sx={{ mr: 1, borderRadius: 4 }} key={role}>
                {role}
              </Chip>
            ))}
          </Box>
        ),
        renderEditCell: EditRolesCell,
        editable: true,
      },
      {
        field: "createdAt",
        type: "date",
        valueGetter: (value: string | null) => {
          if (!value) return value;
          return new Date(value);
        },
        headerName: "Created At",
        width: 120,
        editable: false,
      },
    ],
    []
  );

  const handleProcessRowUpdate = useCallback(
    async (
      newRow: GridRowModel,
      oldRow: GridRowModel,
      { rowId }: { rowId: GridRowId }
    ) => {
      await teamAddUserMutation({
        variables: {
          userId: newRow.id as any,
          teamId: teamCtx.team.id,
          roles: newRow.roles as any,
        },
        onCompleted: (data) => {
          teamEdges.refetch();
        },
      });

      return newRow;
    },
    [teamAddUserMutation, teamCtx.team.id, teamEdges]
  );

  const navigate = useNavigate();

  return (
    <Box sx={{ m: 2 }}>
      <Box sx={{ display: "flex", mb: 2 }}>
        <Typography sx={{ flex: 1 }} level="h4">
          {t("Members")}
        </Typography>
        <Button variant="solid" onClick={() => navigate("./add")}>
          {t("Invite")}
        </Button>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="cell"
        showColumnVerticalBorder
        showCellVerticalBorder
        rowHeight={36}
        disableVirtualization={false}
        disableColumnMenu
        disableColumnFilter
        hideFooter
        processRowUpdate={handleProcessRowUpdate}
      />
      <Outlet />
    </Box>
  );
}
