import * as React from "react";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";

export interface TableViewSwitchProps {
  view: "table" | "list";
  onChange?: (value: "table" | "list") => void;
}

export default function TableViewSwitch({
  view: defaultView,
  onChange,
}: TableViewSwitchProps) {
  const [view, setView] = React.useState(defaultView ?? "table");
  return (
    <RadioGroup
      orientation="horizontal"
      aria-labelledby="segmented-controls-example"
      name="view"
      value={view}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as "table" | "list";
        setView(value);
        onChange && onChange(value);
      }}
      sx={{
        minHeight: 32,
        padding: "4px",
        borderRadius: "12px",
        bgcolor: "neutral.softBg",
        "--RadioGroup-gap": "4px",
        "--Radio-actionRadius": "8px",
      }}
    >
      {["table", "list"].map((item) => (
        <Radio
          key={item}
          color="neutral"
          value={item}
          disableIcon
          label={
            {
              table: <ViewListOutlinedIcon sx={{ fontSize: 24 }} />,
              list: <GridViewOutlinedIcon sx={{ fontSize: 24 }} />,
            }[item]
          }
          variant="plain"
          sx={{ px: 2, alignItems: "center" }}
          slotProps={{
            action: ({ checked }) => ({
              sx: {
                ...(checked && {
                  bgcolor: "background.surface",
                  boxShadow: "sm",
                  "&:hover": {
                    bgcolor: "background.surface",
                  },
                }),
                ...(!checked && {
                  "&:hover": {
                    bgcolor: "neutral.softHoverBg",
                  },
                }),
              },
            }),
          }}
        />
      ))}
    </RadioGroup>
  );
}
