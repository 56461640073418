import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import {
  useUserMeQuery,
  useUserSendVerificationEmailMutation,
} from "../../__generated__/types-and-hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { useCallback } from "react";
import WarningIcon from "@mui/icons-material/Warning";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import FileUploadButton from "../../components/FileUploadButton";
import TeamLinkButton from "./organizations/TeamLinkButton";
import DOMPurify from "dompurify";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function Dashboard() {
  const navigate = useNavigate();
  const t = useTranslation();
  const [userSendVerificationEmailMutation, userSendVerificationEmailRes] =
    useUserSendVerificationEmailMutation({});

  const userMeQuery = useUserMeQuery();
  const user = userMeQuery.data?.user_me;
  const handleGetStarted = useCallback(() => {
    navigate("/get-started");
  }, [navigate]);

  return (
    <>
      <Box sx={{ padding: 4, maxWidth: 900, margin: "0 auto" }}>
        {/* Verify Email */}
        {!user?.verifiedEmail && !userSendVerificationEmailRes.data && (
          <Alert
            sx={{ padding: 3, marginBottom: 3 }}
            startDecorator={<WarningIcon fontSize="medium" />}
            variant="soft"
            color="warning"
            endDecorator={
              <Button
                disabled={userSendVerificationEmailRes.loading}
                color="success"
                sx={{ mr: 1 }}
                startDecorator={<EmailOutlinedIcon />}
                onClick={() => userSendVerificationEmailMutation()}
              >
                {t("Send the link")}
              </Button>
            }
          >
            {t("Please verify your email by opening a verification link.")}
          </Alert>
        )}
        <Box sx={{ textAlign: "right" }}>
          <Link
            // level="body-sm"
            target="_blank"
            href={process.env.REACT_APP_URL + "/user/" + user?.slug}
          >
            {process.env.REACT_APP_URL + "/user/" + user?.slug}
            <OpenInNewIcon />
          </Link>
        </Box>
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 0,
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: 180,
              width: "100%",
            }}
          >
            <img
              src={
                user?.background
                  ? process.env.REACT_APP_API_URL + "/" + user?.background
                  : "/images/inspections_empty_state.png"
              }
              alt="Background"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 8,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
            <IconButton
              component={"label"}
              variant="soft"
              color="neutral"
              sx={{
                border: 1,
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <>
                <FileUploadButton
                  entityType="user"
                  resource="background"
                  onSuccess={userMeQuery.refetch}
                />
                <FileUploadRoundedIcon />
              </>
            </IconButton>
          </Box>
          <Avatar
            sx={{
              position: "absolute",
              width: 160,
              height: 160,
              marginBottom: 2,
              boxShadow: "lg",
              marginTop: 0,
              top: 90,
              border: 2,
              borderColor: "var(--joy-palette-background-level1)",
            }}
            src={
              user?.picture
                ? process.env.REACT_APP_API_URL + "/" + user?.picture
                : "/images/qcsku_light.jpg"
            }
            alt={t("User Avatar")}
          />
          <IconButton
            component={"label"}
            variant="solid"
            color="neutral"
            sx={{
              position: "absolute",
              top: 190,
              ml: 25,
            }}
          >
            <>
              <FileUploadButton
                entityType="user"
                resource="picture"
                onSuccess={userMeQuery.refetch}
              />
              <FileUploadRoundedIcon />
            </>
          </IconButton>

          <Stack
            gap={1}
            sx={{
              width: "100%",
              p: 2,
              pt: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              level="h2"
              endDecorator={
                <IconButton
                  variant="soft"
                  color="primary"
                  onClick={() => navigate("./edit-name")}
                >
                  <CreateOutlinedIcon />
                </IconButton>
              }
            >
              {user?.firstName && user.firstName.length > 0
                ? user.firstName
                : t("[First Name]")}{" "}
              {user?.lastName && user.lastName.length > 0
                ? user.lastName
                : t("[Last Name]")}{" "}
            </Typography>
            <Box>
              {user?.teamEdges.map((teamEdge) => (
                <TeamLinkButton
                  key={teamEdge.team.id}
                  name={teamEdge.team.name}
                  slug={teamEdge.team.slug}
                  picture={teamEdge.team.picture}
                />
              ))}
            </Box>
            {user?.teamEdges.length === 0 && (
              <Button sx={{ m: 1 }} variant="solid" onClick={handleGetStarted}>
                {t("Get Started")}
              </Button>
            )}
          </Stack>
          {/* About Section */}
          <Divider />
          <Box sx={{ alignItems: "left", width: "100%", p: 3 }}>
            <Typography
              level="h2"
              sx={{ fontSize: "lg" }}
              endDecorator={
                <IconButton
                  variant="soft"
                  color="primary"
                  onClick={() => navigate("./edit-about")}
                >
                  <CreateOutlinedIcon />
                </IconButton>
              }
            >
              {t("Bio")}
            </Typography>
            {user && user.bio && user.bio.length > 0 ? (
              <Typography sx={{ marginTop: 1 }}>
                <div
                  className="quill-content"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(user.bio),
                  }}
                />
              </Typography>
            ) : (
              <Typography level="body-xs" sx={{ marginTop: 1 }}>
                {t(
                  "You can write about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences."
                )}
              </Typography>
            )}
          </Box>
        </Card>
      </Box>
      <Outlet />
    </>
  );
}
