import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputTypeMap,
  Option,
  Select,
} from "@mui/joy";
import React, { ReactNode, useCallback } from "react";
import { Observable, Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";
import { useLang } from "../i18n";

const formatValue = (number: number, currency: string, locale: string) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number || 0);
};

export interface XMoneyInputProps {
  label?: string | ReactNode;
  val$: Subject<number>;
  currency$: Subject<string>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
}

export function XMoneyInput({
  val$,
  currency$,
  error$,
  showError,
  label,
  required,
  ...props
}: InputTypeMap<{}, "div">["props"] & XMoneyInputProps) {
  const val = useRxVal(val$);
  const currency = useRxVal(currency$);
  const error = useRxVal(error$);
  const [lang] = useLang();

  const handleChanges = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      const inputValue = e.target.value.replace(/[^0-9]/g, "");
      val$.next(inputValue === "" ? 0 : parseInt(inputValue, 10));
    },
    [val$]
  );

  const formatted = formatValue(val, currency, lang);
  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        {...props}
        type="text"
        onChange={handleChanges}
        onBlur={handleChanges}
        value={formatted}
        sx={{ borderRadius: "sm", paddingLeft: 0, ...props.sx }}
        startDecorator={
          <Select
            size="sm"
            variant="plain"
            color="primary"
            value={currency}
            sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, m: 0 }}
            onChange={(event, newValue) => currency$.next(newValue ?? "USD")}
          >
            <Option value="USD">USD</Option>
            <Option value="EUR">EUR</Option>
            <Option value="GBP">GBP</Option>
            <Option value="JPY">JPY</Option>
            <Option value="CNY">YUAN</Option>
            <Option value="TWD">TWD</Option>
            <Option value="CAD">CAD</Option>
            <Option value="RUB">RUB</Option>
          </Select>
        }
      />
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
