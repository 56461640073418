import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import { AspectRatio, Button } from "@mui/joy";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

export default function PurchaseOrders() {
  const t = useTranslation();
  const teamCtx = useTeam();

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Purchase Orders")}
        </Typography>
        <Button startDecorator={<AddIcon />} variant="solid">
          {t("Purchase Order")}
        </Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography color="neutral" level="body-md">
          {t(
            "Manage all your Purchase Orders in one place to streamline your supply chain operations. Add Purchase Orders manually or connect with integrations like Anvyl or NetSuite."
          )}
        </Typography>
        <Link to={`/${teamCtx.team.slug}/settings/integrations`}>
          {t("Settings/Integrations")}
        </Link>
        <AspectRatio
          variant="plain"
          ratio={1}
          objectFit="contain"
          maxHeight={"50vh"}
          sx={{ bgcolor: "transparent" }}
        >
          <img
            src={"/images/purchase_order_empty.png"}
            alt={t("No purchase orders yet")}
          />
        </AspectRatio>
      </Box>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Button sx={{ mb: 2 }} variant="outlined">
          {t("Create New Purchase Order")}
        </Button>
      </Box>
    </>
  );
}
