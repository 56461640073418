import Box from "@mui/joy/Box";
import { useParams } from "react-router-dom";
import { useTeam } from "../TeamLayout";
import { useContactByUniqueIdQuery } from "../../../__generated__/types-and-hooks";
import {
  Breadcrumbs,
  Card,
  Chip,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../../i18n";
import Email from "@mui/icons-material/EmailOutlined";
import WhatsApp from "@mui/icons-material/WhatsApp";
import Phone from "@mui/icons-material/LocalPhone";
import Message from "@mui/icons-material/Message";
import { ReactNode } from "react";
import CountryFlag from "../../../components/CountryFlag";
import { Link as RouterLink } from "react-router-dom";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";

// Map of contact method keys to corresponding icons
const iconMap: Record<string, ReactNode> = {
  phone: <Phone style={{ fontSize: 14 }} />,
  email: <Email style={{ fontSize: 14 }} />,
  whatsapp: <WhatsApp style={{ fontSize: 14 }} />,
};

export default function Contact() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const { uniqueId } = useParams();
  const contactQuery = useContactByUniqueIdQuery({
    variables: { uniqueId: uniqueId! },
  });
  const contact = contactQuery.data && contactQuery.data.contact_by_id;

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={RouterLink}
          underline="hover"
          sx={{ display: "flex", alignItems: "center" }}
          to={`/${teamCtx.team.slug}/contacts`}
        >
          {t("Contacts")}
        </Link>
        <Typography
          sx={{ color: "text.primary", display: "flex", alignItems: "center" }}
          startDecorator={<TagOutlinedIcon />}
        >
          {uniqueId}
        </Typography>
      </Breadcrumbs>
      <Typography sx={{ ml: 2 }} level="title-lg">
        {contact?.name}
      </Typography>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          minHeight: "calc(100vh - 150px)",
        }}
      >
        {contactQuery.loading && <LinearProgress />}
        {contact && (
          <>
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 2,
              }}
            >
              <Typography level="title-lg">Contact Methods</Typography>
              {contact.contactMethods.map((method, index) => (
                <Chip
                  key={index}
                  variant="soft"
                  sx={{ borderRadius: 4 }}
                  startDecorator={
                    iconMap[method.key] ?? <Message style={{ fontSize: 14 }} />
                  }
                >
                  {method.value}
                </Chip>
              ))}
            </Card>
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 2,
              }}
            >
              <Typography level="title-lg">Address</Typography>
              <KeyValueLine
                label={t("Address Line 1")}
                value={contact.addressLine1}
              />
              <KeyValueLine
                label={t("Address Line 2")}
                value={contact.addressLine2}
              />
              <KeyValueLine label={t("City")} value={contact.city} />
              <KeyValueLine label={t("State")} value={contact.state} />
              <KeyValueLine
                label={t("Postal Code")}
                value={contact.postalCode}
              />
              <KeyValueLine
                label={t("Country")}
                value={
                  contact.countryCode ? (
                    <CountryFlag code={contact.countryCode} />
                  ) : null
                }
              />
            </Card>
          </>
        )}
      </Box>
    </>
  );
}

function KeyValueLine({
  label,
  value,
}: {
  label: string;
  value?: string | null | ReactNode;
}) {
  if (!value) return null;
  return (
    <Stack direction="row" gap={1}>
      <Typography sx={{ minWidth: 140 }} level="body-md">
        {label}:
      </Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
}
