import { Chip } from "@mui/joy";
import { FAIcon, FALabelShort, FAValue } from "./inspections/FA";
import {
  InlineIcon,
  InlineLabelShort,
  InlineValue,
} from "./inspections/Inline";
import { PSIIcon, PSILabelShort, PSIValue } from "./inspections/PSI";

interface ServiceTypeIconProps {
  value: string;
}

export function ServiceTypeIcon({ value }: ServiceTypeIconProps) {
  switch (value) {
    case PSIValue:
      return (
        <Chip size={"sm"} sx={{ borderRadius: 4 }} startDecorator={<PSIIcon />}>
          {PSILabelShort}
        </Chip>
      );
    case InlineValue:
      return (
        <Chip
          size={"sm"}
          sx={{ borderRadius: 4 }}
          startDecorator={<InlineIcon />}
        >
          {InlineLabelShort}
        </Chip>
      );
    case FAValue:
      return (
        <Chip size={"sm"} sx={{ borderRadius: 4 }} startDecorator={<FAIcon />}>
          {FALabelShort}
        </Chip>
      );
  }

  return <span>{value}</span>;
}
