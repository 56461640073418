import { Box, Typography } from "@mui/joy";
import { useTranslation } from "../../../i18n";
import { useAllBrandsQuery } from "../../../__generated__/types-and-hooks";
import BrandMiniCard from "./BrandMiniCard";

export default function Network() {
  const t = useTranslation();
  const { data, loading } = useAllBrandsQuery();

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h4">
          {t("QC Network")}
        </Typography>
      </Box>
      <Box sx={{ m: 2 }}>
        <Typography sx={{ flex: 1 }} level="body-sm">
          {t("Explore the network of QCSKU agencies and brands.")}
        </Typography>
      </Box>
      <Box sx={{ m: 2 }}>
        {loading && "Loading..."}
        {!loading && (
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns:
                "repeat(auto-fill, minmax(min(100%, 200px), 1fr))",
              gap: 2,
              marginBottom: 3,
            }}
          >
            {(data?.team_list?.teams ?? []).map((team) => (
              <BrandMiniCard
                key={team.id}
                name={team.name}
                slug={team.slug}
                description={team.description}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
}
