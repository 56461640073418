import * as React from "react";
import { useColorScheme } from "@mui/joy/styles";
import Box from "@mui/joy/Box";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import LanguageToggle from "../../components/header/LanguageToggle";
import { Button, Typography } from "@mui/joy";
import LogoIcon from "../../components/LogoIcon";

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === "light" ? "dark" : "light");
        onClick?.(event);
      }}
      {...other}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export function SignInHeader() {
  const { mode } = useColorScheme();
  return (
    <Box
      component="header"
      sx={{
        py: 3,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button
        variant="plain"
        color="neutral"
        component="a"
        href="https://qcsku.com/"
        sx={{ alignSelf: "center" }}
      >
        <Typography
          startDecorator={<LogoIcon size="md" dark={mode === "dark"} />}
          sx={{ fontWeight: "md" }}
        >
          QCSKU
        </Typography>
      </Button>
      <ColorSchemeToggle sx={{ ml: "auto" }} />
      <LanguageToggle size="sm" variant="outlined" sx={{ ml: 1 }} />
    </Box>
  );
}
