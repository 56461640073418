import { Typography } from "@mui/joy";
import {
  useApiKeysQuery,
  useRevokeApiKeyMutation,
} from "../../../__generated__/types-and-hooks";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "../../../i18n";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useCallback, useMemo } from "react";

export default function UserApiKeys() {
  const t = useTranslation();
  const apiKeysQuery = useApiKeysQuery({});
  const [revokeApiKeyMutation] = useRevokeApiKeyMutation();
  const handleRevoke = useCallback(
    (params: any) => {
      revokeApiKeyMutation({
        variables: { authorizationKeyId: params.id },
        onCompleted() {
          apiKeysQuery.refetch();
        },
      });
    },
    [revokeApiKeyMutation, apiKeysQuery]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 10,
        editable: false,
      },
      {
        field: "name",
        headerName: "Name",
        width: 180,
        editable: false,
      },
      {
        field: "key",
        headerName: "Key",
        width: 180,
        editable: false,
      },
      {
        field: "exp",
        type: "date",
        valueGetter: (value: string | null) => {
          if (!value) return value;
          return new Date(value);
        },
        headerName: "Exp",
        width: 120,
        editable: false,
      },
      {
        field: "actions",
        type: "actions",
        width: 10,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<DeleteOutlineOutlinedIcon />}
            label={t("Revoke")}
            onClick={() => handleRevoke(params)}
            showInMenu
          />,
        ],
      },
    ],
    [t, handleRevoke]
  );

  if (apiKeysQuery.loading) return <div>Loading</div>;

  const rows = apiKeysQuery.data?.authorization_key_list ?? [];
  if (rows.length === 0) {
    return (
      <Typography level="body-sm">
        {t(
          "No API Keys yet. Generate an API key to start using our services securely."
        )}
      </Typography>
    );
  }

  return (
    <DataGrid
      hideFooter
      rows={rows}
      columns={columns}
      editMode="cell"
      showColumnVerticalBorder
      showCellVerticalBorder
      rowHeight={36}
      disableVirtualization={false}
      disableColumnMenu
      disableColumnFilter
    />
  );
}
