import PurchaseOrders from "./PurchaseOrders";

export const purchaseOrdersRoutes = [
  {
    path: "/:teamSlug/purchase-orders",
    element: <PurchaseOrders />,
    children: [],
  },
  {
    path: "/:teamSlug/purchase-orders/:id",
    element: <PurchaseOrders />,
    children: [],
  },
];
