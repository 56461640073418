import { GridRenderCellParams } from "@mui/x-data-grid";
import { Chip } from "@mui/joy";

const ArrayCell = ({ id, value, field, api }: GridRenderCellParams) => {
  return (
    <>
      {value?.map((v: any, i: number) => (
        <Chip key={i} size={"sm"} sx={{ mr: 1, borderRadius: 4 }}>
          {v}
        </Chip>
      ))}
    </>
  );
};

export default ArrayCell;
