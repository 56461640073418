import {
  Avatar,
  Box,
  Card,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/joy";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { useGenralTeamSettingsQuery } from "../../../../__generated__/types-and-hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import FileUploadButton from "../../../../components/FileUploadButton";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { useCallback } from "react";
import { useTranslation } from "../../../../i18n";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DOMPurify from "dompurify";

export default function General() {
  const navigate = useNavigate();
  const t = useTranslation();
  const { teamSlug } = useParams();
  const genralTeamSettingsQuery = useGenralTeamSettingsQuery({
    variables: { slug: teamSlug! },
  });
  const handleAvatar = useCallback(() => {}, []);

  const team = genralTeamSettingsQuery.data?.team_by_slug;
  if (!team) return null;

  return (
    <Box sx={{ padding: 2, width: "100%", margin: "0 auto" }}>
      <Box sx={{ textAlign: "right" }}>
        <Link
          target="_blank"
          href={process.env.REACT_APP_URL + "/team/" + team?.slug}
        >
          {process.env.REACT_APP_URL + "/team/" + team?.slug}
          <OpenInNewIcon />
        </Link>
      </Box>

      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 0,
          marginBottom: 3,
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: 180,
            width: "100%",
          }}
        >
          <img
            src={
              team?.background
                ? process.env.REACT_APP_API_URL + "/" + team?.background
                : "/images/inspections_empty_state.png"
            }
            alt="Background"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 8,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <IconButton
            component={"label"}
            variant="solid"
            color="neutral"
            sx={{
              border: 1,
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <>
              <FileUploadButton
                entityType="team"
                entityId={team.id.toString()}
                resource="background"
                onSuccess={() => genralTeamSettingsQuery.refetch()}
              />
              <FileUploadRoundedIcon />
            </>
          </IconButton>
        </Box>
        <Avatar
          sx={{
            position: "absolute",
            width: 160,
            height: 160,
            marginBottom: 2,
            boxShadow: "lg",
            marginTop: 0,
            top: 90,
            border: 2,
            borderColor: "var(--joy-palette-background-level1)",
          }}
          src={
            team?.picture
              ? process.env.REACT_APP_API_URL + "/" + team?.picture
              : "/images/qcsku_light.jpg"
          }
          alt={t("User Avatar")}
          onClick={handleAvatar}
        />
        <IconButton
          component={"label"}
          variant="outlined"
          color="neutral"
          sx={{
            position: "absolute",
            top: 190,
            ml: 25,
          }}
        >
          <>
            <FileUploadButton
              entityType="team"
              entityId={team.id.toString()}
              resource="picture"
              onSuccess={() => genralTeamSettingsQuery.refetch()}
            />
            <FileUploadRoundedIcon />
          </>
        </IconButton>

        <Stack
          gap={1}
          sx={{
            width: "100%",
            p: 2,
            pt: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            level="h4"
            fontWeight="bold"
            endDecorator={
              <IconButton
                variant="soft"
                color="primary"
                onClick={() => navigate("./edit-name")}
              >
                <CreateOutlinedIcon />
              </IconButton>
            }
          >
            {team?.name}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {team.description}
          </Typography>
        </Stack>
        <Divider />
        {/* About Section */}
        <Stack gap={1} sx={{ alignItems: "left", width: "100%", p: 3 }}>
          <Typography
            level="h2"
            sx={{ fontSize: "lg" }}
            endDecorator={
              <IconButton
                variant="soft"
                color="primary"
                onClick={() => navigate("./about")}
              >
                <CreateOutlinedIcon />
              </IconButton>
            }
          >
            {t("About")}
          </Typography>
          {team && !team.about && (
            <Typography level="body-xs" sx={{ marginTop: 1 }}>
              {t("You can write about your company.")}
            </Typography>
          )}
          {team && team.about && (
            <Typography sx={{ marginTop: 1 }}>
              <div
                className="quill-content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(team.about),
                }}
              />
            </Typography>
          )}
        </Stack>
      </Card>
      <Outlet />
    </Box>
  );
}
