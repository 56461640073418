import Button from "@mui/joy/Button";
import {
  Box,
  DialogTitle,
  FormControl,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
} from "@mui/joy";
import CardOverflow from "@mui/joy/CardOverflow";
import { Typography } from "@mui/joy";
import { Stack } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";
import { useTranslation } from "../../../i18n";
import {
  TeamSearchFragment,
  useTeamJoinMutation,
  useUserMeQuery,
} from "../../../__generated__/types-and-hooks";
import { QCSKULogo } from "../../../components/QCSKULogo";
import { XTeamsAutocomplete } from "../../../x-components/XTeamsAutocomplete";
import { BehaviorSubject } from "rxjs";
import { useRxVal } from "../../../hooks/useRx";

export default function JoinTeam() {
  const navigate = useNavigate();
  const t = useTranslation();
  const userMeQuery = useUserMeQuery();
  const team$ = React.useMemo(
    () => new BehaviorSubject<TeamSearchFragment | null>(null),
    []
  );
  const team = useRxVal(team$);
  const [teamJoinMutation, { loading }] = useTeamJoinMutation();
  const handleJoinTeam = useCallback(async () => {
    if (team) {
      await teamJoinMutation({
        variables: { teamId: team.id },
        onCompleted() {
          userMeQuery.refetch();
          navigate("..");
        },
      });
    }
  }, [teamJoinMutation, team, navigate, userMeQuery]);

  return (
    <Modal open onClose={() => navigate("..")}>
      <ModalDialog size="lg" variant="outlined">
        <DialogTitle>
          {t("Join Brand or Agency")}
          <ModalClose />
        </DialogTitle>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            "--icon-size": "100px",
            bgcolor: "background.surface",
          }}
        >
          <CardOverflow variant="soft">
            <QCSKULogo />
          </CardOverflow>
          <form>
            <Stack gap={2} sx={{ mt: "calc(var(--icon-size) / 2)", pt: 4 }}>
              <FormControl>
                <FormLabel>
                  <Typography color="neutral" level="body-sm">
                    {t(
                      "Start typing a name of the brand or agency that you want to join."
                    )}
                  </Typography>
                </FormLabel>
                <XTeamsAutocomplete
                  val$={team$}
                  placeholder={t("Type to search")}
                />
              </FormControl>
              <Button
                type="submit"
                loading={loading}
                variant={"solid"}
                color={"primary"}
                disabled={team === null}
                onClick={handleJoinTeam}
              >
                {t("Send a request to join")}
              </Button>
            </Stack>
          </form>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
