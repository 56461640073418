import { Avatar, Box, Link, Typography } from "@mui/joy";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useTranslation } from "../../i18n";
import { useUserTooltipBySlugQuery } from "../../__generated__/types-and-hooks";
import CountryFlag from "../CountryFlag";

export interface UserTooltipProps {
  slug: string;
}

export default function UserTooltip({ slug }: UserTooltipProps) {
  const t = useTranslation();
  const { data } = useUserTooltipBySlugQuery({ variables: { slug } });
  const user = data?.user_by_slug;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 320,
        justifyContent: "center",
        p: 1,
        textAlign: "center",
        alignItems: "center",
        width: "100%",
        margin: "auto",
        "--icon-size": "60px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: 80,
          width: "100%",
        }}
      >
        <img
          src={
            user?.background
              ? process.env.REACT_APP_API_URL + "/" + user?.background
              : "/images/inspections_empty_state.png"
          }
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 8,
          }}
        />
      </Box>
      <Avatar
        sx={{
          width: 80,
          height: 80,
          marginBottom: 2,
          marginTop: -5,
          boxShadow: "lg",
          border: 2,
          borderColor: "var(--joy-palette-background-level1)",
        }}
        src={
          user?.picture
            ? process.env.REACT_APP_API_URL + "/" + user?.picture
            : "/images/qcsku_light.jpg"
        }
        alt={t("User Avatar")}
      />

      <Typography
        level="h4"
        fontWeight="bold"
        endDecorator={
          user?.verifiedEmail && <VerifiedOutlinedIcon color="primary" />
        }
      >
        {user?.firstName} {user?.lastName}
      </Typography>
      <Box>
        {user && <CountryFlag code={user?.countryCode} />}
        <Link
          level="body-xs"
          target="_blank"
          href={process.env.REACT_APP_URL + "/user/" + user?.slug}
        >
          {user?.slug}
          <OpenInNewIcon />
        </Link>
      </Box>
    </Box>
  );
}
