import Box from "@mui/joy/Box";
import { AspectRatio, IconButton, Stack } from "@mui/joy";
import Layout from "../components/Layout";
import LanguageToggle from "../components/header/LanguageToggle";
import ColorSchemeToggle from "../components/header/ColorSchemeToggle";
import ProfileDropDown from "../components/header/ProfileDropDown";
import HomeIcon from "@mui/icons-material/Home";
import { Link, useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Layout.Root1Col>
      <Layout.Header>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            <IconButton variant="soft" size="md" onClick={() => navigate("/")}>
              <HomeIcon />
            </IconButton>
          </Stack>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1.5,
              alignItems: "center",
            }}
          >
            <LanguageToggle />
            <ColorSchemeToggle />
            <ProfileDropDown />
          </Box>
        </Box>
      </Layout.Header>
      <Layout.SideNav></Layout.SideNav>
      <Layout.Main sx={{ mb: 10 }}>
        <Link to="/">
          <Box
            sx={{
              gap: 2,
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AspectRatio
              variant="plain"
              ratio={1}
              objectFit="contain"
              maxHeight={"80vh"}
              sx={{ bgcolor: "transparent" }}
            >
              <img alt="Page not found" src="/images/page_not_found.png" />
            </AspectRatio>
          </Box>
        </Link>
      </Layout.Main>
    </Layout.Root1Col>
  );
}
