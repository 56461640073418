import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardOverflow,
  Divider,
  Link,
  Stack,
  Typography,
  useColorScheme,
} from "@mui/joy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export interface BrandMiniCardProps {
  name: string;
  slug: string;
  picture?: string;
  description?: string | null;
}

export default function BrandMiniCard({
  name,
  slug,
  picture,
  description,
}: BrandMiniCardProps) {
  const { mode } = useColorScheme();

  return (
    <Card
      size="sm"
      variant={"outlined"}
      sx={{ boxShadow: "sm", bgcolor: "background.level1" }}
    >
      <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
        <CardContent
          orientation="horizontal"
          sx={{ alignItems: "center", gap: 1 }}
        >
          <Box
            sx={{
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                m: "-2px",
                borderRadius: "50%",
                background:
                  "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
              },
            }}
          >
            <Avatar
              size="sm"
              src={
                picture
                  ? picture
                  : mode === "light"
                  ? "/images/qcsku_light.jpg"
                  : "/images/qcsku_dark.jpg"
              }
            />
          </Box>
          <Link
            target="_blank"
            href={process.env.REACT_APP_URL + "/team/" + slug}
          >
            <Typography
              noWrap
              sx={{ fontWeight: "lg", flex: 1 }}
              endDecorator={<OpenInNewIcon />}
            >
              {name.slice(0, 16)}
              {name.length > 16 ? "..." : null}
            </Typography>
          </Link>
        </CardContent>
        <Divider inset="context" />
      </CardOverflow>
      <CardContent sx={{ overflow: "hidden" }}>
        <Stack maxHeight={60} minHeight={60} gap={1}>
          <Box>
            <Typography level="body-sm">{description}</Typography>
          </Box>
        </Stack>
      </CardContent>
      {/* <CardOverflow variant="soft" sx={{ bgcolor: "background.level1" }}>
        <Button variant="soft" onClick={handleOpen}>
          Connect
        </Button>
      </CardOverflow> */}
    </Card>
  );
}
