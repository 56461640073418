import { useState } from "react";
import { IconButton, Link, Tooltip, Typography } from "@mui/joy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "../i18n";
import OpenInNewRounded from "@mui/icons-material/OpenInNewRounded";
import { Link as RouterLink } from "react-router-dom";

export interface InspectionRefProps {
  inspectionRef: string;
  teamSlug: string;
  openInNewWindow?: boolean;
}

export default function InspectionRef({
  inspectionRef,
  teamSlug,
  openInNewWindow,
}: InspectionRefProps) {
  const t = useTranslation();
  const [copied, setCopied] = useState<string | null>(null);
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(inspectionRef);
    setCopied(inspectionRef);
    setTimeout(() => setCopied(null), 200);
  };
  return (
    <Typography
      fontSize={14}
      startDecorator={
        <Tooltip arrow title={<Typography>{t("Copy Ref #")}</Typography>}>
          <IconButton
            size="sm"
            variant={copied === inspectionRef ? "solid" : "plain"}
            color={copied === inspectionRef ? "success" : "neutral"}
            onClick={handleCopyToClipboard}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <Link
        component={RouterLink}
        to={`/${teamSlug}/inspections/${inspectionRef}`}
        target={openInNewWindow ? "_blank" : ""}
        endDecorator={openInNewWindow ? <OpenInNewRounded /> : null}
      >
        {inspectionRef.toUpperCase()}
      </Link>
    </Typography>
  );
}
