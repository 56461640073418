import Layout from "../../components/Layout";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Navigation from "./Navigation";

export default function DashboardLayout() {
  return (
    <Layout.Root>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.SideNav>
        <Navigation />
      </Layout.SideNav>
      <Layout.Main sx={{ mb: 10, overflow: "auto" }}>
        <Outlet />
      </Layout.Main>
    </Layout.Root>
  );
}
