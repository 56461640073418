import * as React from "react";
// import Avatar from "@mui/joy/Avatar";
// import ListItemDecorator from "@mui/joy/ListItemDecorator";
// import Option from "@mui/joy/Option";
// import { useUserListQuery } from "../__generated__/types-and-hooks";
import Box from "@mui/joy/Box";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import { Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";
import { Chip } from "@mui/joy";

export type UserBaseType = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
};

export interface UserSelectProps {
  user$: Subject<UserBaseType | null>;
}

export function UserSelect({ user$ }: UserSelectProps) {
  const selectedUser = useRxVal(user$);
  // const userListQuery = useUserListQuery({});
  const handleSelectUser = React.useCallback(
    (_event: any, value: UserBaseType | null) => {
      user$.next(value);
    },
    [user$]
  );

  return (
    <Select
      onChange={handleSelectUser}
      value={selectedUser}
      renderValue={(selectedOption) => {
        if (!selectedOption) return "";

        const user = selectedOption.value;
        return (
          <Box sx={{ display: "flex", gap: "0.25rem" }}>
            <Typography>
              {user.firstName} {user.lastName}
            </Typography>
            <Chip variant="soft" color="primary">
              {user.email}
            </Chip>
          </Box>
        );
      }}
      slotProps={{
        listbox: {
          sx: {
            "--ListItemDecorator-size": "48px",
          },
        },
      }}
      sx={{ minWidth: 240 }}
    >
      {/* {userListQuery.data?.user_list?.users.map((data, index) => (
        <Option
          key={data.id}
          value={data}
          label={`${data.firstName} ${data.lastName} (${data.email})`} // The appearance of the selected value will be a string
        >
          <ListItemDecorator>
            <Avatar src={data.picture} />
          </ListItemDecorator>
          <Box component="span" sx={{ display: "block" }}>
            <Typography component="span" level="title-sm">
              {data.firstName} {data.lastName}
            </Typography>
            <Typography level="body-xs">{data.email}</Typography>
          </Box>
        </Option>
      ))} */}
    </Select>
  );
}
