import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { Avatar, Box, Divider, useColorScheme } from "@mui/joy";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import FactoryIcon from "@mui/icons-material/Factory";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Link, useLocation, useParams } from "react-router-dom";
import Tooltip from "@mui/joy/Tooltip";
import { useTranslation } from "../i18n";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import PublicIcon from "@mui/icons-material/Public";
import React, { ComponentType } from "react";
import { useMyTeamsQuery } from "../__generated__/types-and-hooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export interface NavigationProps {
  teamKind: "AGENCY" | "BRAND";
}

export interface NavItemProps {
  selected: boolean;
  to: string;
  title: string;
  icon: ComponentType<{ fontSize?: any; sx?: object }>;
  iconSelected?: ComponentType<{ fontSize?: any; sx?: object }>;
  children?: React.ReactNode;
}

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={[
          {
            display: "grid",
            transition: "0.2s ease",
            "& > *": {
              overflow: "hidden",
            },
          },
          open ? { gridTemplateRows: "1fr" } : { gridTemplateRows: "0fr" },
        ]}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

function NavItem({
  title,
  selected,
  to,
  icon,
  iconSelected,
  children,
}: NavItemProps) {
  const Icon = selected ? iconSelected ?? icon : icon;

  if (children) {
    return (
      <ListItem nested>
        <Toggler
          renderToggle={({ open, setOpen }) => (
            <ListItemButton
              component={Link}
              selected={selected}
              to={to}
              onClick={() => setOpen(!open)}
            >
              <ListItemDecorator>
                <Tooltip
                  sx={{ display: { xs: "none", sm: "block", lg: "none" } }}
                  title={title}
                  placement="right"
                  size="md"
                  variant="solid"
                >
                  <Icon fontSize="small" />
                </Tooltip>
                <Icon
                  fontSize="small"
                  sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
                />
              </ListItemDecorator>
              <ListItemContent
                sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
              >
                {title}
              </ListItemContent>
              <KeyboardArrowDownIcon
                sx={[
                  open
                    ? { transform: "rotate(180deg)" }
                    : { transform: "none" },
                ]}
              />
            </ListItemButton>
          )}
        >
          <List sx={{ gap: 0.5 }}>
            <Box sx={{ mt: 0.5 }}></Box>
            {children}
          </List>
        </Toggler>
      </ListItem>
    );
  }

  return (
    <ListItem sx={{ ml: { sx: "none", sm: "none" } }}>
      <ListItemButton component={Link} selected={selected} to={to}>
        <ListItemDecorator>
          <Tooltip
            sx={{ display: { xs: "none", sm: "block", lg: "none" } }}
            title={title}
            placement="right"
            size="md"
            variant="solid"
          >
            <Icon fontSize="small" />
          </Tooltip>
          <Icon
            fontSize="small"
            sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
          />
        </ListItemDecorator>
        <ListItemContent
          sx={{ display: { xs: "flex", sm: "none", lg: "flex" } }}
        >
          {title}
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}

export default function Navigation({ teamKind }: NavigationProps) {
  const t = useTranslation();
  const { teamSlug } = useParams();
  const { pathname } = useLocation();
  const [section0, section] = pathname.split("/").slice(1);
  const myTeamsQuery = useMyTeamsQuery();
  const { mode } = useColorScheme();

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: 0,
        overflow: "hidden auto",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        [`& .${listItemButtonClasses.root}`]: {
          gap: 1.5,
        },
      }}
    >
      <List
        size="sm"
        sx={{
          gap: 1,
          "--List-nestedInsetStart": { sm: "0px", lg: "30px" },
          "--ListItem-radius": (theme) => theme.vars.radius.sm,
        }}
      >
        {myTeamsQuery.data?.user_me.teamEdges.map(({ team }) => (
          <React.Fragment key={team.name}>
            <NavItem
              title={team.name}
              selected={section0 === team.slug}
              to={"/" + team.slug}
              icon={(props) => (
                <Box
                  sx={{
                    position: "relative",
                    mr: 1,
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      m: "-1px",
                      borderRadius: "50%",
                      background:
                        "linear-gradient(45deg, #007cf0 0%, #00b3f0 25%, #00c3ff 50%, #0098ff 75%, #005eff 100%)",
                    },
                    ...props.sx,
                  }}
                >
                  <Avatar
                    size="sm"
                    sx={{
                      height: 23,
                      width: 23,
                    }}
                    src={
                      team?.picture
                        ? process.env.REACT_APP_API_URL + "/" + team?.picture
                        : mode === "light"
                        ? "/images/qcsku_light.jpg"
                        : "/images/qcsku_dark.jpg"
                    }
                  />
                </Box>
              )}
            >
              <>
                {teamKind === "AGENCY" && (
                  <NavItem
                    title={t("Inquiries")}
                    selected={section === "inquiries"}
                    to={`/${teamSlug}/inquiries`}
                    icon={(props) => <PublicOutlinedIcon {...props} />}
                    iconSelected={(props) => <PublicIcon {...props} />}
                  />
                )}
                <NavItem
                  title={t("Inspections")}
                  selected={section === "inspections"}
                  to={`/${teamSlug}/inspections`}
                  icon={(props) => <FactCheckOutlinedIcon {...props} />}
                  iconSelected={(props) => <FactCheckIcon {...props} />}
                />
                {teamKind === "BRAND" && (
                  <NavItem
                    title={t("SKU")}
                    selected={section === "sku"}
                    to={`/${teamSlug}/sku`}
                    icon={(props) => <CategoryOutlinedIcon {...props} />}
                    iconSelected={(props) => <CategoryIcon {...props} />}
                  />
                )}
                {teamKind === "BRAND" && (
                  <NavItem
                    title={t("PO")}
                    selected={section === "purchase-orders"}
                    to={`/${teamSlug}/purchase-orders`}
                    icon={(props) => <ReceiptOutlinedIcon {...props} />}
                    iconSelected={(props) => <ReceiptIcon {...props} />}
                  />
                )}
                {teamKind === "BRAND" && (
                  <NavItem
                    title={t("Suppliers")}
                    selected={section === "suppliers"}
                    to={`/${teamSlug}/suppliers`}
                    icon={(props) => <FactoryOutlinedIcon {...props} />}
                    iconSelected={(props) => <FactoryIcon {...props} />}
                  />
                )}
                {teamKind === "BRAND" && (
                  <NavItem
                    title={t("Contacts")}
                    selected={section === "contacts"}
                    to={`/${teamSlug}/contacts`}
                    icon={(props) => <ContactPageOutlinedIcon {...props} />}
                    iconSelected={(props) => <ContactPageIcon {...props} />}
                  />
                )}
                <NavItem
                  title={t("Settings")}
                  selected={section === "settings"}
                  to={`/${teamSlug}/settings`}
                  icon={(props) => <SettingsOutlinedIcon {...props} />}
                  iconSelected={(props) => <SettingsIcon {...props} />}
                />
              </>
            </NavItem>
          </React.Fragment>
        ))}
        {(myTeamsQuery.data?.user_me.teamEdges ?? []).length > 0 && <Divider />}
        <NavItem
          title={t("User Profile")}
          selected={!section}
          to={"/"}
          icon={(props) => <AccountBoxOutlinedIcon {...props} />}
        />
        <NavItem
          title={t("QC Network")}
          selected={section === "network"}
          to={"/network"}
          icon={(props) => <LanguageOutlinedIcon {...props} />}
        />
      </List>
      {/* <Card
        invertedColors
        variant="soft"
        color="warning"
        size="sm"
        sx={{ boxShadow: "none" }}
      >
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography level="title-sm">Used space</Typography>
        </Stack>
        <Typography level="body-xs">
          Your team has used 80% of your available space. Need more?
        </Typography>
      </Card> */}
    </Box>
  );
}
