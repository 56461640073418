import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { Link } from "react-router-dom";
import { useTeam } from "../TeamLayout";
import { AspectRatio, Button } from "@mui/joy";
import { useCallback } from "react";
import {
  useInspectionCreateDraftMutation,
  useInspectionListQuery,
} from "../../../__generated__/types-and-hooks";
import AddIcon from "@mui/icons-material/Add";
import TableView from "./views/TableView";

export default function Inspections() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const inspectionsListQuery = useInspectionListQuery({
    variables: { teamId: teamCtx.team.id, teamKind: teamCtx.team.kind },
  });
  const [inspectionCreateDraftMutation, { loading }] =
    useInspectionCreateDraftMutation();

  const inspections =
    inspectionsListQuery.data &&
    inspectionsListQuery.data.inspection_list?.inspections;

  const handleAddInspection = useCallback(() => {
    inspectionCreateDraftMutation({
      variables: {
        draft: {
          brandId: teamCtx.team.id,
        },
      },
      onCompleted() {
        inspectionsListQuery.refetch();
      },
    });
  }, [teamCtx, inspectionCreateDraftMutation, inspectionsListQuery]);

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("Inspections")}
        </Typography>
        <Button
          loading={loading}
          startDecorator={<AddIcon />}
          variant="solid"
          onClick={handleAddInspection}
        >
          {t("Add Inspection")}
        </Button>
      </Box>
      <Box
        sx={{
          gap: 2,
          p: 2,
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          minHeight: "calc(100vh - 150px)",
        }}
      >
        {inspections && inspections.length === 0 && (
          <>
            <Typography color="neutral" level="body-md">
              {t(
                "No Inspections yet! Start by adding one manually or connecting to an integration like Anvyl or NetSuite."
              )}
            </Typography>

            <Link to={`/${teamCtx.team.slug}/settings/integrations`}>
              {t("Settings/Integrations")}
            </Link>

            <AspectRatio
              variant="plain"
              ratio={1}
              objectFit="contain"
              maxHeight={"50vh"}
              sx={{ bgcolor: "transparent" }}
            >
              <img
                alt={t("No Inspections yet")}
                src="/images/inspections_empty_state.png"
              />
            </AspectRatio>

            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Button sx={{ mb: 2 }} variant="outlined">
                {t("Create New Inspection")}
              </Button>
            </Box>
          </>
        )}

        {inspections && inspections.length > 0 && (
          <>
            <TableView inspections={inspections} />
          </>
        )}
      </Box>
    </>
  );
}
