import { Box, Chip, Option, Select } from "@mui/joy";

export interface RolesMultiSelectProps {
  hasFocus?: boolean;
  value: string[];
  onChange: (newValue: string[]) => void;
}

export default function RolesMultiSelect({
  value,
  onChange,
  hasFocus = false,
}: RolesMultiSelectProps) {
  return (
    <Select
      size="sm"
      sx={{ width: "100%", border: 0 }}
      listboxOpen={hasFocus}
      autoFocus
      multiple
      defaultValue={value}
      renderValue={(selected) => (
        <Box sx={{ display: "flex", gap: "0.25rem" }}>
          {selected.map((selectedOption) => (
            <Chip key={selectedOption.value} variant="soft" color="primary">
              {selectedOption.label}
            </Chip>
          ))}
        </Box>
      )}
      onChange={(event, roles: string[]) => {
        onChange(roles);
      }}
    >
      <Option value="OWNER">Owner</Option>
      <Option value="ADMIN">Admin</Option>
      <Option value="MANAGER">Manager</Option>
      <Option value="MEMBER">Member</Option>
    </Select>
  );
}
