import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useTranslation } from "../../../i18n";
import { useTeam } from "../TeamLayout";
import { useSkuListQuery } from "../../../__generated__/types-and-hooks";
import { AspectRatio, Button } from "@mui/joy";
import { Link } from "react-router-dom";

export default function Skus() {
  const t = useTranslation();
  const teamCtx = useTeam();
  const skuListQuery = useSkuListQuery({
    variables: { teamId: teamCtx.team.id },
  });
  const skus = (skuListQuery.data && skuListQuery.data.sku_list?.skus) ?? [];

  return (
    <>
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          {t("SKU")}
        </Typography>
        <Button variant="solid">{t("Add SKU")}</Button>
      </Box>
      {skus?.length === 0 && (
        <>
          <Box
            sx={{
              gap: 2,
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography color="neutral" level="body-md">
              {t(
                "Manage all your SKUs in one place to streamline your supply chain operations. Add SKUs manually or connect with integrations like Anvyl or NetSuite."
              )}
            </Typography>
            <Link to={`/${teamCtx.team.slug}/settings/integrations`}>
              {t("Settings/Integrations")}
            </Link>
            <AspectRatio
              variant="plain"
              ratio={1}
              objectFit="contain"
              maxHeight={"50vh"}
              sx={{ bgcolor: "transparent" }}
            >
              <img
                src={"/images/skus_empty_state.png"}
                alt={t("No SKUs yet")}
              />
            </AspectRatio>
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Button sx={{ mb: 2 }} variant="outlined">
              {t("Create New SKU")}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
