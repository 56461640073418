import Box from "@mui/joy/Box";
import LanguageToggle from "../../components/header/LanguageToggle";
import ProfileDropDown from "../../components/header/ProfileDropDown";
import ColorSchemeToggle from "../../components/header/ColorSchemeToggle";
import {
  Button,
  DialogTitle,
  Drawer,
  IconButton,
  ModalClose,
  Stack,
  Typography,
  useColorScheme,
} from "@mui/joy";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Navigation from "./Navigation";
import { useTranslation } from "../../i18n";
import React from "react";
import LogoIcon from "../../components/LogoIcon";

export default function Header() {
  const { mode } = useColorScheme();
  const t = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
        sx={{ display: { sm: "flex" } }}
      >
        <Box sx={{ display: { xs: "inline-flex", sm: "none" } }}>
          <IconButton
            variant="plain"
            color="neutral"
            onClick={() => setOpen(true)}
          >
            <MenuRoundedIcon />
          </IconButton>

          <Drawer
            sx={{ display: { xs: "inline-flex", sm: "none" } }}
            open={open}
            onClose={() => setOpen(false)}
          >
            <ModalClose />
            <DialogTitle>{t("QCSKU")}</DialogTitle>
            <Box sx={{ px: 1 }}>
              <Navigation />
            </Box>
          </Drawer>
        </Box>
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href="https://qcsku.com/"
          sx={{ alignSelf: "center" }}
        >
          <Typography
            startDecorator={<LogoIcon size="md" dark={mode === "dark"} />}
            sx={{ fontWeight: "md" }}
          >
            {t("QCSKU")}
          </Typography>
        </Button>
      </Stack>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          alignItems: "center",
        }}
      >
        <LanguageToggle />
        <ColorSchemeToggle />
        <ProfileDropDown />
      </Box>
    </Box>
  );
}
