import "@fontsource/inter";
import "./index.css";
import App from "./App";
import { createBrowserRouter } from "react-router-dom";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import NextSteps from "./pages/auth/NextSteps";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Authorization from "./pages/auth/Authorization";
import { teamRoutes } from "./pages/team/routes";
import { dashRoutes } from "./pages/dash/routes";
import PageNotFound from "./pages/PageNotFound";
import { ErrorBoundary } from "./ErrorBoundary";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      { ...dashRoutes },
      { ...teamRoutes },
      // {
      //   path: "/team",
      //   element: <MainLayout />,
      //   children: [
      //     {
      //       path: "/team/new",
      //       element: <NewTeam />,
      //     },
      //     {
      //       path: "/team/:teamSlug",
      //       element: <NewTeam />,
      //     },
      //   ],
      // },
      // {
      //   path: "/profile",
      //   element: <MainLayout />,
      //   children: [
      //     {
      //       path: "/profile",
      //       element: <ProfileSettings />,
      //     },
      //     {
      //       path: "/profile/:userId",
      //       element: <ProfileSettings />,
      //     },
      //   ],
      // },
    ],
  },
  /** Sign Up/In */
  {
    path: "/auth",
    element: <Authorization />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/auth/sign-in",
        element: <SignIn />,
      },
      {
        path: "/auth/sign-up",
        element: <SignUp />,
      },
      {
        path: "/auth/next-steps",
        element: <NextSteps />,
      },
      {
        path: "/auth/forgot-password",
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
    errorElement: <ErrorBoundary />,
  },
]);
