import {
  Alert,
  Button,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../i18n";
import { XTextInput } from "../../x-components/XTextInput";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Subject,
} from "rxjs";
import { computed } from "../../decorators/computed";
import { useCallback, useEffect, useMemo } from "react";
import {
  useUserMeQuery,
  useUserUpdateMutation,
} from "../../__generated__/types-and-hooks";

export class UserNameEditorVM {
  public firstName$: Subject<string> = new BehaviorSubject("");
  public lastName$: Subject<string> = new BehaviorSubject("");

  constructor(private t: ReturnType<typeof useTranslation>) {}

  @computed
  get firstName$error$() {
    return this.firstName$.pipe(
      map((firstName) => {
        if (firstName === null || firstName.length === 0) {
          return this.t("Please enter your first name.");
        }
        return null;
      })
    );
  }

  @computed
  get lastName$error$() {
    return this.lastName$.pipe(
      map((lastName) => {
        if (lastName === null || lastName.length === 0) {
          return this.t("Please enter your last name.");
        }
        return null;
      })
    );
  }

  @computed
  get json$() {
    return combineLatest({
      firstName: this.firstName$,
      lastName: this.lastName$,
    });
  }
}

export interface EditNameModalProps {
  open: boolean;
}

export default function EditNameModal({ open }: EditNameModalProps) {
  const t = useTranslation();
  const vm = useMemo(() => new UserNameEditorVM(t), [t]);
  const navigate = useNavigate();
  const showError = true;

  const [userUpdateMutation, { error }] = useUserUpdateMutation({});

  const userMeQuery = useUserMeQuery();
  const user = userMeQuery.data?.user_me;

  useEffect(() => {
    vm.firstName$.next(user?.firstName ?? "");
    vm.lastName$.next(user?.lastName ?? "");
  }, [user, vm]);

  const handleClose = useCallback(() => {
    navigate("../");
  }, [navigate]);
  const handleSave = useCallback(
    (e: any) => {
      e.preventDefault();

      if (user) {
        firstValueFrom(vm.json$).then((data) => {
          userUpdateMutation({
            variables: {
              id: user.id,
              firstName: data.firstName,
              lastName: data.lastName,
            },
            onCompleted() {
              userMeQuery.refetch();
              handleClose();
            },
          });
        });
      }
    },
    [userUpdateMutation, handleClose, userMeQuery, user, vm]
  );

  return (
    <Modal open={open} onClose={() => navigate("../")}>
      <ModalDialog size="md" variant="outlined">
        <DialogTitle>
          {t("Change Profile Name")}
          <ModalClose />
        </DialogTitle>
        <form>
          <Stack spacing={2}>
            <XTextInput
              autoFocus
              label={t("First Name")}
              size="sm"
              placeholder={t("e.g., John")}
              val$={vm.firstName$}
              error$={vm.firstName$error$}
              showError={showError}
            />
            <XTextInput
              label={t("Last Name")}
              size="sm"
              placeholder={t("e.g., Doe")}
              val$={vm.lastName$}
              error$={vm.lastName$error$}
              showError={showError}
            />
            <Button onClick={handleSave} type="submit" fullWidth>
              {t("Save")}
            </Button>
            {error && <Alert color="danger">{error.message}</Alert>}
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}
