import { Chip } from "@mui/joy";

export interface CountryFlagProps {
  code: string;
}

export default function CountryFlag({ code }: CountryFlagProps) {
  return (
    <Chip size="sm" sx={{ borderRadius: 4 }}>
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
        alt=""
      />
      {" " + code}
    </Chip>
  );
}
