import {
  Box,
  Button,
  Card,
  CardActions,
  CardOverflow,
  Divider,
  Stack,
  Typography,
} from "@mui/joy";
import { useTranslation } from "../../../i18n";
import { firstValueFrom } from "rxjs";
import Stepper from "@mui/joy/Stepper";
import Step, { stepClasses } from "@mui/joy/Step";
import StepIndicator, { stepIndicatorClasses } from "@mui/joy/StepIndicator";
import CategoryIcon from "@mui/icons-material/Category";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useInspectionCreateDraftMutation } from "../../../__generated__/types-and-hooks";
import { useTeam } from "../TeamLayout";
import { Link } from "react-router-dom";
import { DraftVM } from "./draft/DraftVM";
import { addDays } from "date-fns";
import StepInspectionType from "./draft/StepInspectionType";
import StepAddress from "./draft/StepAddress";
import StepSampling from "./draft/StepSampling";
import StepOther from "./draft/StepOther";
import StepReview from "./draft/StepReview";

interface IStep {
  completed: boolean;
  active: boolean;
  icon: ReactNode;
  text: string;
  render: (vm: DraftVM) => ReactNode;
}

const initialSteps: IStep[] = [
  {
    completed: true,
    active: true,
    icon: <TravelExploreIcon />,
    text: "Inspection Type",
    render: (vm: DraftVM) => <StepInspectionType vm={vm} />,
  },
  {
    completed: false,
    active: false,
    icon: <WarehouseIcon />,
    text: "Address",
    render: (vm: DraftVM) => <StepAddress vm={vm} />,
  },
  {
    completed: false,
    active: false,
    icon: <CategoryIcon />,
    text: "Samplings",
    render: (vm: DraftVM) => <StepSampling vm={vm} />,
  },
  {
    completed: false,
    active: false,
    icon: <DateRangeIcon />,
    text: "Dates & Notes",
    render: (vm: DraftVM) => <StepOther vm={vm} />,
  },
  {
    completed: false,
    active: false,
    icon: <CheckCircleRoundedIcon />,
    text: "Review",
    render: (vm: DraftVM) => <StepReview vm={vm} />,
  },
];

interface IconStepperProps {
  steps: IStep[];
  onClick: (step: IStep) => void;
}

export function IconStepper({ steps, onClick }: IconStepperProps) {
  return (
    <Stepper
      size="lg"
      sx={{
        width: "100%",
        "--StepIndicator-size": "3rem",
        "--Step-connectorInset": "0px",
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: "primary.300",
            color: "primary.300",
          },
          "&::after": {
            bgcolor: "primary.300",
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: "currentColor",
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: "neutral.outlinedDisabledColor",
        },
      }}
    >
      {steps.map((step, i) => (
        <Step
          onClick={() => onClick(step)}
          key={i}
          //   completed={step.completed}
          orientation="vertical"
          indicator={
            <StepIndicator
              variant="outlined"
              color={step.active ? "success" : "primary"}
            >
              {step.icon}
            </StepIndicator>
          }
        >
          <Typography
            textColor={step.active ? "text.primary" : "text.tertiary"}
            sx={{
              textTransform: "uppercase",
              fontWeight: "lg",
              fontSize: "0.75rem",
              letterSpacing: "0.5px",
            }}
          >
            {step.text}
          </Typography>
        </Step>
      ))}
    </Stepper>
  );
}

export default function CreateDraft() {
  const vm = useMemo(
    () =>
      new DraftVM({
        serviceType: "PSI",
        serviceDate: addDays(new Date(), 10),
        serviceManDays: null,
        rate: null,
        supplier: null,
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "RU",
        state: "",
        postalCode: "",
        samplings: null,
        notes: null,
      }),
    []
  );
  const t = useTranslation();

  const [inspectionCreateDraftMutation] = useInspectionCreateDraftMutation();

  const [steps, setSteps] = useState(initialSteps);
  const [step, setStep] = useState(0);

  const team = useTeam();

  const handleNextStep = useCallback(() => {
    steps[step].active = false;
    steps[step + 1].active = true;
    setSteps([...steps]);
    setStep(step + 1);
  }, [step, steps]);

  const handleBackStep = useCallback(() => {
    steps[step].active = false;
    steps[step - 1].active = true;
    setSteps([...steps]);
    setStep(step - 1);
  }, [step, steps]);

  const handleSave = useCallback(async () => {
    const json = await firstValueFrom(vm.json$);
    inspectionCreateDraftMutation({
      variables: {
        draft: {
          brandId: team.team.id,
          supplierId: json.supplier?.id!,
          serviceType: json.serviceType,
          // serviceDate: json.serviceDate,
          // address: json.address,
          // notes: json.notes,
        },
      },
    });
  }, [vm, inspectionCreateDraftMutation, team]);

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={{
        display: "flex",
        px: 2,
        py: 2,
      }}
    >
      <Box sx={{ display: "flex", m: 2 }}>
        <Typography sx={{ flex: 1 }} level="h2" component="h1">
          <Link to="..">{t("Inspections")}</Link>
        </Typography>
        <Button variant="solid" disabled>
          {t("Add Inspection")}
        </Button>
      </Box>
      <Typography sx={{ m: 1 }} level="body-sm">
        {t(
          "Creating Draft Inspection to improve clarity and reduce back-and-forth by refining the inspection details later."
        )}
      </Typography>
      <Card>
        <Box sx={{ mb: 1 }}>
          <IconStepper
            steps={steps}
            onClick={(ss) => {
              steps[step].active = false;
              const i = steps.findIndex((s) => s === ss);
              if (i !== -1) {
                steps[i].active = true;
              }
              setStep(i);
              setSteps([...steps]);
            }}
          />
        </Box>
        <Divider />
        {steps[step].render(vm)}
        <CardOverflow sx={{ borderTop: "1px solid", borderColor: "divider" }}>
          <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
            {step > 0 && (
              <Button
                //   loading={saving}
                size="sm"
                variant="outlined"
                onClick={handleBackStep}
              >
                {t("Back")}
              </Button>
            )}
            {step < steps.length - 1 && (
              <Button
                //   loading={saving}
                size="sm"
                variant="solid"
                onClick={handleNextStep}
              >
                {t("Next")}
              </Button>
            )}
            {step === steps.length - 1 && (
              <Button
                //   loading={saving}
                size="sm"
                variant="solid"
                onClick={handleSave}
              >
                {t("Save Draft")}
              </Button>
            )}
          </CardActions>
        </CardOverflow>
      </Card>
    </Stack>
  );
}
