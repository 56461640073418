import {
  FormControl,
  FormHelperText,
  FormLabel,
  TextareaTypeMap,
} from "@mui/joy";
import { ReactNode } from "react";
import { Observable, Subject } from "rxjs";
import { useRxVal } from "../hooks/useRx";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export interface TextArea$Props {
  label?: string | ReactNode;
  val$: Subject<string | null>;
  error$?: Observable<string | null>;
  showError?: boolean;
  required?: boolean;
}

export function XTextArea({
  val$,
  error$,
  showError,
  label,
  required,
}: TextareaTypeMap<{}, "div">["props"] & TextArea$Props) {
  const val = useRxVal(val$);
  const error = useRxVal(error$);
  const toolbarOptions = [
    [{ font: [] }], // Font options
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
    ["bold", "italic", "underline", "strike"], // Basic formatting
    [{ color: [] }, { background: [] }], // Text colors and highlights
    [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
    [{ list: "ordered" }, { list: "bullet" }], // Lists
    [{ indent: "-1" }, { indent: "+1" }], // Indentation
    [{ direction: "rtl" }], // Right-to-left text
    [{ align: [] }], // Alignment
    ["link", "image", "video"], // Links, media, and math
    ["clean"], // Remove formatting
  ];

  return (
    <FormControl required={required} error={showError && !!error}>
      {label && <FormLabel>{label}</FormLabel>}
      <ReactQuill
        theme="snow"
        modules={{
          toolbar: {
            container: toolbarOptions,
          },
        }}
        value={val ?? ""}
        onChange={(value) => val$.next(value)}
      />
      {/* <Textarea
        {...props}
        onChange={handleChanges}
        onBlur={handleChanges}
        value={val ?? ""}
      /> */}
      {showError && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
    // // <textarea defaultValue={val} onBlur={handleChanges} />
    // <Textarea
    //   defaultValue={val}
    //   // onChange={handleChanges}
    //   onBlur={handleChanges}
    //   // slots={{
    //   //   textarea: {
    //   //     // @ts-ignore
    //   //     onBlur: handleChanges,
    //   //   },
    //   // }}
    //   {...props}
    // />
  );
}
